import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Input, InputAdornment, FormHelperText } from "@material-ui/core";
import { DateRange } from "@material-ui/icons";

export default class CustomDateInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { value, onClick, label, disabled, helperText } = this.props;
    return (
      <Fragment>
        <Input
          error={!!helperText}
          placeholder={label}
          disabled={disabled}
          autoComplete="off"
          onClick={onClick}
          value={value}
          startAdornment={
            <InputAdornment position="start">
              <DateRange
                style={disabled ? { color: "#cccccc" } : { color: "#777777" }}
              />
            </InputAdornment>
          }
        />
        {helperText && (
          <FormHelperText style={{ color: "#f00" }}>
            {helperText}
          </FormHelperText>
        )}
      </Fragment>
    );
  }
}

CustomDateInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool
};

CustomDateInput.defaultProps = {
  onClick: () => {},
  value: "",
  label: "",
  helperText: "",
  disabled: false
};
