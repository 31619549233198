import React, { Component } from "react";
import PropTypes from "prop-types";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div className="ErrorBoundary error-boundary">
          <h1 className="error">Oops, something went wrong :(</h1>
          <p className="error-message">Error displaying chart</p>
        </div>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.shape()
};

ErrorBoundary.defaultProps = {
  children: {}
};

export default ErrorBoundary;
