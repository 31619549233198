import React from "react";
import Particles from "react-tsparticles";

export default () => (
  <div>
    <Particles
      width="100vw"
      height="100vh"
      params={{
        background: {
          color: "#000054"
        },
        detectRetina: true,
        fullScreen: {
          enable: true
        },
        interactivity: {
          detectsOn: "canvas",
          events: {
            onClick: { enable: true, mode: "push" },
            onHover: { enable: true, mode: "grab" },
            resize: true
          },
          modes: {
            grab: { distance: 200, links: { opacity: 0.3 } },
            push: { quantity: 14 }
          }
        },
        particles: {
          color: { value: "#ffffff" },
          links: {
            color: { value: "#ffffff" },
            distance: 150,
            enable: true,
            opacity: 0.1,
            width: 1
          },
          move: {
            attract: { enable: false, rotate: { x: 600, y: 1200 } },
            direction: "none",
            enable: true,
            outMode: "out",
            random: false,
            speed: 2,
            straight: false
          },
          number: { density: { enable: true, area: 800 }, value: 90 },
          opacity: {
            animation: {
              enable: false,
              minimumValue: 0.1,
              speed: 1,
              sync: false
            },
            random: false,
            value: 0.2
          },
          shape: {
            type: "edge",
            polygon: { sides: 5 }
          },
          size: {
            animation: {
              enable: false,
              minimumValue: 0.1,
              speed: 40,
              sync: false
            },
            random: true,
            value: 5
          },
          stroke: { color: "#000000", width: 0 }
        }
      }}
    />
  </div>
);
