import React from "react";
import { Button, Link, makeStyles } from "@material-ui/core";
import { ContactSupport } from "@material-ui/icons";

const useStyles = makeStyles({
  button: {
    backgroundColor: "#fff",
    color: "#000034",
    position: "fixed",
    bottom: 40,
    right: 40
  }
});

const Helpdesk = () => {
  const classes = useStyles();
  return (
    <div className="Helpdesk">
      <Link
        href="https://share.hsforms.com/1JBh490lnRS6bBFd0QnZ5AA2xb00"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Button
          variant="contained"
          className={classes.button}
          startIcon={<ContactSupport />}
          disableElevation
        >
          Contact support
        </Button>
      </Link>
    </div>
  );
};

export default Helpdesk;
