import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { generate } from "shortid";
import _ from "lodash";
import { Input, Chip } from "@material-ui/core";
import { deleteTerm, removeFilterTerm } from "../../actions/search";
import { getDateLabel } from "../../utils";

const getTermName = term => {
  if (term.subsection === "period") {
    return term.name.period
      ? `${term.name.period} ${term.name.date}`
      : getDateLabel(term.name);
  }
  return term.name.value ? term.name.value : term.name;
};

class SearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldVal: "",
      terms: []
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      (nextProps.terms.slice(-1)[0] || {}).name !==
      (prevState.terms.slice(-1)[0] || {}).name
    ) {
      return { terms: nextProps.terms };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { terms } = this.props;
    if (!_.isEqual(prevProps.terms, terms)) {
      this.input.focus();
    }
  }

  onChange(e) {
    const { onKeyUp } = this.props;
    const { oldVal } = this.state;
    onKeyUp(e, oldVal);
    this.setState({ oldVal: e.target.value });
  }

  render() {
    const {
      onClick,
      terms,
      onChange,
      autoCompleteTerm,
      isStaticData,
      isFilteringData,
      filterTerms,
      story,
      classes,
      client,
      placeholder
    } = this.props;
    return (
      <div className={classes.searchInput}>
        {terms
          .filter(term => term.table !== "context")
          .filter(term => term.subsection !== "autocomplete")
          .map(term => (
            <Chip
              key={generate()}
              className={classes.searchInputChip}
              label={getTermName(term)}
              onDelete={() => onClick(term, filterTerms, story, client)}
            />
          ))}
        <Input
          className={classes.searchInputField}
          fullWidth
          ref={input => {
            this.input = input;
          }}
          placeholder={
            terms.filter(term => term.subsection === "autocomplete").length ===
              0 && terms.length === 0
              ? placeholder
              : ""
          }
          onChange={onChange}
          onKeyUp={e => this.onChange(e)}
          value={autoCompleteTerm}
          type="text"
          disabled={!isStaticData || isFilteringData}
          disableUnderline
        />
      </div>
    );
  }
}

SearchInput.propTypes = {
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onClick: PropTypes.func,
  autoCompleteTerm: PropTypes.string,
  terms: PropTypes.arrayOf(PropTypes.shape()),
  isStaticData: PropTypes.bool,
  isFilteringData: PropTypes.bool,
  filterTerms: PropTypes.arrayOf(PropTypes.shape()),
  story: PropTypes.string,
  classes: PropTypes.shape(),
  client: PropTypes.string,
  placeholder: PropTypes.string
};

SearchInput.defaultProps = {
  onChange: () => {},
  onKeyUp: () => {},
  onClick: () => {},
  autoCompleteTerm: "",
  terms: [],
  isStaticData: false,
  isFilteringData: false,
  filterTerms: [],
  story: "",
  classes: {},
  client: "",
  placeholder: ""
};

const mapStateToProps = state => ({
  isStaticData: state.data.isStaticData,
  isFilteringData: state.search.isFilteringData
});

const mapDispatchToProps = dispatch => ({
  onClick: (term, filterTerms, story, client) => {
    dispatch(deleteTerm(term, story));
    if (_.isEqual(term.table, "what")) {
      dispatch(removeFilterTerm(term, filterTerms, story, client));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);
