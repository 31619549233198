import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import { connect } from "react-redux";
import { getYear } from "date-fns";
import IdleTimer from "react-idle-timer";
import { TIMEOUT } from "./constants";
import "./reset.css";
import "./App.css";
import { logout } from "./actions/user";
import { clearStaticDataCache, fetchStaticData } from "./actions/data";
import { clearAllSearchData } from "./actions/search";
import Notification from "./Notification";
import Home from "./components/Home";
import Login from "./components/Login";
import Settings from "./components/Settings";
import ViewReport from "./components/ViewReport";
import FindOutMore from "./components/FindOutMore";
import Support from "./components/Support";
import Upgrade from "./components/Upgrade";
import MenuBar from "./components/MenuBar";
import ReportsReviews from "./components/ReportsReviews";

class App extends Component {
  componentDidMount() {
    const { isUser, clearData, isStaticData } = this.props;
    // clear static data and logout when refresh after cookie expires
    if (!isUser && isStaticData) {
      clearData();
    }
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;
    script.src = "https://js.hs-scripts.com/4913136.js";
    document.getElementsByTagName("head")[0].appendChild(script);
  }

  componentDidUpdate() {
    const { isUser, isStaticData, getData, client } = this.props;
    // get static data when user logs in
    if (isUser && !isStaticData) {
      getData(client);
    }
  }

  render() {
    const { isUser, clearData } = this.props;
    return (
      <IdleTimer element={document} onIdle={clearData} timeout={TIMEOUT * 1000}>
        <Router>
          <div className="App">
            {isUser && <MenuBar />}
            <Notification />
            <div className="app-body">
              <Switch>
                <Route
                  exact
                  path="/"
                  render={props =>
                    !isUser ? (
                      <Redirect
                        to={{
                          pathname: "/login",
                          state: { from: props.location }
                        }}
                        component={Login}
                      />
                    ) : (
                      <Home />
                    )
                  }
                />
                <Route
                  exact
                  path="/reports"
                  render={props =>
                    !isUser ? (
                      <Redirect
                        to={{
                          pathname: "/login",
                          state: { from: props.location }
                        }}
                        component={Login}
                      />
                    ) : (
                      <ViewReport />
                    )
                  }
                />
                <Route
                  exact
                  path="/reports-reviews/:type"
                  render={props =>
                    !isUser ? (
                      <Redirect
                        to={{
                          pathname: "/login",
                          state: { from: props.location }
                        }}
                        component={Login}
                      />
                    ) : (
                      <ReportsReviews />
                    )
                  }
                />
                <Route
                  exact
                  path="/find-out-more"
                  render={props =>
                    !isUser ? (
                      <Redirect
                        to={{
                          pathname: "/login",
                          state: { from: props.location }
                        }}
                        component={Login}
                      />
                    ) : (
                      <FindOutMore />
                    )
                  }
                />
                <Route
                  exact
                  path="/support"
                  render={props =>
                    !isUser ? (
                      <Redirect
                        to={{
                          pathname: "/login",
                          state: { from: props.location }
                        }}
                        component={Login}
                      />
                    ) : (
                      <Support />
                    )
                  }
                />
                <Route
                  exact
                  path="/upgrade"
                  render={props =>
                    !isUser ? (
                      <Redirect
                        to={{
                          pathname: "/login",
                          state: { from: props.location }
                        }}
                        component={Login}
                      />
                    ) : (
                      <Upgrade />
                    )
                  }
                />
                <Route
                  exact
                  path="/settings"
                  render={props =>
                    !isUser ? (
                      <Redirect
                        to={{
                          pathname: "/login",
                          state: { from: props.location }
                        }}
                        component={Login}
                      />
                    ) : (
                      <Settings />
                    )
                  }
                />
                <Route exact path="/login" component={Login} />
                <Route exact path="/logout" component={Login} />
                <Redirect from="/*" to="/" />
              </Switch>
            </div>
            <footer id="footer">
              &copy; {getYear(new Date())} Interrodata
            </footer>
          </div>
        </Router>
      </IdleTimer>
    );
  }
}

App.propTypes = {
  isUser: PropTypes.bool,
  clearData: PropTypes.func,
  isStaticData: PropTypes.bool,
  getData: PropTypes.func,
  client: PropTypes.string
};

App.defaultProps = {
  isUser: false,
  clearData: () => {},
  isStaticData: false,
  getData: () => {},
  client: ""
};

const mapStateToProps = state => ({
  isUser: state.user.isUser,
  isStaticData: state.data.isStaticData,
  client: state.user.user.client
});

const mapDispatchToProps = dispatch => ({
  clearData: () => {
    dispatch(logout());
    dispatch(clearStaticDataCache());
    dispatch(clearAllSearchData());
  },
  getData: client => {
    dispatch(fetchStaticData(client));
  }
});

export { App as AppComponent };
export default connect(mapStateToProps, mapDispatchToProps)(App);
