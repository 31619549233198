import React from "react";
import PropTypes from "prop-types";
import { generate } from "shortid";
import { Link } from "react-router-dom";
import { Card, CardHeader, Avatar, Button, Tooltip } from "@material-ui/core";
import { sortByDate, getShortTitleFromQueryString } from "../../utils";
import { UpgradeIcon } from "../Icons";

const ReportType = props => {
  const {
    type,
    reports,
    icon,
    title,
    intro,
    buttons,
    buttonClass,
    background,
    classes,
    disabled
  } = props;
  return (
    <div className="ReportType">
      <Avatar className={classes.avatar}>{icon}</Avatar>
      <Card className={`${classes.reportType} ${background}`}>
        <CardHeader
          titleTypographyProps={{ variant: "h5" }}
          title={
            <a
              className={classes.reportTypeHeader}
              href={`/reports-reviews/${type}`}
              onClick={e => disabled && e.preventDefault()}
            >
              {title}
            </a>
          }
        />
        <div className={classes.reportTypeIntro}>
          <p>{intro}</p>
          <br />
          <a href="/find-out-more" className={classes.moreInfoLink}>
            Find out more
          </a>
        </div>
        <div className={classes.buttonContainer}>
          {buttons.map(i => (
            <Link
              to={i.link}
              key={generate()}
              onClick={e => disabled && e.preventDefault()}
            >
              {i.disabled ? (
                <Tooltip title={i.tooltip} arrow>
                  <div>
                    <Button
                      className={`${classes.newReportButton} ${buttonClass}`}
                      variant="contained"
                      disableElevation
                      disabled
                      startIcon={
                        <UpgradeIcon className={classes.upgradeIcon} />
                      }
                    >
                      {i.text}
                    </Button>
                  </div>
                </Tooltip>
              ) : (
                <Button
                  className={`${classes.newReportButton} ${buttonClass}`}
                  variant="contained"
                  disableElevation
                >
                  {i.text}
                </Button>
              )}
            </Link>
          ))}
        </div>
        {!disabled && (
          <div className={classes.recentReportsContainer}>
            <p className={classes.recentReports}>
              {reports.length} recent reports
              <a
                href={`/reports-reviews/${type}`}
                className={classes.viewRecent}
              >
                View my reports
              </a>
            </p>
            <div className={classes.reportList}>
              {reports
                .sort((a, b) => sortByDate(a, b))
                .map(i => {
                  const shortTitle = getShortTitleFromQueryString(i.query);
                  return (
                    <a href={`/reports?${i.query}`} key={i.query}>
                      <p className={classes.reportTitle}>{shortTitle}</p>
                    </a>
                  );
                })}
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

ReportType.propTypes = {
  type: PropTypes.string,
  reports: PropTypes.arrayOf(PropTypes.shape()),
  icon: PropTypes.shape(),
  title: PropTypes.string,
  intro: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.shape()),
  buttonClass: PropTypes.string,
  background: PropTypes.string,
  classes: PropTypes.shape(),
  disabled: PropTypes.bool
};

ReportType.defaultProps = {
  type: "",
  reports: [],
  icon: {},
  title: "",
  intro: "",
  buttons: [],
  buttonClass: "",
  background: "",
  classes: {},
  disabled: false
};

export default ReportType;
