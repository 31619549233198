import { createTransform } from "redux-persist";
import Fuse from "fuse.js";
import { DATA_SETS } from "./constants";

const dataTransform = createTransform(
  inboundState => {
    if (inboundState.isStaticData) {
      const searchEngine = {};
      DATA_SETS.forEach(e => {
        const { options, _docs } = inboundState.searchEngine[e];
        searchEngine[e] = { _docs, options };
      });

      return {
        ...inboundState,
        searchEngine
      };
    }
    return inboundState;
  },
  outboundState => {
    if (outboundState.isStaticData) {
      // initialise searchEngine with Fuse.
      const searchEngine = {};
      DATA_SETS.forEach(e => {
        const { options, _docs } = outboundState.searchEngine[e];
        searchEngine[e] = new Fuse(_docs, options);
      });
      return {
        ...outboundState,
        searchEngine
      };
    }
    return outboundState;
  },
  {
    whitelist: ["data"]
  }
);

export default dataTransform;
