import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";

const AutocompleteSuggestion = ({
  suggestion,
  completedSearchTerm,
  isSelected,
  onClick,
  classes
}) => (
  <Button
    onClick={onClick}
    className={
      isSelected
        ? `${classes.autocompleteButton} isSelected active`
        : `${classes.autocompleteButton} active`
    }
  >
    {completedSearchTerm}
    {suggestion.subsection === "period" ? (
      <span className={classes.suggestion}>
        &nbsp;{suggestion.name.period} {suggestion.name.date}&nbsp;
      </span>
    ) : (
      <span className={classes.suggestion}>
        &nbsp;{suggestion.name.value ? suggestion.name.value : suggestion.name}
        &nbsp;
      </span>
    )}
    ({suggestion.subsection})
  </Button>
);

AutocompleteSuggestion.propTypes = {
  suggestion: PropTypes.shape(),
  completedSearchTerm: PropTypes.string,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  classes: PropTypes.shape()
};

AutocompleteSuggestion.defaultProps = {
  suggestion: {},
  completedSearchTerm: "",
  onClick: () => {},
  isSelected: false,
  classes: {}
};

export default AutocompleteSuggestion;
