import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION
} from "../constants/actionConstants";

const notification = (state = {}, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        isVisible: true,
        message: action.message,
        level: action.level,
        vertical: action.vertical,
        horizontal: action.horizontal
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        isVisible: false,
        message: "",
        level: "",
        vertical: "",
        horizontal: ""
      };
    default:
      return state;
  }
};

export default notification;
