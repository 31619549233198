import React from "react";
import PropTypes from "prop-types";
import numfmt from "numfmt";
import ClusteredColumn from "./Charts/ClusteredColumn";
import ClusteredBar from "./Charts/ClusteredBar";
import StackedBar from "./Charts/StackedBar";
import StackedColumn from "./Charts/StackedColumn";
import Multi from "./Charts/Multi";
import PieGraph from "./Charts/PieGraph";
import BubbleChart from "./Charts/BubbleChart";
import ErrorBoundary from "./ErrorBoundary";

const getData = data => {
  let chartData = [];
  switch (data.type) {
    case "clustered_column":
      if (!Array.isArray(data.y[0])) {
        chartData = Object.assign(
          data.x.map((d, i) => ({ x: d, y: data.y[i] }))
        );
      } else {
        for (let i = 0; i < data.x.length; i += 1) {
          const d = { x: data.x[i] };
          for (let j = 0; j < data.y.length; j += 1) {
            d[data.y[j][0]] = data.y[j][1][i];
          }
          chartData.push(d);
        }
      }
      return chartData;
    case "clustered_bar":
      chartData = Object.assign(data.x.map((d, i) => ({ x: d, y: data.y[i] })));
      return chartData;
    case "stacked_bar":
      for (let i = 0; i < data.x.length; i += 1) {
        const d = { x: data.x[i] };
        for (let j = 0; j < data.y.length; j += 1) {
          d[data.y[j][0]] = data.y[j][1][i];
        }
        chartData.push(d);
      }
      return chartData;
    case "stacked_column":
      for (let i = 0; i < data.x.length; i += 1) {
        const d = { x: data.x[i] };
        for (let j = 0; j < data.y.length; j += 1) {
          d[data.y[j][0]] = data.y[j][1][i];
        }
        chartData.push(d);
      }
      return chartData;
    case "multi":
      // 0 is clustered_column or stacked_column, 1 is line
      // OR 0 is line and 1 is clustered_column
      for (let i = 0; i < data[0].x.length; i += 1) {
        const d = { x: data[0].x[i] };
        for (let j = 0; j < data[0].y.length; j += 1) {
          d[data[0].y[j][0]] = data[0].y[j][1][i];
          if (data[1].y[j]) {
            d[data[1].y[j][0]] = data[1].y[j][1][i];
          }
        }
        chartData.push(d);
      }
      return chartData;
    case "pie":
      chartData = Object.assign(
        data.x.map((d, i) => ({ name: d, value: data.y[i] }))
      );
      return chartData;
    case "bubble":
      chartData = Object.assign(
        data.y.map(d => ({ x: d[0], y: d[1], z: d[2] }))
      );
      return chartData;
    default:
      return chartData;
  }
};

const formatNumber = (number, format) => {
  try {
    return numfmt.format(format, number);
  } catch (error) {
    return number;
  }
};

const ReportChart = props => {
  const { data, display, layout } = props;
  const chartData = getData(data);
  const colors = [
    "#00002D",
    "#028893",
    "#AE2E1A",
    "#B67A10",
    "#000066",
    "#D23823",
    "#84848B",
    "#84B8C0",
    "#D48B87",
    "#DBAE85"
  ];
  return (
    <div className="Chart ReportChart">
      <div
        className={data.type}
        style={{
          position: "absolute",
          top: `${layout.top}%`,
          left: `${layout.left}%`,
          height: `${layout.height}%`,
          width: `${layout.width}%`
        }}
      >
        {data.type === "clustered_column" ? (
          <ErrorBoundary>
            <ClusteredColumn
              data={data}
              display={display}
              chartData={chartData}
              colors={colors}
              formatNumber={formatNumber}
            />
          </ErrorBoundary>
        ) : null}
        {data.type === "clustered_bar" ? (
          <ErrorBoundary>
            <ClusteredBar
              data={data}
              display={display}
              chartData={chartData}
              formatNumber={formatNumber}
            />
          </ErrorBoundary>
        ) : null}
        {data.type === "stacked_bar" ? (
          <ErrorBoundary>
            <StackedBar
              data={data}
              display={display}
              chartData={chartData}
              colors={colors}
              formatNumber={formatNumber}
            />
          </ErrorBoundary>
        ) : null}
        {data.type === "stacked_column" ? (
          <ErrorBoundary>
            <StackedColumn
              data={data}
              display={display}
              chartData={chartData}
              colors={colors}
              formatNumber={formatNumber}
            />
          </ErrorBoundary>
        ) : null}
        {data.type === "multi" ? (
          <ErrorBoundary>
            <Multi
              data={data}
              display={display}
              chartData={chartData}
              colors={colors}
              formatNumber={formatNumber}
            />
          </ErrorBoundary>
        ) : null}
        {data.type === "pie" ? (
          <ErrorBoundary>
            <PieGraph display={display} chartData={chartData} colors={colors} />
          </ErrorBoundary>
        ) : null}
        {data.type === "bubble" ? (
          <ErrorBoundary>
            <BubbleChart display={display} chartData={chartData} />
          </ErrorBoundary>
        ) : null}
      </div>
    </div>
  );
};

ReportChart.propTypes = {
  data: PropTypes.shape(),
  display: PropTypes.shape(),
  layout: PropTypes.shape()
};

ReportChart.defaultProps = {
  data: {},
  display: {},
  layout: {}
};

export default ReportChart;
