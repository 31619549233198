import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers";
import dataTransform from "./transforms";

const logger = createLogger();

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["data"],
  transforms: [dataTransform]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

function configureStore() {
  return process.env.NODE_ENV === "production"
    ? createStore(persistedReducer, applyMiddleware(thunk))
    : createStore(persistedReducer, applyMiddleware(thunk, logger));
}

export const store = configureStore();
export const persistor = persistStore(store);
