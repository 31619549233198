import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Favicon from "react-favicon";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Paper,
  IconButton,
  Button,
  Tooltip,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { StarBorderRounded } from "@material-ui/icons";
import { DownloadIcon, ReportsReviewsIcon } from "../Icons";
import {
  fetchReport,
  fetchLocalReport,
  addToFavourites
} from "../../actions/report";
import { goToSlide } from "../../actions/presentation";
import loading from "../../images/logo-loading-clear.gif";
import { getDataSet } from "../../config";
import Presentation from "./Presentation";
import PresentationNavLeft from "./PresentationNavLeft";
import PresentationNavRight from "./PresentationNavRight";
import styles from "./styles";
import "./Presentation.css";
import PresentationError from "./PresentationError";
import PresentationNav from "./PresentationNav";

function loadWidget() {
  window.HubSpotConversations.widget.load();
}

function refreshWidget() {
  window.HubSpotConversations.widget.refresh();
}

export class ViewReport extends Component {
  componentDidMount() {
    const {
      getReport,
      goToFirstSlide,
      slideIndex,
      userId,
      date,
      location,
      client
    } = this.props;
    const queryString = location.search.slice(1);
    const urlParams = new URLSearchParams(queryString);
    const story =
      urlParams.get("story") && urlParams.get("story").toLowerCase();
    const dataSet = getDataSet(story);
    getReport(queryString, userId, date[dataSet], client, story);
    if (slideIndex > 0) {
      goToFirstSlide();
    }
    if (window.HubSpotConversations) {
      loadWidget();
    } else {
      window.hsConversationsOnReady = [loadWidget];
    }
  }

  componentWillUnmount() {
    if (window.HubSpotConversations) {
      refreshWidget();
    } else {
      window.hsConversationsOnReady = [refreshWidget];
    }
  }

  render() {
    const {
      reportIsLoading,
      reportError,
      report,
      userId,
      addFavourite,
      classes,
      slideIndex,
      reportTitle,
      reportQuery,
      reportId,
      client,
      story,
      reportClient
    } = this.props;
    if (reportIsLoading) {
      return (
        <div className="ViewReport">
          <Favicon url={["favicon0.ico", "favicon1.ico", "favicon2.ico"]} />
          <Paper elevation={0} className={classes.container}>
            <img
              className={classes.loadingImage}
              alt="Interrodata"
              src={loading}
            />
            <Typography
              variant="subtitle1"
              gutterBottom
              className={classes.loadingText}
            >
              You can run{" "}
              <a
                href="/reports-reviews/new-ih-performance"
                target="_blank"
                rel="noreferrer"
              >
                another report
              </a>{" "}
              or view{" "}
              <a
                href="/reports-reviews/performance"
                target="_blank"
                rel="noreferrer"
              >
                your recent reports
              </a>{" "}
              while you are waiting
            </Typography>
          </Paper>
        </div>
      );
    }
    if (reportError.message) {
      return (
        <div className="ViewReport">
          <Favicon url="favicon.ico" />
          <Paper elevation={0} className={classes.container}>
            <PresentationError
              errorMessage={reportError.message}
              classes={classes}
            />
          </Paper>
        </div>
      );
    }
    return (
      <div className="ViewReport">
        <Favicon url="favicon.ico" />
        <Grid
          container
          justifyContent="center"
          className={classes.container}
          spacing={1}
        >
          <Grid container justifyContent="center" spacing={1}>
            <Grid item lg={6} md={7} xs={12} className={classes.headerRow}>
              <Paper className={classes.header} elevation={3}>
                <Tooltip
                  title={reportTitle}
                  classes={{ tooltip: classes.titleTooltip }}
                >
                  <div id="title" className={classes.reportTitle}>
                    <ReportsReviewsIcon className={classes.titleIcon} />
                    {reportTitle}
                  </div>
                </Tooltip>
              </Paper>
            </Grid>
            <Grid item md={3} className={classes.headerRow}>
              <Grid container spacing={1}>
                <Grid item md={2}>
                  <Paper elevation={3} className={classes.header}>
                    <Tooltip title="Add to favourites">
                      <IconButton
                        className={classes.favouriteButton}
                        onClick={() =>
                          addFavourite(userId, { query: reportQuery })
                        }
                      >
                        <StarBorderRounded className={classes.favouriteIcon} />
                      </IconButton>
                    </Tooltip>
                  </Paper>
                </Grid>
                <Grid item md={3}>
                  <Paper elevation={3} className={classes.header}>
                    <PresentationNav
                      slideIndex={slideIndex}
                      reportLength={report.length}
                      classes={classes}
                    />
                  </Paper>
                </Grid>
                <Grid item md={7} className={classes.headerRow}>
                  <Paper elevation={3} className={classes.header}>
                    <Tooltip
                      title="You can download reports to a fully editable PowerPoint document with our Professional and Professional+ packages"
                      arrow
                    >
                      <span>
                        <Button
                          className={classes.download}
                          startIcon={<DownloadIcon />}
                          fullWidth
                          disabled
                          id="download" // for load-runner
                        >
                          Download Report
                        </Button>
                      </span>
                    </Tooltip>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={1} className={classes.navButton}>
            <PresentationNavLeft
              activeSlideIndex={slideIndex}
              report={report}
              classes={classes}
              reportId={reportId}
              userId={userId}
              story={story}
              client={client}
            />
          </Grid>
          <Grid item md={10} lg={9}>
            <Presentation
              activeSlideIndex={slideIndex}
              report={report}
              classes={classes}
              client={reportClient}
            />
          </Grid>
          <Grid item md={1} className={classes.navButton}>
            <PresentationNavRight
              activeSlideIndex={slideIndex}
              report={report}
              classes={classes}
              reportId={reportId}
              userId={userId}
              story={story}
              client={client}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

ViewReport.propTypes = {
  report: PropTypes.arrayOf(PropTypes.shape()),
  reportIsLoading: PropTypes.bool,
  reportError: PropTypes.shape(),
  getReport: PropTypes.func,
  slideIndex: PropTypes.number,
  goToFirstSlide: PropTypes.func,
  userId: PropTypes.string,
  addFavourite: PropTypes.func,
  date: PropTypes.shape(),
  location: PropTypes.shape(),
  classes: PropTypes.shape(),
  reportTitle: PropTypes.string,
  reportQuery: PropTypes.string,
  reportId: PropTypes.string,
  client: PropTypes.string,
  story: PropTypes.string,
  reportClient: PropTypes.string
};

ViewReport.defaultProps = {
  report: [],
  reportIsLoading: true,
  reportError: {},
  getReport: () => {},
  slideIndex: 0,
  goToFirstSlide: () => {},
  userId: "",
  addFavourite: () => {},
  date: {},
  location: {},
  classes: {},
  reportTitle: "",
  reportQuery: "",
  reportId: "",
  client: "",
  story: "",
  reportClient: ""
};

const mapStateToProps = state => ({
  report: state.report.reportContent,
  reportIsLoading: state.report.isLoading,
  reportError: state.report.error,
  reportTitle: state.report.reportTitle,
  reportQuery: state.report.reportQuery,
  reportId: state.report.reportId,
  reportClient: state.report.reportClient,
  story: state.report.reportStory,
  slideIndex: state.presentation.activeSlideIndex,
  userId: state.user.user.id,
  client: state.user.user.client,
  date: state.data.date
});

const mapDispatchToProps = dispatch => ({
  getReport: (queryString, userId, date, client, story) => {
    if (process.env.REACT_APP_DEV_ENV === "local") {
      dispatch(fetchLocalReport());
    } else {
      dispatch(fetchReport(queryString, userId, date, client, story));
    }
  },
  goToFirstSlide: () => dispatch(goToSlide(0)),
  addFavourite: (userId, report) => dispatch(addToFavourites(userId, report))
});

export { ViewReport as ViewReportComponent };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(ViewReport)));
