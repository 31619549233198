import React from "react";
import PropTypes from "prop-types";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  ReferenceLine,
  Cell,
  LabelList,
  Legend,
  CartesianGrid,
  Text
} from "recharts";

const ClusteredColumn = props => {
  const { data, display, chartData, colors, formatNumber } = props;
  return (
    <ResponsiveContainer>
      {Array.isArray(data.y[0]) ? (
        <BarChart
          data={chartData}
          margin={{ top: 40, bottom: 40, left: 40, right: 40 }}
        >
          {display.category_axis.tick_label_position === "None" ? null : (
            <XAxis
              axisLine={display.category_axis.visible}
              dataKey="x"
              interval={0}
              fontSize={12}
              tick={e => (
                <CustomizedAxisTick
                  x={e.x}
                  y={e.y}
                  value={`${e.payload.value}`}
                  fontSize={e.fontSize}
                  fill={e.fill}
                />
              )}
            />
          )}
          {display.has_legend ? <Legend verticalAlign="top" /> : null}
          <YAxis
            axisLine={display.value_axis.visible}
            hide={!display.value_axis.visible}
            type="number"
            domain={
              display.value_axis.minimum_scale
                ? [
                    display.value_axis.minimum_scale,
                    display.value_axis.maximum_scale
                  ]
                : [0, "auto"]
            }
          />
          {display.value_axis.has_major_gridlines ? (
            <CartesianGrid vertical={false} />
          ) : null}
          {data.y.map((i, key) => (
            <Bar key={key.toString()} dataKey={`${i[0]}`}>
              {display.data_labels ? (
                <LabelList
                  dataKey={`${i[0]}`}
                  formatter={j =>
                    formatNumber(j, display.data_labels.number_format)
                  }
                  style={{
                    fill: display.apply
                      ? "auto"
                      : `#${display.data_labels.font.color}`,
                    fontSize: display.data_labels.font.size
                  }}
                  position={
                    display.data_labels.position === "inside_end"
                      ? "insideTop"
                      : "top"
                  }
                />
              ) : null}
              {chartData.map((e, j) => (
                <Cell key={j.toString()} fill={colors[j % colors.length]} />
              ))}
            </Bar>
          ))}
          <ReferenceLine ifOverflow="extendDomain" y={0} stroke="#333" />
        </BarChart>
      ) : (
        <BarChart
          data={chartData}
          margin={{ top: 40, bottom: 40, left: 40, right: 40 }}
        >
          {display.category_axis.tick_label_position === "None" ? null : (
            <XAxis
              axisLine={
                display.category_axis.visible ||
                display.category_axis.visible !== undefined
              }
              dataKey="x"
              tickLine={false}
              fontSize={12}
              interval={0}
              tick={e => (
                <CustomizedAxisTick
                  x={e.x}
                  y={e.y}
                  value={`${e.payload.value}`}
                  fontSize={e.fontSize}
                  fill={e.fill}
                />
              )}
            />
          )}
          <YAxis
            axisLine={display.value_axis.visible}
            hide={!display.value_axis.visible}
            type="number"
            domain={[
              display.value_axis.minimum_scale,
              display.value_axis.maximum_scale
            ]}
            padding={Math.min(...data.y) < 0 ? { bottom: 10 } : { bottom: 0 }}
          />
          <Bar dataKey="y">
            {display.data_labels ? (
              <LabelList
                dataKey="y"
                formatter={i =>
                  formatNumber(i, display.data_labels.number_format)
                }
                style={{
                  fill:
                    display.data_labels.font && display.data_labels.font.color
                      ? `#${display.data_labels.font.color}`
                      : "auto",
                  fontSize: display.data_labels.font.size
                }}
                position={
                  display.data_labels.position === "inside_end"
                    ? "insideTop"
                    : "top"
                }
                offset={15}
              />
            ) : null}
            {chartData.map((e, i) =>
              display.apply ? (
                <Cell
                  key={i.toString()}
                  fill={e.y > 0 ? "#01B050" : "#FF0000"}
                />
              ) : (
                <Cell key={i.toString()} fill={`#${display.color}`} />
              )
            )}
          </Bar>
          <ReferenceLine ifOverflow="extendDomain" y={0} stroke="#333" />
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};

ClusteredColumn.propTypes = {
  data: PropTypes.shape(),
  display: PropTypes.shape(),
  chartData: PropTypes.arrayOf(PropTypes.shape()),
  colors: PropTypes.arrayOf(PropTypes.string),
  formatNumber: PropTypes.func
};

ClusteredColumn.defaultProps = {
  data: {},
  display: {},
  chartData: [],
  colors: [],
  formatNumber: () => {}
};

const CustomizedAxisTick = props => {
  const { x, y, value, fontSize, fill } = props;
  return (
    <Text
      x={x}
      y={y}
      width={120}
      textAnchor="middle"
      verticalAnchor="start"
      fontSize={fontSize}
      fill={fill}
    >
      {value}
    </Text>
  );
};

CustomizedAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  value: PropTypes.string,
  fontSize: PropTypes.number,
  fill: PropTypes.string
};

CustomizedAxisTick.defaultProps = {
  x: 0,
  y: 0,
  value: "",
  fontSize: 0,
  fill: ""
};

export default ClusteredColumn;
