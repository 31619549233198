import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IconButton } from "@material-ui/core";
import { goToSlide } from "../../actions";
import { PreviousIcon } from "../Icons";

const PresentationNavLeft = props => {
  const { activeSlideIndex, toSlide, report, classes } = props;
  const isFirstSlide = activeSlideIndex <= 0;
  return (
    <div className="PresentationNavLeft">
      <IconButton
        id="presentation-prev"
        className={classes.navButtonIcon}
        onClick={() =>
          !isFirstSlide && toSlide(activeSlideIndex - 1, report, report.length)
        }
        disabled={isFirstSlide}
      >
        <PreviousIcon />
      </IconButton>
      <p>Previous Page</p>
    </div>
  );
};

PresentationNavLeft.propTypes = {
  activeSlideIndex: PropTypes.number,
  toSlide: PropTypes.func,
  report: PropTypes.arrayOf(PropTypes.shape()),
  classes: PropTypes.shape()
};

PresentationNavLeft.defaultProps = {
  activeSlideIndex: 0,
  toSlide: () => {},
  report: [],
  classes: {}
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  toSlide: (slideIndex, report, slideCount) => {
    const { reportId, userId, story, client } = ownProps;
    dispatch(
      goToSlide(
        slideIndex,
        report,
        "prev",
        slideCount,
        reportId,
        userId,
        story,
        client
      )
    );
  }
});

export { PresentationNavLeft as PresentationNavLeftComponent };
export default connect(null, mapDispatchToProps)(PresentationNavLeft);
