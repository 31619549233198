import React from "react";
import PropTypes from "prop-types";
import ReportImage from "./ReportImage";
import { LOGO_BUCKET } from "../../constants";

const ReportTemplate = props => {
  const { activeSlideIndex, client } = props;
  return (
    <div className="ReportTemplate">
      <div>
        {activeSlideIndex === 0 && (
          <ReportImage
            layout={{
              height: 40.94,
              left: 0,
              top: 30,
              width: 100
            }}
            data="/templates/title-slide.png"
          />
        )}
        <ReportImage
          layout={{
            height: 26.25,
            left: 83.76,
            top: 2.62,
            width: 14.76
          }}
          data={`${LOGO_BUCKET}/${client}-logo.png`}
        />
        <ReportImage
          layout={{
            height: 7.38,
            left: 1.16,
            top: 92.18,
            width: 7.38
          }}
          data="/templates/logo-cropped.png"
        />
      </div>
    </div>
  );
};

ReportTemplate.propTypes = {
  activeSlideIndex: PropTypes.number,
  client: PropTypes.string
};

ReportTemplate.defaultProps = {
  activeSlideIndex: 0,
  client: ""
};

export default ReportTemplate;
