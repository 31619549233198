import React, { useEffect } from "react";
import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import { UpgradeIcon } from "./Icons";
import background from "../images/background.png";

const useStyles = makeStyles({
  container: {
    maxWidth: 1300,
    margin: "0 auto",
    marginBottom: 100
  },
  paper: {
    width: "100%",
    padding: 20,
    color: "#3c3838"
  },
  imageContainer: {
    padding: "50px 60px"
  },
  image: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    color: "#fff",
    borderRadius: 20,
    height: "calc(100% - 20px)",
    backgroundColor: "green"
  },
  premiumLogo: {
    textAlign: "center",
    margin: "0 auto",
    marginTop: "45%"
  },
  icon: {
    fontSize: 72
  },
  interrodata: {
    fontWeight: "bold"
  },
  premium: {
    letterSpacing: 2,
    textTransform: "uppercase",
    fontWeight: "bold"
  },
  titleIcon: {
    fontSize: 42,
    verticalAlign: "middle"
  },
  heading: {
    color: "#009cad",
    fontWeight: "bold"
  },
  formContainer: {
    padding: 50
  },
  introText: {
    color: "#009cad",
    fontSize: 16
  },
  hubspotForm: {
    padding: 30,
    width: "100%",
    borderRadius: 4,
    boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.16)",
    marginBottom: 20,
    marginTop: 30
  }
});

const Upgrade = () => {
  const classes = useStyles();
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.crossOrigin = "anonymous";
    script.src = "https://js.hsforms.net/forms/shell.js";
    document.getElementsByTagName("head")[0].appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "4913136",
          formId: "01f26145-c7f6-4f3e-b1aa-4708754829be",
          target: "#hubspotUpgradeForm"
        });
      }
    });
  }, []);
  return (
    <div className="Upgrade">
      <Grid container spacing={3} className={classes.container}>
        <Paper className={classes.paper}>
          <Grid item md={12} container>
            <Grid item md={6} className={classes.imageContainer}>
              <Grid container className={classes.image}>
                <Grid item md={4} />
                <Grid item md={4} className={classes.premiumLogo}>
                  <UpgradeIcon className={classes.icon} />
                  <Typography variant="h5" className={classes.interrodata}>
                    Interrodata
                  </Typography>
                  <Typography
                    variant="h5"
                    gutterBottom
                    className={classes.premium}
                  >
                    Premium
                  </Typography>
                </Grid>
                <Grid item md={4} />
              </Grid>
            </Grid>
            <Grid item md={6} className={classes.formContainer}>
              <Typography variant="h4" gutterBottom className={classes.heading}>
                <UpgradeIcon className={classes.titleIcon} /> Upgrade
              </Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                className={classes.introText}
              >
                We are glad that you are enjoying trialling Interrodata!
              </Typography>
              <Typography variant="body2" gutterBottom>
                Please fill out the quick form below so we can best understand
                which of our solutions will suit the needs of you and your
                business.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Once completed, one of our dedicated Sales Team will be in touch
                to discuss setting up the perfect package for you.
              </Typography>
              <fieldset
                className={classes.hubspotForm}
                id="hubspotUpgradeForm"
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default Upgrade;
