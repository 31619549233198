import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Grid,
  Card,
  CardHeader,
  IconButton,
  FormControl,
  TextField,
  Button,
  FormHelperText
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { Loader } from "../SharedComponents";
import { disableUser } from "../../actions/user";

const DisableUser = props => {
  const { error, message, submitDisableUserForm, isLoading, onClose, classes } =
    props;
  const [email, setEmail] = useState("");

  const reset = () => {
    setEmail("");
  };

  return (
    <div className="DisableUser">
      <Card className={classes.card}>
        <CardHeader
          titleTypographyProps={{
            variant: "h6",
            className: classes.heading
          }}
          avatar={
            <IconButton
              onClick={() => {
                onClose();
                reset();
              }}
            >
              <ArrowBack className={classes.icon} />
            </IconButton>
          }
          title="Disable User"
        />
        <Grid item md={6}>
          <form className={classes.form}>
            <FormControl>
              <TextField
                className={classes.formInput}
                placeholder="Email"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                variant="outlined"
              />
            </FormControl>
            <br />
            <div className={classes.buttons}>
              <Button
                className={classes.submitButton}
                variant="contained"
                type="sumbit"
                disableElevation
                onClick={e => {
                  e.preventDefault();
                  submitDisableUserForm(email);
                  // if success
                  if (message.length > 0) {
                    reset();
                  }
                }}
              >
                Disable user
              </Button>
              <Button
                className={classes.cancelButton}
                type="cancel"
                disableElevation
                onClick={e => {
                  e.preventDefault();
                  onClose();
                  reset();
                }}
              >
                Close
              </Button>
            </div>
            {error.length > 0 ? (
              <FormHelperText className={classes.errors}>
                {error.startsWith("ERROR:") ? error.substring(12) : error}
              </FormHelperText>
            ) : null}
            {message.length > 0 ? (
              <FormHelperText className={classes.success}>
                {message}
              </FormHelperText>
            ) : null}
          </form>
          {isLoading ? <Loader /> : null}
        </Grid>
      </Card>
    </div>
  );
};

DisableUser.propTypes = {
  submitDisableUserForm: PropTypes.func,
  error: PropTypes.string,
  message: PropTypes.string,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  classes: PropTypes.shape()
};

DisableUser.defaultProps = {
  submitDisableUserForm: () => {},
  error: "",
  message: "",
  isLoading: false,
  onClose: () => {},
  classes: {}
};

const mapDispatchToProps = dispatch => ({
  submitDisableUserForm: email => {
    dispatch(disableUser(email));
  }
});

export default connect(null, mapDispatchToProps)(DisableUser);
