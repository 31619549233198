import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, CardHeader, Typography, Button, List } from "@material-ui/core";
import { PerformanceIcon } from "../Icons";
import WhatSection from "./WhatSection";
import SearchBar from "./SearchBar";
import WhereSection from "../SharedComponents/WhereSection";
import WhenSection from "../SharedComponents/WhenSection";
import MeasureSection from "../SharedComponents/MeasureSection";
import Review from "./Review";
import { fetchStaticData } from "../../actions/data";
import { clearStorySearchData, deleteTerm } from "../../actions/search";

function loadWidget() {
  window.HubSpotConversations.widget.load();
}

function refreshWidget() {
  window.HubSpotConversations.widget.refresh();
}

class NewPerformanceReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdvancedSearch: false,
      activeStep: 0
    };
    this.checkForErrors = this.checkForErrors.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.clearContext = this.clearContext.bind(this);
  }

  componentDidMount() {
    const { isStaticData, getData, client } = this.props;
    if (!isStaticData) {
      getData(client);
    }
    if (window.HubSpotConversations) {
      loadWidget();
    } else {
      window.hsConversationsOnReady = [loadWidget];
    }
  }

  componentWillUnmount() {
    if (window.HubSpotConversations) {
      refreshWidget();
    } else {
      window.hsConversationsOnReady = [refreshWidget];
    }
  }

  checkForErrors = () => {
    const { searchTerms } = this.props;
    let err = "";
    if (searchTerms.filter(t => t.table === "what").length === 0) {
      err = "Please select which product you are interested in.";
    } else if (searchTerms.filter(t => t.table === "where").length === 0) {
      err = "Please select which retailer or channel you are interested in.";
    } else if (searchTerms.filter(t => t.table === "when").length === 0) {
      err = "Please select which time period you are interested in.";
    } else if (searchTerms.filter(t => t.table === "measure").length === 0) {
      err = "Please select which measure you are interested in.";
    }
    return err;
  };

  handleNext = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep + 1 });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep - 1 });
  };

  handleReset = () => {
    const { reset, story } = this.props;
    reset(story);
  };

  clearContext = () => {
    const { searchTerms, story, deleteTerms } = this.props;
    const contextTerms = searchTerms.filter(i => i.table === "context");
    deleteTerms(contextTerms, story);
  };

  render() {
    const {
      subsections,
      filteredSubsections,
      searchTerms,
      allTerms,
      hint,
      isFilteredData,
      filterTerms,
      isFilteringData,
      story,
      classes,
      isStaticData,
      dataSet,
      isLoadingContext,
      context,
      contextSearchEngine,
      suggestedContext,
      client
    } = this.props;
    const { isAdvancedSearch, activeStep } = this.state;
    return (
      <div className="NewPerformance">
        <Card
          className={
            !isStaticData || isFilteringData || isLoadingContext
              ? classes.wait
              : classes.overflow
          }
        >
          <CardHeader
            titleTypographyProps={{ variant: "h5" }}
            title="Performance Reports (In-Home – Including Impulse)"
            avatar={<PerformanceIcon fontSize="small" />}
            className={classes.heading}
          />
          <Typography variant="body2" gutterBottom className={classes.subtext}>
            Run a performance report to understand how a category or brand is
            performing over time, in a specific market or retailer.
          </Typography>
          <List>
            {(activeStep === 0 && (
              <Fragment>
                <SearchBar
                  hint={hint}
                  story={story}
                  classes={classes}
                  allTerms={allTerms}
                  checkForErrors={this.checkForErrors}
                  dataSet={dataSet}
                  handleNext={this.handleNext}
                  client={client}
                />
                {isAdvancedSearch ? (
                  <Fragment>
                    <WhatSection
                      searchTerms={searchTerms.filter(s => s.table === "what")}
                      options={
                        isFilteredData
                          ? filteredSubsections.filter(s => s.table === "what")
                          : subsections.filter(s => s.table === "what")
                      }
                      filterTerms={filterTerms}
                      hint={hint}
                      story={story}
                      classes={classes}
                      isFilteringData={isFilteringData}
                      client={client}
                    />
                    <WhereSection
                      selected={searchTerms.find(s => s.table === "where")}
                      options={subsections
                        .filter(s => s.table === "where")
                        .filter(i => i.name !== "prr_retailer")}
                      hint={hint}
                      story={story}
                      classes={classes}
                    />
                    <WhenSection
                      selected={searchTerms.find(s => s.table === "when")}
                      options={subsections.filter(s => s.table === "when")}
                      hint={hint}
                      story={story}
                      classes={classes}
                    />
                    <MeasureSection
                      selected={searchTerms.find(s => s.table === "measure")}
                      options={subsections.filter(s => s.table === "measure")}
                      hint={hint}
                      story={story}
                      classes={classes}
                    />
                  </Fragment>
                ) : (
                  <div className={classes.subtitle}>
                    Can&apos;t find what you&apos;re looking for?
                    <br />
                    <Button
                      className={classes.advancedSearchButton}
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() =>
                        this.setState({ isAdvancedSearch: !isAdvancedSearch })
                      }
                    >
                      Advanced Search
                    </Button>
                  </div>
                )}
              </Fragment>
            )) ||
              (activeStep === 1 && (
                <Review
                  searchTerms={searchTerms}
                  classes={classes}
                  story={story}
                  handleReset={this.handleReset}
                  handleBack={this.handleBack}
                  isLoadingContext={isLoadingContext}
                  context={context}
                  searchEngine={contextSearchEngine}
                  clearContext={this.clearContext}
                  suggestedContext={suggestedContext}
                  client={client}
                />
              ))}
          </List>
        </Card>
      </div>
    );
  }
}

NewPerformanceReport.propTypes = {
  subsections: PropTypes.arrayOf(PropTypes.shape()),
  filteredSubsections: PropTypes.arrayOf(PropTypes.shape()),
  searchTerms: PropTypes.arrayOf(PropTypes.shape()),
  allTerms: PropTypes.bool,
  getData: PropTypes.func,
  isStaticData: PropTypes.bool,
  hint: PropTypes.string,
  isFilteredData: PropTypes.bool,
  filterTerms: PropTypes.arrayOf(PropTypes.shape()),
  isFilteringData: PropTypes.bool,
  story: PropTypes.string,
  classes: PropTypes.shape(),
  dataSet: PropTypes.string,
  reset: PropTypes.func,
  isLoadingContext: PropTypes.bool,
  context: PropTypes.arrayOf(PropTypes.shape()),
  contextSearchEngine: PropTypes.shape(),
  deleteTerms: PropTypes.func,
  suggestedContext: PropTypes.arrayOf(PropTypes.shape()),
  client: PropTypes.string
};

NewPerformanceReport.defaultProps = {
  subsections: [],
  filteredSubsections: [],
  searchTerms: [],
  allTerms: false,
  getData: () => {},
  isStaticData: false,
  hint: "",
  isFilteredData: false,
  filterTerms: [],
  isFilteringData: false,
  story: "",
  classes: {},
  dataSet: "",
  reset: () => {},
  isLoadingContext: false,
  context: [],
  contextSearchEngine: {},
  deleteTerms: () => {},
  suggestedContext: [],
  client: ""
};

const mapStateToProps = (state, ownProps) => {
  const { story, dataSet } = ownProps;
  const {
    search: {
      searchTerms = { story: [] },
      allTerms = { story: false },
      hint = "",
      filteredSubsections = { story: [] },
      isFilteredData = { story: false },
      filterTerms = { story: [] },
      isFilteringData = false,
      isLoadingContext = false,
      context = { story: [] },
      contextSearchEngine = { story: {} },
      suggestedContext = { story: [] }
    },
    data: { subsections = { dataSet: [] }, isStaticData = false }
  } = state;
  return {
    searchTerms: searchTerms[story],
    subsections: subsections[dataSet],
    allTerms: allTerms[story],
    isStaticData,
    hint,
    filteredSubsections: filteredSubsections[story],
    isFilteredData: isFilteredData[story],
    filterTerms: filterTerms[story],
    isFilteringData,
    isLoadingContext,
    context: context[story],
    contextSearchEngine: contextSearchEngine[story],
    suggestedContext: suggestedContext[story],
    client: state.user.user.client
  };
};

const mapDispatchToProps = dispatch => ({
  getData: client => {
    dispatch(fetchStaticData(client));
  },
  reset: story => {
    dispatch(clearStorySearchData(story));
  },
  deleteTerms: (terms, story) => {
    terms.forEach(i => {
      dispatch(deleteTerm(i, story));
    });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPerformanceReport);
