import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { ListItem, FormHelperText } from "@material-ui/core";
import {
  enterSelectedTerm,
  deleteTerm,
  clearSearchHints
} from "../../actions/search";
import { customFilter } from "../../utils";

const MeasureSection = props => {
  const { options, selected, onSelect, hint, story, classes, clearErrors } =
    props;
  const optionList = options
    .map(option =>
      option.keywords.map(kw => ({ name: kw, subsection: option.name }))
    )
    .reduce((array, option) => array.concat(option), []);
  const optionsToUse = optionList.map(option => ({
    value: `${option.subsection}--${option.name}`,
    label: option.name
  }));
  const selectedValue = selected
    ? {
        value: `${selected.subsection}--${selected.name}`,
        label: selected.name
      }
    : "no-value";
  return (
    <ListItem>
      <fieldset className={classes.fieldset}>
        <legend className={classes.fieldsetLegend}>Measure</legend>
        <Select
          className={`${classes.selectContainer} textInput`}
          name="measure"
          onChange={e => {
            onSelect(e.value, selectedValue, story, hint);
            clearErrors();
          }}
          options={optionsToUse}
          placeholder="Type or scroll to select a Measure"
          value={selectedValue}
          backspaceRemovesValue={false}
          filterOption={customFilter}
        />
        <FormHelperText className={classes.errors}>
          {hint && hint.includes("measure") ? hint : null}
        </FormHelperText>
      </fieldset>
    </ListItem>
  );
};

MeasureSection.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  selected: PropTypes.objectOf(PropTypes.string),
  onSelect: PropTypes.func,
  hint: PropTypes.string,
  story: PropTypes.string,
  classes: PropTypes.shape(),
  clearErrors: PropTypes.func
};

MeasureSection.defaultProps = {
  options: [],
  selected: undefined,
  onSelect: () => {},
  hint: "",
  story: "",
  classes: {},
  clearErrors: () => {}
};

const mapDispatchToProps = dispatch => ({
  onSelect: (selectedValue, oldValue, story, hint) => {
    const value = selectedValue.split("--");
    if (oldValue !== "no-value") {
      const old = oldValue.value.split("--");
      dispatch(
        deleteTerm(
          {
            name: old[1],
            subsection: old[0],
            table: "measure",
            story
          },
          story
        )
      );
    }
    dispatch(
      enterSelectedTerm(
        {
          name: value[1],
          subsection: value[0],
          table: "measure",
          story
        },
        story
      )
    );
    if (hint && hint.includes("measure")) {
      dispatch(clearSearchHints());
    }
  }
});

export { MeasureSection as MeasureSectionUnconnected };
export default connect(null, mapDispatchToProps)(MeasureSection);
