import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography
} from "@material-ui/core";
import {
  ReportsReviewsIcon,
  PerformanceIcon,
  RangeReviewIcon,
  BrandBattlesIcon
} from "../Icons";

const LeftMenu = props => {
  const { classes } = props;
  return (
    <Card>
      <CardHeader
        avatar={<ReportsReviewsIcon className={classes.leftMenuTitleIcon} />}
        className={classes.leftMenuTitle}
        title="Reports & Reviews"
        titleTypographyProps={{ variant: "subtitle2" }}
      />
      <List component="nav" aria-label="secondary mailbox folders">
        <Link to="/reports-reviews/performance">
          <ListItem button>
            <ListItemIcon>
              <PerformanceIcon
                className={`${classes.leftMenuIcon} ${classes.selectedMenu}`}
              />
            </ListItemIcon>
            <ListItemText
              secondary={
                <Typography
                  type="body2"
                  className={`${classes.leftMenuText} ${classes.selectedMenu}`}
                >
                  Performance Reports
                </Typography>
              }
            />
          </ListItem>
        </Link>
        <Link
          to="/reports-reviews"
          onClick={e => e.preventDefault()}
          className={classes.disabled}
        >
          <ListItem button className={classes.disabled}>
            <ListItemIcon>
              <BrandBattlesIcon
                className={`${classes.leftMenuIcon} ${classes.disabled}`}
              />
            </ListItemIcon>
            <ListItemText
              secondary={
                <Typography type="body2" className={classes.disabled}>
                  Brand Battles
                </Typography>
              }
            />
          </ListItem>
        </Link>
        <Link
          to="/reports-reviews"
          onClick={e => e.preventDefault()}
          className={classes.disabled}
        >
          <ListItem button className={classes.disabled}>
            <ListItemIcon>
              <RangeReviewIcon
                className={`${classes.leftMenuIcon} ${classes.disabled}`}
              />
            </ListItemIcon>
            <ListItemText
              secondary={
                <Typography type="body2" className={classes.disabled}>
                  Range Review Impact Assessment
                </Typography>
              }
            />
          </ListItem>
        </Link>
      </List>
    </Card>
  );
};

LeftMenu.propTypes = {
  classes: PropTypes.shape()
};

LeftMenu.defaultProps = {
  classes: {}
};

export default LeftMenu;
