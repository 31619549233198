import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  Grid,
  CardHeader,
  Typography,
  Button,
  IconButton
} from "@material-ui/core";
import { StarBorderRounded, StarRounded } from "@material-ui/icons";
import { PerformanceIcon } from "../Icons";
import {
  getMyRecentReports,
  getMyFavouriteReports
} from "../../actions/report";
import { sortByDate } from "../../utils";
import { getDataSet } from "../../config";
import { ReportCard, Loader } from "../SharedComponents";

const PerformanceHistory = props => {
  const [isStarred, setIsStarred] = useState(false);
  const {
    myRecentReports,
    isLoadingMyReports,
    recentError,
    myFavouriteReports,
    isLoadingFavourites,
    favouritesError,
    classes,
    getRecentReports,
    getFavourites,
    userId,
    story,
    dataDate,
    client
  } = props;

  useEffect(() => {
    if (myRecentReports.length < 1 || myFavouriteReports.length < 1) {
      getRecentReports(userId);
      getFavourites(userId);
    }
  }, []);

  const listedReports = isStarred ? myFavouriteReports : myRecentReports;

  return (
    <Card className={classes.overflow}>
      <CardHeader
        avatar={<PerformanceIcon fontSize="small" />}
        titleTypographyProps={{ variant: "h5" }}
        title="Performance Reports"
        className={classes.heading}
      />
      <Typography variant="body2" gutterBottom className={classes.subtext}>
        Run a performance report to understand how a category or brand is
        performing over time, in a specific market, channel or retailer. You can
        run either an In-Home or Out-of-Home report.
      </Typography>
      <div className={classes.historyContainer}>
        <Link to="/reports-reviews/new-ih-performance">
          <Button
            className={classes.generateReportButton}
            variant="contained"
            color="primary"
            disableElevation
          >
            New In-Home Performance Report (Including Impulse)
          </Button>
        </Link>
        <Link
          to="/reports-reviews/new-ooh-performance"
          onClick={e => e.preventDefault()}
        >
          <Button
            className={classes.generateReportButton}
            variant="contained"
            color="primary"
            disableElevation
            disabled
          >
            New Out-of-Home Performance Report (Excluding Impulse)
          </Button>
        </Link>
        <div className={classes.starFiltering}>
          <IconButton onClick={() => setIsStarred(!isStarred)}>
            {isStarred ? (
              <StarRounded fontSize="large" className={classes.favourite} />
            ) : (
              <StarBorderRounded fontSize="large" />
            )}
          </IconButton>
        </div>
      </div>
      {((isLoadingFavourites || isLoadingMyReports) && <Loader />) ||
        ((favouritesError || recentError) && (
          <div className="errors">
            <p>There was an error loading your reports:</p>
            {favouritesError && favouritesError}
            {recentError && recentError}
          </div>
        )) ||
        ((myRecentReports || myFavouriteReports) && (
          <div className={classes.reportCardContainer}>
            <Grid container spacing={3}>
              {listedReports
                .map(i => {
                  const urlParams = new URLSearchParams(i.query);
                  const params = Object.fromEntries(urlParams);
                  return { ...i, ...params };
                })
                .filter(e => e.story.toLowerCase() === story)
                .sort((a, b) => sortByDate(a, b))
                .map(report => {
                  const isFavourite =
                    myFavouriteReports.filter(e => e.query === report.query)
                      .length > 0;
                  const dataSet = getDataSet(report.story);
                  return (
                    <ReportCard
                      key={report.query}
                      report={report}
                      classes={classes}
                      isFavourite={isFavourite}
                      userId={userId}
                      dataDate={dataDate[dataSet]}
                      client={client}
                    />
                  );
                })}
            </Grid>
          </div>
        ))}
    </Card>
  );
};

PerformanceHistory.propTypes = {
  classes: PropTypes.shape(),
  getRecentReports: PropTypes.func,
  userId: PropTypes.string,
  myRecentReports: PropTypes.arrayOf(PropTypes.shape()),
  isLoadingMyReports: PropTypes.bool,
  recentError: PropTypes.string,
  getFavourites: PropTypes.func,
  myFavouriteReports: PropTypes.arrayOf(PropTypes.shape()),
  isLoadingFavourites: PropTypes.bool,
  favouritesError: PropTypes.string,
  story: PropTypes.string,
  dataDate: PropTypes.shape(),
  client: PropTypes.string
};

PerformanceHistory.defaultProps = {
  classes: {},
  getRecentReports: () => {},
  userId: "",
  myRecentReports: [],
  isLoadingMyReports: false,
  recentError: "",
  getFavourites: () => {},
  myFavouriteReports: [],
  isLoadingFavourites: false,
  favouritesError: "",
  story: "",
  dataDate: {},
  client: ""
};

const mapStateToProps = state => ({
  myRecentReports: state.report.myRecentReports,
  myFavouriteReports: state.report.myFavouriteReports,
  userId: state.user.user.id,
  client: state.user.user.client,
  isLoadingMyReports: state.report.isLoadingMyReports,
  recentError: state.report.myRecentReportsError,
  isLoadingFavourites: state.report.isLoadingFavourites,
  favouritesError: state.report.myFavouriteReportsError,
  dataDate: state.data.date
});

const mapDispatchToProps = dispatch => ({
  getRecentReports: userId => {
    dispatch(getMyRecentReports(userId));
  },
  getFavourites: userId => {
    dispatch(getMyFavouriteReports(userId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceHistory);
