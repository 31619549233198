import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Card,
  CardHeader,
  Avatar,
  Collapse,
  makeStyles
} from "@material-ui/core";
import { connect } from "react-redux";
import { clearSettingsMessages } from "../../actions/user";
import {
  SettingsIcon,
  ChangePasswordIcon,
  CreateAccountIcon,
  UnblockUserIcon,
  DisableUserIcon,
  EnableUserIcon,
  ResetPasswordIcon,
  AddClientIcon
} from "../Icons";
import ChangePassword from "./ChangePassword";
import CreateAccount from "./CreateAccount";
import UnblockUser from "./UnblockUser";
import DisableUser from "./DisableUser";
import EnableUser from "./EnableUser";
import ResetPassword from "./ResetPassword";
import AddClient from "./AddClient";

const useStyles = makeStyles({
  container: {
    maxWidth: 1300,
    margin: "0 auto",
    textAlign: "center",
    marginBottom: 100
  },
  card: {
    width: "100%",
    padding: 20,
    paddingBottom: 100
  },
  heading: {
    color: "#000074",
    fontWeight: "bold",
    float: "left"
  },
  icon: {
    color: "#000074"
  },
  settingsOption: {
    height: 120,
    color: "#000034",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.3)",
      color: "#000074"
    }
  },
  optionText: {
    fontSize: 16,
    fontWeight: "bold"
  },
  optionAvatar: {
    borderRadius: "50%",
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.3)",
    backgroundColor: "#fff",
    marginLeft: 10
  },
  optionIcon: {
    padding: 15,
    height: 50,
    width: 50,
    color: "#000034"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    marginBottom: 20
  },
  formInput: {
    margin: 20
  },
  buttons: {
    margin: 20
  },
  submitButton: {
    backgroundColor: "#009dbc",
    minWidth: "40%",
    color: "#fff",
    float: "left"
  },
  cancelButton: {
    float: "right"
  },
  errors: {
    color: "red",
    textAlign: "center",
    marginTop: 20,
    fontSize: 14
  },
  success: {
    color: "#000",
    textAlign: "center",
    marginTop: 20,
    fontSize: 14
  },
  inputRoot: {
    padding: "0px !important"
  }
});

const Settings = props => {
  const {
    userId,
    isAdmin,
    client,
    isLoading,
    changePasswordError,
    changePasswordMessage,
    createUserError,
    createUserMessage,
    unblockUserError,
    unblockUserMessage,
    disableUserError,
    disableUserMessage,
    enableUserError,
    enableUserMessage,
    resetPasswordError,
    resetPasswordMessage,
    addClientError,
    addClientMessage,
    clearMessages,
    departments
  } = props;
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [option, setOption] = useState(null);

  const onClose = () => {
    setIsOpen(false);
    clearMessages();
  };

  const showForm = () => {
    switch (option) {
      case "change-password":
        return (
          <ChangePassword
            userId={userId}
            classes={classes}
            onClose={onClose}
            error={changePasswordError}
            message={changePasswordMessage}
            isLoading={isLoading}
          />
        );
      case "create-account":
        return isAdmin ? (
          <CreateAccount
            client={client}
            classes={classes}
            onClose={onClose}
            error={createUserError}
            message={createUserMessage}
            isLoading={isLoading}
            departments={departments}
          />
        ) : null;
      case "unblock-users":
        return isAdmin ? (
          <UnblockUser
            classes={classes}
            onClose={onClose}
            error={unblockUserError}
            message={unblockUserMessage}
            isLoading={isLoading}
          />
        ) : null;
      case "disable-user":
        return isAdmin ? (
          <DisableUser
            classes={classes}
            onClose={onClose}
            error={disableUserError}
            message={disableUserMessage}
            isLoading={isLoading}
          />
        ) : null;
      case "enable-user":
        return isAdmin ? (
          <EnableUser
            classes={classes}
            onClose={onClose}
            error={enableUserError}
            message={enableUserMessage}
            isLoading={isLoading}
          />
        ) : null;
      case "reset-password":
        return isAdmin ? (
          <ResetPassword
            classes={classes}
            onClose={onClose}
            error={resetPasswordError}
            message={resetPasswordMessage}
            isLoading={isLoading}
          />
        ) : null;
      case "add-client":
        return isAdmin ? (
          <AddClient
            classes={classes}
            onClose={onClose}
            error={addClientError}
            message={addClientMessage}
            isLoading={isLoading}
          />
        ) : null;
      default:
        return null;
    }
  };

  return (
    <div className="Settings">
      <Grid container spacing={3} className={classes.container}>
        <Card className={classes.card}>
          <CardHeader
            titleTypographyProps={{
              variant: "h5",
              className: classes.heading
            }}
            avatar={<SettingsIcon className={classes.icon} />}
            title="Settings"
          />
          <Collapse in={isOpen}>{showForm()}</Collapse>
          <Collapse in={!isOpen}>
            <Grid container spacing={3}>
              <Grid item md={4}>
                <Card
                  className={classes.settingsOption}
                  onClick={() => {
                    setIsOpen(true);
                    setOption("change-password");
                  }}
                >
                  <CardHeader
                    titleTypographyProps={{
                      variant: "h6",
                      className: classes.optionText
                    }}
                    avatar={
                      <Avatar className={classes.optionAvatar}>
                        <ChangePasswordIcon className={classes.optionIcon} />
                      </Avatar>
                    }
                    title="Change password"
                  />
                </Card>
              </Grid>
              {isAdmin && (
                <Fragment>
                  <Grid item md={4}>
                    <Card
                      className={classes.settingsOption}
                      onClick={() => {
                        setIsOpen(true);
                        setOption("create-account");
                      }}
                    >
                      <CardHeader
                        titleTypographyProps={{
                          variant: "h6",
                          className: classes.optionText
                        }}
                        avatar={
                          <Avatar className={classes.optionAvatar}>
                            <CreateAccountIcon className={classes.optionIcon} />
                          </Avatar>
                        }
                        title="Create an account"
                      />
                    </Card>
                  </Grid>
                  <Grid item md={4}>
                    <Card
                      className={classes.settingsOption}
                      onClick={() => {
                        setIsOpen(true);
                        setOption("unblock-users");
                      }}
                    >
                      <CardHeader
                        titleTypographyProps={{
                          variant: "h6",
                          className: classes.optionText
                        }}
                        avatar={
                          <Avatar className={classes.optionAvatar}>
                            <UnblockUserIcon className={classes.optionIcon} />
                          </Avatar>
                        }
                        title="Unblock users"
                      />
                    </Card>
                  </Grid>
                  <Grid item md={4}>
                    <Card
                      className={classes.settingsOption}
                      onClick={() => {
                        setIsOpen(true);
                        setOption("disable-user");
                      }}
                    >
                      <CardHeader
                        titleTypographyProps={{
                          variant: "h6",
                          className: classes.optionText
                        }}
                        avatar={
                          <Avatar className={classes.optionAvatar}>
                            <DisableUserIcon className={classes.optionIcon} />
                          </Avatar>
                        }
                        title="Disable user"
                      />
                    </Card>
                  </Grid>
                  <Grid item md={4}>
                    <Card
                      className={classes.settingsOption}
                      onClick={() => {
                        setIsOpen(true);
                        setOption("enable-user");
                      }}
                    >
                      <CardHeader
                        titleTypographyProps={{
                          variant: "h6",
                          className: classes.optionText
                        }}
                        avatar={
                          <Avatar className={classes.optionAvatar}>
                            <EnableUserIcon className={classes.optionIcon} />
                          </Avatar>
                        }
                        title="Enable user"
                      />
                    </Card>
                  </Grid>
                  <Grid item md={4}>
                    <Card
                      className={classes.settingsOption}
                      onClick={() => {
                        setIsOpen(true);
                        setOption("reset-password");
                      }}
                    >
                      <CardHeader
                        titleTypographyProps={{
                          variant: "h6",
                          className: classes.optionText
                        }}
                        avatar={
                          <Avatar className={classes.optionAvatar}>
                            <ResetPasswordIcon className={classes.optionIcon} />
                          </Avatar>
                        }
                        title="Reset password"
                      />
                    </Card>
                  </Grid>
                  <Grid item md={4}>
                    <Card
                      className={classes.settingsOption}
                      onClick={() => {
                        setIsOpen(true);
                        setOption("add-client");
                      }}
                    >
                      <CardHeader
                        titleTypographyProps={{
                          variant: "h6",
                          className: classes.optionText
                        }}
                        avatar={
                          <Avatar className={classes.optionAvatar}>
                            <AddClientIcon className={classes.optionIcon} />
                          </Avatar>
                        }
                        title="Add client"
                      />
                    </Card>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </Collapse>
        </Card>
      </Grid>
    </div>
  );
};

Settings.propTypes = {
  userId: PropTypes.string,
  isAdmin: PropTypes.bool,
  client: PropTypes.string,
  isLoading: PropTypes.bool,
  changePasswordError: PropTypes.string,
  changePasswordMessage: PropTypes.string,
  createUserError: PropTypes.string,
  createUserMessage: PropTypes.string,
  unblockUserError: PropTypes.string,
  unblockUserMessage: PropTypes.string,
  disableUserError: PropTypes.string,
  disableUserMessage: PropTypes.string,
  enableUserError: PropTypes.string,
  enableUserMessage: PropTypes.string,
  resetPasswordError: PropTypes.string,
  resetPasswordMessage: PropTypes.string,
  addClientError: PropTypes.string,
  addClientMessage: PropTypes.string,
  clearMessages: PropTypes.func,
  departments: PropTypes.arrayOf(PropTypes.string)
};

Settings.defaultProps = {
  userId: "",
  isAdmin: false,
  client: "",
  isLoading: false,
  changePasswordError: "",
  changePasswordMessage: "",
  createUserError: "",
  createUserMessage: "",
  unblockUserError: "",
  unblockUserMessage: "",
  disableUserError: "",
  disableUserMessage: "",
  enableUserError: "",
  enableUserMessage: "",
  resetPasswordError: "",
  resetPasswordMessage: "",
  addClientError: "",
  addClientMessage: "",
  clearMessages: () => {},
  departments: []
};

const mapStateToProps = state => ({
  userId: state.user.user.id,
  isAdmin: state.user.user.admin,
  client: state.user.user.client,
  isLoading: state.user.isLoading,
  changePasswordError: state.user.changePasswordError,
  changePasswordMessage: state.user.changePasswordMessage,
  createUserError: state.user.createUserError,
  createUserMessage: state.user.createUserMessage,
  unblockUserError: state.user.unblockUserError,
  unblockUserMessage: state.user.unblockUserMessage,
  disableUserError: state.user.disableUserError,
  disableUserMessage: state.user.disableUserMessage,
  enableUserError: state.user.enableUserError,
  enableUserMessage: state.user.enableUserMessage,
  resetPasswordError: state.user.resetPasswordError,
  resetPasswordMessage: state.user.resetPasswordMessage,
  addClientError: state.user.addClientError,
  addClientMessage: state.user.addClientMessage,
  departments: state.user.departments
});

const mapDispatchToProps = dispatch => ({
  clearMessages: () => {
    dispatch(clearSettingsMessages());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
