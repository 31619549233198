import React from "react";
import PropTypes from "prop-types";

const ReportShape = props => {
  const { data, display, layout } = props;
  return (
    <div className="Shape ReportShape">
      {display.type === "oval" ? (
        <div
          style={{
            backgroundColor: `#${display.color ? display.color : "fff"}`,
            color: `#${display.font ? display.font.color : "fff"}`,
            fontSize: `${display.font ? Number(display.font.size) / 16 : 1}em`,
            textAlign: "center",
            position: "absolute",
            whiteSpace: "pre-wrap",
            top: `${layout.top}%`,
            left: `${layout.left}%`,
            height: `${layout.height}%`,
            width: `${layout.width}%`,
            borderRadius: "50%",
            fontWeight: `${
              display.font && display.font.bold ? "bold" : "normal"
            }`,
            boxShadow: "2px 2px 5px grey",
            display: "flex",
            zIndex: 12
          }}
        >
          <p style={{ margin: "auto" }}>{data}</p>
        </div>
      ) : null}
      {display.type === "line" ? (
        <div
          style={{
            top: `${layout.begin_y}%`,
            left: `${layout.begin_x}%`,
            height: `${layout.end_y - layout.begin_y + 1}%`,
            width: `${layout.end_x - layout.begin_x + 1}%`,
            position: "absolute",
            textAlign: "center",
            backgroundColor: `#${display.color}`
          }}
        >
          {data}
        </div>
      ) : null}
      {display.type === "rndrectangle" ? (
        <div
          style={{
            backgroundColor: `#${display.color ? display.color : "fff"}`,
            color: `#${display.font ? display.font.color : "fff"}`,
            fontSize: `${display.font ? Number(display.font.size) / 16 : 1}em`,
            textAlign: "center",
            position: "absolute",
            whiteSpace: "pre-wrap",
            top: `${layout.top}%`,
            left: `${layout.left}%`,
            height: `${layout.height}%`,
            width: `${layout.width}%`,
            borderRadius: "10%",
            fontWeight: `${
              display.font && display.font.bold ? "bold" : "normal"
            }`,
            boxShadow: "2px 2px 5px grey"
          }}
        >
          <p style={{ margin: "auto" }}>{data}</p>
        </div>
      ) : null}
      {display.type === "arrow" ? (
        <div
          style={{
            top: `${layout.top}%`,
            left: `${layout.left}%`,
            height: `${layout.height}%`,
            width: `${layout.width}%`,
            position: "absolute",
            textAlign: "center"
          }}
        >
          <i
            aria-label="down-arrow"
            style={{
              position: "relative",
              color: `#${display.color}`,
              fontSize: `${100 / layout.height}vh`,
              display: "inline-block",
              transform: "rotate(90deg)"
            }}
          >
            &#10145;
          </i>
        </div>
      ) : null}
    </div>
  );
};

ReportShape.propTypes = {
  data: PropTypes.string,
  display: PropTypes.shape(),
  layout: PropTypes.shape()
};

ReportShape.defaultProps = {
  data: "",
  display: {},
  layout: {}
};

export default ReportShape;
