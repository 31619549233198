import winston from "winston";
import WinstonCloudWatch from "winston-cloudwatch";
import AWS from "aws-sdk/global";
import CloudWatchLogs from "aws-sdk/clients/cloudwatchlogs";
import { MD5 } from "crypto-js";
import { AWS_CONFIG } from "./constants";

AWS.config.update(AWS_CONFIG);

const logger = winston.add(
  new WinstonCloudWatch({
    cloudWatchLogs: new CloudWatchLogs(),
    logGroupName: `frontend-${process.env.REACT_APP_DEV_ENV}`,
    logStreamName: () => {
      const date = new Date().toISOString().split("T")[0];
      const hour = new Date().getUTCHours().toString();
      const name = `${date}-${MD5(hour)}`;
      return name;
    },
    jsonMessage: true
  })
);

export default logger;
