import React from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import LeftMenu from "./LeftMenu";
import NewPerformanceReport from "../NewPerformanceReport";
import PerformanceHistory from "../PerformanceHistory";
import useStyles from "./styles";
import "./ReportsReviews.css";

const ReportsReviews = () => {
  const { type } = useParams();
  const classes = useStyles();
  return (
    <div className="ReportsReviews">
      <Grid container style={{ padding: "0 15px" }} spacing={3}>
        <Grid item md={3} style={{ marginBottom: 10 }}>
          <LeftMenu classes={classes} type={type} />
        </Grid>
        <Grid item md={9} style={{ marginBottom: 100 }}>
          {(type === "performance" && (
            <PerformanceHistory classes={classes} story="frida" />
          )) ||
            (type === "new-ih-performance" && (
              <NewPerformanceReport
                classes={classes}
                story="frida"
                dataSet="nielsen"
              />
            ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default ReportsReviews;
