import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  Typography,
  Collapse,
  Box
} from "@material-ui/core";
import { EditIcon } from "../Icons";
import { getDateLabel, buildQueryString } from "../../utils";
import ProductContext from "./ProductContext";

const Review = props => {
  const {
    searchTerms,
    story,
    classes,
    handleReset,
    handleBack,
    isLoadingContext,
    context,
    searchEngine,
    clearContext,
    suggestedContext,
    client
  } = props;
  const [isContextOpen, setIsContextOpen] = useState(false);
  const [contextError, setContextError] = useState(null);
  const whatTerms = searchTerms.filter(s => s.table === "what");
  const contextTerms = searchTerms.filter(s => s.table === "context");
  const where = searchTerms.find(s => s.table === "where");
  const when = searchTerms.find(s => s.table === "when");
  const measure = searchTerms.find(s => s.table === "measure");
  const dateString = when.name.period
    ? `${when.name.period} ${when.name.date}`
    : getDateLabel(when.name);
  useEffect(() => {
    setIsContextOpen(whatTerms.length > 1 && contextTerms.length === 0);
  }, []);
  return (
    <div className="Review">
      <Typography
        gutterBottom
        className={`${classes.subheading} ${classes.bold}`}
      >
        Review & Run
      </Typography>
      <Typography
        variant="body2"
        gutterBottom
        className={`${classes.subtext} ${classes.selected}`}
      >
        Please review your selections and add or change the product scope. To
        change your initial selections, please go back to the query builder.
      </Typography>
      <div className={classes.stepperButtons}>
        {contextTerms.length > 0 ? (
          <Link
            to={`/reports?story=${story.toUpperCase()}&${buildQueryString(
              searchTerms
            )}`}
            onClick={handleReset}
          >
            <Button
              className={`${classes.generateReportButton} ${classes.nextButton}`}
              variant="contained"
              color="primary"
              disableElevation
            >
              Generate Report
            </Button>
          </Link>
        ) : (
          <Button
            className={`${classes.generateReportButton} ${classes.nextButton}`}
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => setContextError("Please select a context")}
          >
            Generate Report
          </Button>
        )}
      </div>
      <List>
        <ListItem>
          <fieldset className={classes.fieldset}>
            <List>
              <ListItem>
                <ListItemText className={classes.reviewTitle}>
                  What
                  <span className={`${classes.reviewValue} ${classes.right}`}>
                    {whatTerms.map((i, k) => (
                      <p key={k.toString()}>{i.name}</p>
                    ))}
                  </span>
                </ListItemText>
              </ListItem>
              <Collapse in={!isContextOpen}>
                <Divider />
                <ListItem>
                  <ListItemText className={classes.reviewTitle}>
                    Product Scope
                    <span className={classes.right}>
                      {contextTerms.map((i, k) => (
                        <p key={k.toString()}>{i.name}</p>
                      ))}
                    </span>
                  </ListItemText>
                </ListItem>
              </Collapse>
              <Collapse in={isContextOpen}>
                <ProductContext
                  isLoadingContext={isLoadingContext}
                  options={context.filter(s => s.table === "what")}
                  contextTerms={contextTerms}
                  whatTerms={whatTerms}
                  searchEngine={searchEngine}
                  story={story}
                  classes={classes}
                  clearErrors={() => setContextError(null)}
                  contextError={contextError}
                  suggestedContext={suggestedContext}
                  client={client}
                />
              </Collapse>
              <Box textAlign="center">
                <Button
                  size="small"
                  variant="contained"
                  className={classes.contextButton}
                  startIcon={isContextOpen ? null : <EditIcon />}
                  onClick={() => {
                    setIsContextOpen(!isContextOpen);
                  }}
                  disableElevation
                >
                  {isContextOpen ? "OK" : "Edit Scope"}
                </Button>
              </Box>
            </List>
          </fieldset>
        </ListItem>
        <ListItem>
          <fieldset className={classes.fieldset}>
            <List>
              <ListItem>
                <ListItemText className={classes.reviewTitle}>
                  Where
                  <span className={`${classes.reviewValue} ${classes.right}`}>
                    {where.name}
                  </span>
                </ListItemText>
              </ListItem>
            </List>
          </fieldset>
        </ListItem>
        <ListItem>
          <fieldset className={classes.fieldset}>
            <List>
              <ListItem>
                <ListItemText className={classes.reviewTitle}>
                  When
                  <span className={`${classes.reviewValue} ${classes.right}`}>
                    {dateString}
                  </span>
                </ListItemText>
              </ListItem>
            </List>
          </fieldset>
        </ListItem>
        <ListItem>
          <fieldset className={classes.fieldset}>
            <List>
              <ListItem>
                <ListItemText className={classes.reviewTitle}>
                  Measure
                  <span className={`${classes.reviewValue} ${classes.right}`}>
                    {measure.name}
                  </span>
                </ListItemText>
              </ListItem>
            </List>
          </fieldset>
        </ListItem>
        <div className={classes.stepperButtons}>
          <Button
            variant="contained"
            onClick={() => {
              clearContext();
              handleBack();
            }}
            className={`${classes.nextButton} ${classes.left}`}
          >
            Back
          </Button>
        </div>
      </List>
    </div>
  );
};

Review.propTypes = {
  searchTerms: PropTypes.arrayOf(PropTypes.shape()),
  story: PropTypes.string,
  classes: PropTypes.shape(),
  handleReset: PropTypes.func,
  handleBack: PropTypes.func,
  isLoadingContext: PropTypes.bool,
  context: PropTypes.arrayOf(PropTypes.shape()),
  searchEngine: PropTypes.shape(),
  clearContext: PropTypes.func,
  suggestedContext: PropTypes.arrayOf(PropTypes.shape()),
  client: PropTypes.string
};

Review.defaultProps = {
  searchTerms: [],
  story: "",
  classes: {},
  handleReset: () => {},
  handleBack: () => {},
  isLoadingContext: false,
  context: [],
  searchEngine: {},
  clearContext: () => {},
  suggestedContext: [],
  client: ""
};

export default Review;
