import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { Collapse, Paper } from "@material-ui/core";
import { DateRange } from "@material-ui/icons";
import { getCompareDates } from "../../utils";

const showCompareDate = (periodOption, startDate, endDate) => {
  const [compareStartDate, compareEndDate] = getCompareDates(
    periodOption,
    startDate,
    endDate
  );
  return `${format(compareStartDate, "dd/MM/yyyy")} - ${format(
    compareEndDate,
    "dd/MM/yyyy"
  )}`;
};

const CompareDateBox = props => {
  const { periodOption, startDate, endDate, classes } = props;
  return (
    <Collapse className={classes.listItem} in={periodOption !== "Custom"}>
      <Paper elevation={0} className={classes.dateRangeBox}>
        <div className={classes.dateRangeDates}>
          <div>
            <DateRange />
          </div>
          <div className={classes.compareDateText}> Compare date:</div>
          <br />
        </div>
        <div>
          {periodOption !== "Custom" && startDate && endDate
            ? showCompareDate(periodOption, startDate, endDate)
            : "-"}
        </div>
      </Paper>
    </Collapse>
  );
};

CompareDateBox.propTypes = {
  periodOption: PropTypes.string,
  startDate: PropTypes.shape(),
  endDate: PropTypes.shape(),
  classes: PropTypes.shape()
};

CompareDateBox.defaultProps = {
  periodOption: "",
  startDate: {},
  endDate: {},
  classes: {}
};

export default CompareDateBox;
