import React from "react";
import { Grid, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    flexGrow: 1
  },
  root: {
    position: "relative"
  },
  bottom: {
    color: "#e5e0df"
  },
  top: {
    color: "#009dbc",
    animationDuration: "550ms",
    position: "absolute",
    left: 0
  },
  circle: {
    strokeLinecap: "round"
  }
});

const Loader = props => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center" className={classes.container}>
      <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          value={100}
          {...props}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.circle
          }}
          {...props}
        />
      </div>
    </Grid>
  );
};

export default Loader;
