import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Grid,
  Card,
  CardHeader,
  IconButton,
  FormControl,
  TextField,
  Button,
  FormHelperText,
  Collapse
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import { Loader } from "../SharedComponents";
import { createUser, getDepartments, addDepartment } from "../../actions/user";

const filter = createFilterOptions();

const CreateAccount = props => {
  const {
    error,
    message,
    submitCreateUserForm,
    isLoading,
    onClose,
    classes,
    client,
    departments,
    getDepts,
    addDept
  } = props;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [department, setDepartment] = useState("");
  const [startDate, setStartDate] = useState(null);
  useEffect(() => getDepts(client), []);

  const reset = () => {
    setName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setDepartment("");
    setStartDate(null);
  };

  return (
    <div className="CreateAccount">
      <Card className={classes.card}>
        <CardHeader
          titleTypographyProps={{
            variant: "h6",
            className: classes.heading
          }}
          avatar={
            <IconButton
              onClick={() => {
                onClose();
                reset();
              }}
            >
              <ArrowBack className={classes.icon} />
            </IconButton>
          }
          title="Create Account"
        />
        <Grid item md={6}>
          <form className={classes.form}>
            <FormControl>
              <TextField
                className={classes.formInput}
                placeholder="Name"
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
                variant="outlined"
              />
            </FormControl>
            <br />
            <FormControl>
              <TextField
                className={classes.formInput}
                placeholder="Email"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                variant="outlined"
              />
            </FormControl>
            <br />
            <FormControl>
              <TextField
                className={classes.formInput}
                placeholder="Password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                variant="outlined"
              />
            </FormControl>
            <br />
            <FormControl>
              <TextField
                className={classes.formInput}
                placeholder="Confirm password"
                type="password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                variant="outlined"
              />
            </FormControl>
            <br />
            <Collapse in={!email.includes("@interrodata.com")}>
              <Autocomplete
                value={department}
                freeSolo
                onChange={(e, newValue) => {
                  if (typeof newValue === "string") {
                    setDepartment(newValue);
                  } else if (newValue && newValue.newOption) {
                    // Create a new value from the user input
                    setDepartment(newValue.newOption);
                    addDept(newValue.newOption, client);
                  } else {
                    setDepartment(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  // Suggest the creation of a new value
                  if (params.inputValue !== "") {
                    filtered.push({
                      newOption: params.inputValue,
                      label: `Add "${params.inputValue}"`
                    });
                  }
                  return filtered;
                }}
                getOptionLabel={option => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.newOption) {
                    return option.label;
                  }
                  // Regular option
                  return option;
                }}
                classes={{
                  inputRoot: classes.inputRoot,
                  root: classes.formInput
                }}
                options={departments}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder="Department"
                    type="text"
                    value={department}
                    variant="outlined"
                  />
                )}
              />
              <br />
              <DatePicker
                className="datepicker"
                customInput={<TextField variant="outlined" />}
                placeholderText="Start date"
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={date => {
                  setStartDate(date);
                }}
                minDate={new Date()} // today
                withPortal
                fixedHeight
              />
              <br />
            </Collapse>
            <div className={classes.buttons}>
              <Button
                className={classes.submitButton}
                variant="contained"
                type="sumbit"
                disableElevation
                onClick={e => {
                  e.preventDefault();
                  const formattedDate = startDate
                    ? format(startDate, "yyyy-MM-dd")
                    : "";
                  submitCreateUserForm(
                    name,
                    email,
                    password,
                    confirmPassword,
                    department,
                    client,
                    formattedDate
                  );
                  // if success
                  if (message.length > 0) {
                    reset();
                  }
                }}
              >
                Create account
              </Button>
              <Button
                className={classes.cancelButton}
                type="cancel"
                disableElevation
                onClick={e => {
                  e.preventDefault();
                  onClose();
                  reset();
                }}
              >
                Close
              </Button>
            </div>
            {error.length > 0 ? (
              <FormHelperText className={classes.errors}>
                {error.startsWith("ERROR:") ? error.substring(12) : error}
              </FormHelperText>
            ) : null}
            {message.length > 0 ? (
              <FormHelperText className={classes.success}>
                {message}
              </FormHelperText>
            ) : null}
          </form>
          {isLoading ? <Loader /> : null}
        </Grid>
      </Card>
    </div>
  );
};

CreateAccount.propTypes = {
  submitCreateUserForm: PropTypes.func,
  error: PropTypes.string,
  message: PropTypes.string,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  classes: PropTypes.shape(),
  client: PropTypes.string,
  departments: PropTypes.arrayOf(PropTypes.string),
  getDepts: PropTypes.func,
  addDept: PropTypes.func
};

CreateAccount.defaultProps = {
  submitCreateUserForm: () => {},
  error: "",
  message: "",
  isLoading: false,
  onClose: () => {},
  classes: {},
  client: "",
  departments: [],
  getDepts: () => {},
  addDept: () => {}
};

const mapDispatchToProps = dispatch => ({
  submitCreateUserForm: (
    name,
    email,
    password,
    confirmPassword,
    department,
    client,
    startDate
  ) => {
    dispatch(
      createUser(
        name,
        email,
        password,
        confirmPassword,
        department,
        client,
        startDate
      )
    );
  },
  getDepts: client => dispatch(getDepartments(client)),
  addDept: (dept, client) => dispatch(addDepartment(dept, client))
});

export default connect(null, mapDispatchToProps)(CreateAccount);
