import defaultState from "./defaultState";
import { GOTO_SLIDE } from "../constants/actionConstants";

const presentation = (state = defaultState.presentation, action) => {
  switch (action.type) {
    case GOTO_SLIDE:
      return {
        ...state,
        activeSlideIndex: action.slideIndex
      };
    default:
      return state;
  }
};

export default presentation;
