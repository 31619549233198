import React from "react";
import PropTypes from "prop-types";

const PresentationNav = props => {
  const { slideIndex, reportLength, classes } = props;
  return (
    <div className={classes.slideCount} id="presentation-nav-container">
      <span id="presentation-current-slide-number">{slideIndex + 1}</span> /{" "}
      <span id="presentation-total-slide-number">{reportLength}</span>
    </div>
  );
};

PresentationNav.propTypes = {
  slideIndex: PropTypes.number,
  reportLength: PropTypes.number,
  classes: PropTypes.shape()
};

PresentationNav.defaultProps = {
  slideIndex: 0,
  reportLength: 0,
  classes: {}
};

export default PresentationNav;
