import config from "../config";

// ------ api constants

// main backend
export const BACKEND_HOST = config.serverURL.backend;
// report
export const REPORT_URL = `${BACKEND_HOST}/getReport`;

// static data
export const STATIC_DATA_HOST = config.serverURL.staticData;
// static data api
export const STATIC_DATA_URL = `${STATIC_DATA_HOST}/getStaticData`;
export const FILTER_STATIC_DATA_URL = `${STATIC_DATA_HOST}/getFilteredStaticData`;
export const CONTEXT_URL = `${STATIC_DATA_HOST}/getContext`;

// user
export const USER_HOST = config.serverURL.user;
// user api
export const CREATE_USER_URL = `${USER_HOST}/CreateUser`;
export const SIGN_IN_URL = `${USER_HOST}/SignIn`;
export const CHANGE_PASSWORD_URL = `${USER_HOST}/ChangePassword`;
export const UNBLOCK_USER_URL = `${USER_HOST}/UnblockUser`;
export const DISABLE_USER_URL = `${USER_HOST}/DisableUser`;
export const ENABLE_USER_URL = `${USER_HOST}/EnableUser`;
export const RESET_PASSWORD_URL = `${USER_HOST}/ResetPassword`;
export const USER_TRACKING_URL = `${USER_HOST}/AddUserTracking`;
export const LIST_RECENT_REPORTS_URL = `${USER_HOST}/ListRecentReports`;
export const ADD_TO_FAVOURITES_URL = `${USER_HOST}/AddToFavourites`;
export const REMOVE_FROM_FAVOURITES_URL = `${USER_HOST}/RemoveFromFavourites`;
export const LIST_FAVOURITES_URL = `${USER_HOST}/ListFavourites`;

// ------ app constants

// ------ aws config

const getAWSConfig = () => {
  switch (process.env.REACT_APP_DEV_ENV) {
    case "dev":
      return {
        accessKeyId: "AKIARJEYGV32PDEIO4OS",
        secretAccessKey: "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",
        region: "eu-west-2"
      };
    case "qa":
      return {
        accessKeyId: "AKIAXJWQC4GHNQINRIV2",
        secretAccessKey: "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",
        region: "eu-west-2"
      };
    case "stg":
      return {
        accessKeyId: "AKIAWMLRBVIPZVC6HGPX",
        secretAccessKey: "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",
        region: "eu-west-2"
      };
    case "prod":
      return {
        accessKeyId: "AKIASTBT7AYFQG2JSB7V",
        secretAccessKey: "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",
        region: "eu-west-2"
      };
    default:
      return {
        accessKeyId: "AKIARJEYGV32PDEIO4OS",
        secretAccessKey: "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",
        region: "eu-west-2"
      };
  }
};

const getAWSResources = () => {
  switch (process.env.REACT_APP_DEV_ENV) {
    case "dev":
      return {
        REPORT_TABLE_FRIDA: "frida-analytics-results-dev",
        DEPARTMENTS_TABLE: "departments-dev",
        CLIENTS_TABLE: "clients-dev",
        LOGO_BUCKET: "https://frida-assets-dev.s3.eu-west-2.amazonaws.com"
      };
    case "qa":
      return {
        REPORT_TABLE_FRIDA: "frida-analytics-results-qa",
        DEPARTMENTS_TABLE: "departments-qa",
        CLIENTS_TABLE: "clients-qa",
        LOGO_BUCKET: "https://frida-assets-qa.s3.eu-west-2.amazonaws.com"
      };
    case "stg":
      return {
        REPORT_TABLE_FRIDA: "frida-analytics-results-stg",
        DEPARTMENTS_TABLE: "departments-stg",
        CLIENTS_TABLE: "clients-stg",
        LOGO_BUCKET: "https://frida-assets-stg.s3.eu-west-2.amazonaws.com"
      };
    case "prod":
      return {
        REPORT_TABLE_FRIDA: "frida-analytics-results-prod",
        DEPARTMENTS_TABLE: "departments-prod",
        CLIENTS_TABLE: "clients-prod",
        LOGO_BUCKET: "https://frida-assets-prod.s3.eu-west-2.amazonaws.com"
      };
    default:
      return {
        REPORT_TABLE_FRIDA: "frida-analytics-results-dev",
        DEPARTMENTS_TABLE: "departments-dev",
        CLIENTS_TABLE: "clients-dev",
        LOGO_BUCKET: "https://frida-assets-dev.s3.eu-west-2.amazonaws.com"
      };
  }
};

const getAPIKey = () => {
  switch (process.env.REACT_APP_DEV_ENV) {
    case "dev":
      return "5vgs75Oos08OzeWcXX6R655dwzfjJNlc1gHCJV0v";
    case "qa":
      return "ZSzaaAYOtH72pHxtmo4x02ZrIjMwHKz769rDa7Gt";
    case "stg":
      return "Iv2gWYkH9W8UJZx1J94RdaQN5iEqi7JL481pTcQa";
    case "prod":
      return "inrfPOT2954nK10kRAUQG38c7fI48UmO7v5wQLpw";
    default:
      return "5vgs75Oos08OzeWcXX6R655dwzfjJNlc1gHCJV0v";
  }
};

export const AWS_CONFIG = {
  ...getAWSConfig()
};
export const REPORT_TABLE = {
  frida: `${getAWSResources().REPORT_TABLE_FRIDA}`
};
export const DEPARTMENTS_TABLE = `${getAWSResources().DEPARTMENTS_TABLE}`;
export const CLIENTS_TABLE = `${getAWSResources().CLIENTS_TABLE}`;
export const LOGO_BUCKET = `${getAWSResources().LOGO_BUCKET}`;
export const API_KEY = getAPIKey();

// cookie timeout
export const TIMEOUT = config.app.timeout;

// polling constants
export const FIRST_POLL_TIMER = config.app.firstPollTimer;
export const GENERAL_POLL_TIMER = config.app.generalPollTimer;
export const TOTAL_POLLS = config.app.totalPolls;

// data constants
export const GROUPINGS = config.groupings;
export const DATA_SETS = config.dataSets;

// number of search suggestions
export const NUMBER_SUGGESTIONS = config.app.numberSuggestions;
