import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  leftMenuTitle: {
    textAlign: "left",
    color: "#000034",
    backgroundColor: "#f9f9f9",
    fontSize: 16
  },
  leftMenuTitleIcon: {
    color: "#000034"
  },
  leftMenuIcon: {
    color: "#272525",
    fontSize: "16px !important"
  },
  disabled: {
    color: "rgba(0, 0, 0, 0.26)",
    fontSize: 14
  },
  leftMenuText: {
    display: "flex",
    alignItems: "center",
    color: "#272525",
    fontSize: 14
  },
  selected: {
    color: "#009dbc"
  },
  selectedMenu: {
    color: "#009cad"
  },
  heading: {
    color: "#000034"
  },
  subheading: {
    color: "#000034",
    marginTop: 20,
    marginLeft: 20,
    paddingBottom: 5
  },
  bold: {
    fontWeight: "bold"
  },
  subtext: {
    // description text
    padding: "0 20px",
    marginTop: -10
  },
  generateReportButton: {
    // generate report button, new report button
    backgroundColor: "#009dbc",
    margin: "11px 0 33px 16px",
    "&:hover": {
      backgroundColor: "#009dbc"
    }
  },
  historyContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  overflow: {
    overflow: "visible"
  },
  wait: {
    cursor: "wait",
    "& *": {
      pointerEvents: "none"
    }
  },
  starFiltering: {
    marginRight: 5,
    paddingRight: 16,
    paddingTop: 11
  },
  favourite: {
    color: "#faa61a"
  },
  reportCardContainer: {
    margin: "0 15px"
  },
  reportCard: {
    maxWidth: 345,
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.3)",
    borderRadius: 8
  },
  cardContent: {
    padding: 0,
    fontSize: "0.875rem",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: "0.01071em"
  },
  cardDate: {
    fontSize: 14,
    marginTop: -10
  },
  cardProduct: {
    fontSize: 14,
    fontWeight: "bold"
  },
  cardDownload: {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px 0",
    cursor: "pointer",
    fontWeight: "normal"
  },
  cardSubsection: {
    padding: "9px 16px"
  },
  cardPeriod: {
    backgroundColor: "#f7f3f2"
  },
  cardSubtitle: {
    display: "inline-block",
    fontSize: 13,
    color: "#8f8b8b",
    fontWeight: "normal"
  },
  cardValue: {
    display: "inline-block",
    color: "#565151",
    fontWeight: "bold"
  },
  periodSubtitle: {
    display: "inline-block",
    fontSize: 12,
    color: "#8f8b8b"
  },
  periodValue: {
    fontSize: 12
  },
  periodHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: 7,
    marginLeft: -3,
    color: "#c5351f",
    fontWeight: "bold"
  },
  storyType: {
    // report card - performance
    color: "#000074",
    fontWeight: "normal",
    fontSize: 12,
    backgroundColor: "#f7f3f2",
    marginTop: 10,
    borderRadius: "20px 0 0 20px",
    padding: "5px 10px",
    marginRight: -10
  },
  fieldset: {
    width: "100%",
    padding: 16,
    borderRadius: 4,
    boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.16)",
    marginBottom: 20
  },
  fieldsetLegend: {
    backgroundColor: "#fff",
    padding: "0 10px",
    fontWeight: "bold"
  },
  fieldsetLegendSubheading: {
    fontSize: "80%"
  },
  subtitle: {
    padding: "0 20px",
    fontSize: 14,
    margin: "20px 0px 5px 0px",
    fontWeight: "bold"
  },
  searchSubtitle: {
    padding: 0
  },
  selectContainer: {
    width: "50%",
    paddingLeft: 10
  },
  whatSelect: {
    // advanced search
    width: "50%",
    padding: 10,
    float: "left",
    "nth-of-type(odd)": {
      paddingRight: 0
    },
    "nth-of-type(even)": {
      paddingLeft: 0
    }
  },
  whatInputLabel: {
    // advanced search
    textTransform: "capitalize",
    fontSize: 14,
    color: "#3c3838",
    paddingBottom: 5
  },
  advancedSearchButton: {
    color: "#009cad",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#009cad",
      color: "#fff"
    },
    margin: "11px 0 33px 0",
    border: "solid 1px #009cad"
  },
  closeAdvancedSearchButton: {
    margin: "0 auto",
    display: "block"
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 0,
    paddingLeft: 10,
    paddingRight: 0
  },
  errors: {
    color: "#f00",
    marginLeft: 11
  },
  radioText: {
    fontSize: 14
  },
  resultsButton: {
    // new performance
    color: "#fff",
    backgroundColor: "#009dbc",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#009dbc"
    },
    border: "solid 1px #009dbc",
    float: "right",
    margin: 10,
    marginTop: -63
  },
  searchInput: {
    // performance search bar
    borderRadius: 10,
    margin: "0 10px 15px",
    border: "solid 1px #e5e0df",
    height: 60,
    padding: 10,
    display: "flex",
    width: 780,
    overflowX: "auto"
  },
  searchInputField: {
    // performance search bar
    minWidth: 120,
    paddingLeft: 2
  },
  searchInputChip: {
    // performance search bar
    margin: 2
  },
  autocompleteSuggestions: {
    // performance search bar
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
    overflowY: "scroll",
    alignItems: "stretch",
    margin: "0 10px 20px",
    maxHeight: "12rem"
  },
  autocompleteButton: {
    // performance search bar
    transition: "all 0.25s ease-in-out",
    padding: 0,
    background: "white",
    border: "none",
    flexShrink: 0,
    textAlign: "left",
    justifyContent: "left",
    fontSize: "1rem",
    "&:hover, &.isSelected": {
      backgroundColor: "#cccccc",
      cursor: "pointer"
    },
    "&:active": {
      border: "none"
    },
    textTransform: "none",
    display: "inline"
  },
  suggestion: {
    fontWeight: 700,
    color: "#3c3838"
  },
  required: {
    color: "#f00",
    fontSize: 12
  },
  optional: {
    color: "#009cad"
  },
  searchBar: {
    // search bar - shared
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#009dbc"
    }
  },
  stepperButtons: {
    overflow: "auto"
  },
  nextButton: {
    // next button, generate report button
    backgroundColor: "#009dbc",
    color: "#fff",
    margin: 15,
    float: "right"
  },
  reviewTitle: {
    fontWeight: "bold"
  },
  reviewValue: {
    fontWeight: "bold",
    color: "#009dbc"
  },
  right: {
    float: "right"
  },
  left: {
    float: "left"
  },
  contextButton: {
    color: "#fff",
    backgroundColor: "#009cad",
    margin: "0 auto",
    marginBottom: -10
  }, // edit and save buttons
  hints: {
    marginLeft: -10
  },
  hintIcon: {
    color: "#fff",
    backgroundColor: "#009cad",
    borderRadius: "50%",
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: "0.9rem"
  },
  hintText: {
    color: "#009cad",
    marginLeft: 10
  },
  suggestedContext: {
    padding: 20
  }
}));

export default useStyles;
