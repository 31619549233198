import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { NUMBER_SUGGESTIONS } from "../../constants";

const SearchBar = props => {
  const {
    searchOptions,
    value,
    onChange,
    searchEngine,
    disabled,
    multiple,
    classes,
    placeholder
  } = props;
  return (
    <div className="SearchBar">
      <Autocomplete
        className={classes.searchBar}
        freeSolo
        options={searchOptions.map(i => ({ ...i, name: i.name[0] }))}
        getOptionLabel={option =>
          option.name
            ? (option.name.value && option.name.value) || option.name
            : option.label
        }
        renderOption={option =>
          `${option.name.value ? option.name.value : option.name} (${
            option.subsection
          })`
        }
        renderInput={params => (
          <TextField
            {...params}
            margin="normal"
            variant="outlined"
            placeholder={placeholder}
          />
        )}
        filterOptions={(options, state) => {
          const { inputValue } = state;
          const suggestions = searchEngine
            .search(inputValue)
            .slice(0, NUMBER_SUGGESTIONS)
            .map(i => ({ ...i.item, name: i.item.name[0] }));
          const intersection = _.intersectionWith(
            suggestions,
            options,
            _.isEqual
          );
          return _.differenceBy(
            intersection,
            value,
            i =>
              ((i.name.value && i.name.value) ||
                (i.name.period && i.name.period) ||
                i.name) + i.subsection
          );
        }}
        onChange={onChange}
        value={value}
        disabled={disabled || _.isEmpty(searchEngine)}
        multiple={multiple}
        filterSelectedOptions
      />
    </div>
  );
};

SearchBar.propTypes = {
  searchOptions: PropTypes.arrayOf(PropTypes.shape()),
  value: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape())
  ]),
  onChange: PropTypes.func,
  searchEngine: PropTypes.shape(),
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  classes: PropTypes.shape(),
  placeholder: PropTypes.string
};

SearchBar.defaultProps = {
  searchOptions: [],
  value: {} || [],
  onChange: () => {},
  searchEngine: {},
  disabled: false,
  multiple: false,
  classes: {},
  placeholder: ""
};

export default SearchBar;
