import React from "react";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import ReportText from "./ReportText";
import ReportImage from "./ReportImage";
import ReportShape from "./ReportShape";
import ReportTable from "./ReportTable";
import ReportChart from "./ReportChart";
import ReportTemplate from "./ReportTemplate";

const Presentation = props => {
  const { activeSlideIndex, report, client, classes } = props;
  const slideData = report[activeSlideIndex];
  if (!slideData) {
    return (
      <div className="Presentation">
        <Paper className={classes.presentation} elevation={3}>
          <div>No slide data.</div>
        </Paper>
      </div>
    );
  }
  return (
    <div className="Presentation">
      <Paper className={classes.presentation} elevation={3} id="presentation">
        <ReportTemplate activeSlideIndex={activeSlideIndex} client={client} />
        {slideData.images
          ? slideData.images.map((i, key) => (
              <ReportImage
                key={key.toString()}
                data={i.data}
                layout={i.layout}
              />
            ))
          : null}
        {slideData.text
          ? slideData.text.map((i, key) => (
              <ReportText
                key={key.toString()}
                data={i.data}
                display={i.display}
                layout={i.layout}
              />
            ))
          : null}
        {slideData.shapes
          ? slideData.shapes.map((i, key) => (
              <ReportShape
                key={key.toString()}
                data={i.data}
                display={i.display}
                layout={i.layout}
              />
            ))
          : null}
        {slideData.tables
          ? slideData.tables.map((i, key) => (
              <ReportTable
                key={key.toString()}
                data={i.data}
                display={i.display}
                layout={i.layout}
              />
            ))
          : null}
        {slideData.charts
          ? slideData.charts
              .filter(j => !(j.display.apply && j.display.apply[0] === "multi"))
              .map((i, key) => (
                <ReportChart
                  key={key.toString()}
                  data={i.data}
                  display={i.display}
                  layout={i.layout}
                />
              ))
          : null}
        {/* Refactoring the data from the backend for the multi charts */}
        {/* currently assumes only 2 items: 0 is clustered_column or stacked_column, 1 is line OR 0 is line and 1 is clustered_column */}
        {slideData.charts &&
        slideData.charts.filter(
          j => j.display.apply && j.display.apply[0] === "multi"
        ).length > 0
          ? [
              {
                ...slideData.charts.filter(
                  j => j.display.apply && j.display.apply[0] === "multi"
                )
              }
            ].map((i, key) => (
              <ReportChart
                key={key.toString()}
                data={{
                  type: "multi",
                  0: i[0].data,
                  1: i[1].data
                }}
                display={{ 0: i[0].display, 1: i[1].display }}
                layout={i[1].layout}
              />
            ))
          : null}
      </Paper>
    </div>
  );
};

Presentation.propTypes = {
  activeSlideIndex: PropTypes.number,
  report: PropTypes.arrayOf(PropTypes.shape()),
  client: PropTypes.string,
  classes: PropTypes.shape()
};

Presentation.defaultProps = {
  activeSlideIndex: 0,
  report: [],
  client: "",
  classes: {}
};

export default Presentation;
