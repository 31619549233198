import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles
} from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { Link } from "react-router-dom";
import header from "../images/header.png";
import performance from "../images/performance.png";
import brandBattles from "../images/brand-battles.png";
import rangeReview from "../images/range-review.png";
import { UpgradeIcon } from "./Icons";

const useStyles = makeStyles({
  container: {
    maxWidth: 1300,
    margin: "0 auto",
    marginBottom: 100
  },
  paper: {
    width: "100%"
  },
  header: {
    margin: 0,
    backgroundImage: `url(${header})`,
    backgroundRepeat: "no-repeat",
    color: "#fff"
  },
  headerLeft: {
    textAlign: "center",
    marginTop: 120,
    marginBottom: 100,
    marginLeft: -15,
    fontWeight: "bold",
    fontSize: 24,
    lineHeight: 1.2
  },
  icon: {
    fontSize: 72
  },
  interrodata: {
    fontWeight: "bold"
  },
  premium: {
    letterSpacing: 2,
    textTransform: "uppercase",
    fontWeight: "bold"
  },
  headerTitle: {
    marginTop: 85,
    fontWeight: "bold"
  },
  headerText: {
    lineHeight: 1.5,
    marginRight: 30
  },
  headerButton: {
    color: "#000074",
    backgroundColor: "#fff",
    fontWeight: "bold",
    textTransform: "none",
    fontSize: 16,
    marginTop: 30,
    "&:hover": {
      backgroundColor: "#fff"
    }
  },
  typeContainer: {
    padding: 50,
    color: "#3c3838"
  },
  background: {
    backgroundColor: "#f7f7f7"
  },
  title: {
    fontWeight: "bold"
  },
  subtitle: {
    fontSize: 18,
    lineHeight: "24px",
    fontWeight: "bold",
    color: "#000074",
    marginBottom: 20
  },
  button: {
    textTransform: "none",
    cursor: "auto",
    paddingRight: 30,
    paddingLeft: 30,
    marginTop: 40,
    marginBottom: 10,
    backgroundColor: "#f7f7f7",
    "&:hover": {
      backgroundColor: "#f7f7f7"
    }
  },
  backgroundButton: {
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff"
    }
  },
  listItem: {
    alignItems: "flex-start"
  },
  highlight: {
    fontWeight: "bold",
    color: "#171414"
  },
  image: {
    margin: 30
  },
  performance: {
    color: "#faa61a"
  },
  brandBattles: {
    color: "#ef4129"
  },
  rangeReview: {
    color: "#009cad"
  },
  footer: {
    margin: "0 auto",
    padding: 50,
    paddingTop: 100,
    color: "#3c3838",
    backgroundColor: "#f7f7f7",
    textAlign: "center"
  },
  footerSubtext: {
    color: "#736f6f",
    fontSize: 18
  },
  footerTitle: {
    color: "#000074",
    fontWeight: "bold",
    fontSize: 24,
    marginBottom: 30
  },
  footerText: {
    width: "50%",
    margin: "0 auto"
  },
  footerButton: {
    color: "#fff",
    backgroundColor: "#000074",
    fontWeight: "bold",
    textTransform: "none",
    fontSize: 16,
    marginTop: 30,
    marginBottom: 50,
    "&:hover": {
      backgroundColor: "#000074"
    }
  }
});

const FindOutMore = () => {
  const classes = useStyles();
  return (
    <div className="FindOutMore">
      <Grid container className={classes.container}>
        <Paper className={classes.paper}>
          {/* Header */}
          <Grid item md={12} className={classes.header} container>
            <Grid item md={2} />
            <Grid item md={3} xs={4} className={classes.headerLeft}>
              <UpgradeIcon className={classes.icon} />
              <Typography variant="h5" className={classes.interrodata}>
                Interrodata
              </Typography>
              <Typography variant="h5" gutterBottom className={classes.premium}>
                Premium
              </Typography>
            </Grid>
            <Grid item md={6} xs={8}>
              <Typography
                variant="h5"
                gutterBottom
                className={classes.headerTitle}
              >
                Supercharge what you can do with your data
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                className={classes.headerText}
              >
                Interrodata premium gives everyone in your business a dynamic
                range of ways to unlock hidden revenue opportunities.
                <br />
                Only with Interrodata Premium can you empower all your teams
                with the clarity and confidence to act before the competition.
              </Typography>
              <Button
                variant="contained"
                className={classes.headerButton}
                component={Link}
                to="/upgrade"
                disableElevation
              >
                Talk to our Sales Team
              </Button>
            </Grid>
            <Grid item md={1} />
          </Grid>
          {/* Performance */}
          <Grid item md={12} className={classes.typeContainer} container>
            <Grid item md={7}>
              <Typography
                variant="h4"
                gutterBottom
                className={`${classes.title} ${classes.performance}`}
              >
                Performance Reports
              </Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                className={classes.subtitle}
              >
                Detailed report about drivers of change, causal factors, key
                performing products and more...
              </Typography>
              <Typography variant="body2" gutterBottom>
                You have data about everything in your business – understanding
                all of it is nigh on impossible. Performance reports allow
                anyone to ask a question about a brand, category, sector or pack
                type.
              </Typography>
              <Button
                variant="contained"
                className={`${classes.button} ${classes.performance}`}
                disableElevation
                disableRipple
              >
                Perfect for
              </Button>
              <List>
                <ListItem className={classes.listItem}>
                  <ListItemIcon>
                    <ArrowForward className={classes.performance} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body2">
                        <span className={classes.highlight}>
                          Marketing teams
                        </span>{" "}
                        to understand market trends & those areas that might
                        need a bit more TLC.
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon>
                    <ArrowForward className={classes.performance} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body2">
                        <span className={classes.highlight}>
                          Senior executives
                        </span>{" "}
                        to get a quick and holistic understanding of performance
                        without distracting the Insights team from high level
                        strategic work.
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item md={5}>
              <img
                alt="performance"
                src={performance}
                justify="center"
                className={classes.image}
              />
            </Grid>
          </Grid>
          {/* Brand Battles */}
          <Grid
            item
            md={12}
            className={`${classes.typeContainer} ${classes.background}`}
            container
          >
            <Grid item md={5}>
              <img
                alt="brand battles"
                src={brandBattles}
                justify="center"
                className={classes.image}
              />
            </Grid>
            <Grid item md={7}>
              <Typography
                variant="h4"
                gutterBottom
                className={`${classes.title} ${classes.brandBattles}`}
              >
                Brand Battles&trade;
              </Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                className={classes.subtitle}
              >
                Empower your organization to act both before, and directly
                against your key competitors
              </Typography>
              <Typography variant="body2" gutterBottom>
                Be first off the line with Brand Battles.
                <br />
                <br />
                Ask IDA any question about the relative performance of your
                product, brand or even the whole organisation against your
                closest competition.
                <br />
                IDA will generate a rich report that outlines the key drivers of
                share change between the two products. She will even give you
                recommended actions to leave your rivals thinking &quot;how on
                earth did they know to do that!&quot;
              </Typography>
              <Button
                variant="contained"
                className={`${classes.button} ${classes.backgroundButton} ${classes.brandBattles}`}
                disableElevation
                disableRipple
              >
                Perfect for
              </Button>
              <List>
                <ListItem className={classes.listItem}>
                  <ListItemIcon>
                    <ArrowForward className={classes.brandBattles} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body2">
                        <span className={classes.highlight}>Sales teams</span>{" "}
                        to use to prepare their top-level strategy, answer
                        customer questions on the fly and show retailers why
                        your product should take pride of place on the shelf.
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
          {/* Range Review */}
          <Grid item md={12} className={classes.typeContainer} container>
            <Grid item md={7}>
              <Typography
                variant="h4"
                gutterBottom
                className={`${classes.title} ${classes.rangeReview}`}
              >
                Range Review Impact Assessments
              </Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                className={classes.subtitle}
              >
                Report on performance of any range review you’ve undertaken – in
                minutes.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Range Reviews are a crucial part of the FMCG business process.
                Yet, clients told us that they barely had time to assess the
                previous Review, yet alone build strategies and recommendations
                for their customers.
                <br />
                This allows you to spend your time developing actionable,
                revenue-driving recommendations to customers rather than
                scrambling to merely explain what’s happened.
              </Typography>
              <Button
                variant="contained"
                className={`${classes.button} ${classes.rangeReview}`}
                disableElevation
                disableRipple
              >
                Perfect for
              </Button>
              <List>
                <ListItem className={classes.listItem}>
                  <ListItemIcon>
                    <ArrowForward className={classes.rangeReview} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body2">
                        <span className={classes.highlight}>
                          Category teams
                        </span>{" "}
                        who are frustrated at consistently looking back, not
                        forward.
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item md={5}>
              <img
                alt="range review"
                src={rangeReview}
                justify="center"
                className={classes.image}
              />
            </Grid>
          </Grid>
          {/* Footer */}
          <Grid
            item
            md={12}
            className={classes.footer}
            container
            justifyContent="center"
          >
            <Grid item md={12}>
              <Typography
                variant="body2"
                className={classes.footerSubtext}
                gutterBottom
              >
                And more...
              </Typography>
              <Typography
                variant="h5"
                className={classes.footerTitle}
                gutterBottom
              >
                Solve real world problems that truly matter to your
                organization.
              </Typography>
              <Typography
                variant="body2"
                className={classes.footerText}
                gutterBottom
              >
                <span className={classes.highlight}>
                  With Interrodata Premium
                </span>
                <br />
                we can help profile your business and build capabilities and
                features that can solve your specific pains and frustrations
              </Typography>
              <Button
                variant="contained"
                className={classes.footerButton}
                component={Link}
                to="/upgrade"
                disableElevation
              >
                Talk to our Sales Team
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default FindOutMore;
