import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  FormControlLabel,
  Radio,
  Typography,
  ListItem
} from "@material-ui/core";

const CompareOptions = props => {
  const {
    periodOption,
    setPeriodOption,
    startDate,
    endDate,
    checkPeriodErrors,
    selectedPeriod,
    setDateError,
    setCustomStartDate,
    setCustomDateError,
    setCustomEndDate,
    isCalendarOpen,
    onSelect,
    classes
  } = props;
  return (
    <Grid item xs={12} sm={6}>
      <ListItem className={classes.listItem}>
        <FormControlLabel
          control={
            <Radio
              value="yearAgo"
              checked={periodOption === "yearAgo"}
              onChange={e => {
                setPeriodOption(e.target.value);
                if (startDate && endDate) {
                  checkPeriodErrors(startDate, endDate);
                  onSelect(startDate, endDate, e.target.value, selectedPeriod);
                }
              }}
              name="periodOption"
              disabled={isCalendarOpen}
            />
          }
          label={
            <Typography className={classes.radioText}>
              Compare to the same period a year ago
            </Typography>
          }
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <FormControlLabel
          control={
            <Radio
              value="Custom"
              checked={periodOption === "Custom"}
              onChange={e => {
                if (!startDate || !endDate) {
                  setDateError("Please choose a period");
                } else {
                  setPeriodOption(e.target.value);
                  // reset custom dates
                  setCustomStartDate(null);
                  setCustomEndDate(null);
                  setCustomDateError(null);
                  // remove current period but don't add a new one
                  onSelect(startDate, endDate, e.target.value, selectedPeriod);
                }
              }}
              name="periodOption"
              disabled={isCalendarOpen}
            />
          }
          label={
            <Typography className={classes.radioText}>Custom date</Typography>
          }
        />
      </ListItem>
    </Grid>
  );
};

CompareOptions.propTypes = {
  periodOption: PropTypes.string,
  setPeriodOption: PropTypes.func,
  startDate: PropTypes.shape(),
  endDate: PropTypes.shape(),
  checkPeriodErrors: PropTypes.func,
  selectedPeriod: PropTypes.objectOf(PropTypes.string),
  setDateError: PropTypes.func,
  setCustomStartDate: PropTypes.func,
  setCustomDateError: PropTypes.func,
  setCustomEndDate: PropTypes.func,
  isCalendarOpen: PropTypes.bool,
  onSelect: PropTypes.func,
  classes: PropTypes.shape()
};

CompareOptions.defaultProps = {
  periodOption: "",
  setPeriodOption: () => {},
  startDate: {},
  endDate: {},
  checkPeriodErrors: () => {},
  selectedPeriod: undefined,
  setDateError: () => {},
  setCustomStartDate: () => {},
  setCustomDateError: () => {},
  setCustomEndDate: () => {},
  isCalendarOpen: false,
  onSelect: () => {},
  classes: {}
};

export default CompareOptions;
