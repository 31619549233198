import React from "react";
import PropTypes from "prop-types";
import {
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Legend,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceLine
} from "recharts";
import { getNiceTickValues } from "recharts-scale";
import { scaleLinear } from "d3-scale";

const getDomain = data => {
  const allValues = data[1].y[0][1];
  for (let i = 0; i < data[0].y.length; i += 1) {
    allValues.push(...data[0].y[i][1]);
  }
  const minValue = Math.min(...allValues, 0);
  const maxValue = Math.max(...allValues, 0);
  const domain = [minValue, maxValue];
  const tickValues = getNiceTickValues(domain);
  return [Math.min(...tickValues), Math.max(...tickValues)];
};

const Multi = props => {
  const { data, display, chartData, colors, formatNumber } = props;
  return (
    <ResponsiveContainer>
      <ComposedChart
        data={chartData}
        margin={{ top: 40, bottom: 40, left: 40, right: 80 }}
        stackOffset="sign"
      >
        <Legend verticalAlign="top" wrapperStyle={{ fontSize: 12 }} />
        <CartesianGrid
          vertical={false}
          horizontal={display[0].value_axis.has_major_gridlines}
        />
        <XAxis
          axisLine={display[0].category_axis.visible}
          hide={!display[0].category_axis.visible}
          type="category"
          dataKey="x"
          fontSize={display[0].category_axis.tick_labels.font.size}
          interval={0}
          angle={chartData[0] && chartData[0].x.length > 10 ? -45 : 0}
          textAnchor={
            chartData[0] && chartData[0].x.length > 10 ? "end" : "middle"
          }
          height={chartData[0] && chartData[0].x.length > 10 ? 70 : 30}
        />
        {data[0].type === "stacked_column" ? (
          <ReferenceLine
            ifOverflow="extendDomain"
            y={0}
            yAxisId="left"
            stroke="#333"
          />
        ) : null}
        <YAxis
          axisLine={display[0].value_axis.visible}
          hide={!display[0].value_axis.visible}
          type="number"
          yAxisId="left"
          tickFormatter={tick =>
            formatNumber(tick, display[0].value_axis.tick_labels.number_format)
          }
          fontSize={display[0].value_axis.tick_labels.font.size}
          label={{
            value: `${
              display[0].value_axis.axis_title
                ? display[0].value_axis.axis_title.text_frame.text
                : ""
            }`,
            angle: -90,
            style: {
              fill: "#7a7a7a"
            },
            dx: -25
          }}
          domain={
            data[0].type === "stacked_column" ? getDomain(data) : [0, "auto"]
          }
          ticks={
            data[0].type === "stacked_column"
              ? scaleLinear().domain(getDomain(data)).nice().ticks(6)
              : null
          }
        />
        <YAxis
          axisLine={display[1].value_axis.visible}
          hide={!display[1].value_axis.visible}
          type="number"
          orientation="right"
          yAxisId="right"
          tickFormatter={tick =>
            formatNumber(tick, display[1].value_axis.tick_labels.number_format)
          }
          fontSize={display[1].value_axis.tick_labels.font.size}
          label={{
            value: `${
              display[1].value_axis.axis_title
                ? display[1].value_axis.axis_title.text_frame.text
                : ""
            }`,
            angle: 90,
            style: {
              fill: "#7a7a7a"
            },
            dx: 25
          }}
          domain={
            data[0].type === "stacked_column" ? getDomain(data) : [0, "auto"]
          }
          ticks={
            data[0].type === "stacked_column"
              ? scaleLinear().domain(getDomain(data)).nice().ticks(6)
              : null
          }
        />
        {data[0].type === "line"
          ? data[1].y.map((i, key) => (
              <Bar
                key={key.toString()}
                dataKey={`${i[0]}`}
                fill={
                  data[1].y.length > 1
                    ? colors[key + (data[1].y.length % colors.length)]
                    : colors.slice().reverse()[key % colors.length]
                }
                yAxisId="right"
              />
            ))
          : null}
        {data[0].y.map((i, key) => {
          let colour = colors[key % colors.length];
          if (display[0].color_map) {
            colour =
              display[0].color_map[key] === undefined
                ? colors[key % colors.length]
                : `#${display[0].color_map[key]}`;
          }
          if (data[0].type === "line") {
            return (
              <Line
                dataKey={`${i[0]}`}
                key={`${i[0]}`}
                stroke={
                  display[0].color_map ? colour : colors[key % colors.length]
                }
                yAxisId="left"
              />
            );
          }
          if (data[0].type === "stacked_column") {
            return (
              <Bar
                key={key.toString()}
                dataKey={`${i[0]}`}
                fill={
                  display[0].color_map ? colour : colors[key % colors.length]
                }
                yAxisId="left"
                stackId="a"
              />
            );
          }
          return (
            <Bar
              key={key.toString()}
              dataKey={`${i[0]}`}
              fill={display[0].color_map ? colour : colors[key % colors.length]}
              yAxisId="left"
            />
          );
        })}
        {data[0].type === "line"
          ? null
          : data[1].y.map((i, key) => (
              <Line
                dataKey={`${i[0]}`}
                key={`${i[0]}`}
                stroke={colors[key % colors.length]}
                yAxisId="right"
              />
            ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

Multi.propTypes = {
  data: PropTypes.shape(),
  display: PropTypes.shape(),
  chartData: PropTypes.arrayOf(PropTypes.shape()),
  colors: PropTypes.arrayOf(PropTypes.string),
  formatNumber: PropTypes.func
};

Multi.defaultProps = {
  data: {},
  display: {},
  chartData: [],
  colors: [],
  formatNumber: () => {}
};

export default Multi;
