import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Popover,
  Drawer
} from "@material-ui/core";
import { Menu, AccountCircleOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { logout } from "../actions/user";
import { UpgradeIcon, SettingsIcon, LogoutIcon } from "./Icons";
import { clearStaticDataCache } from "../actions/data";
import { clearAllSearchData } from "../actions/search";
import logo from "../images/logo.png";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "#fff !important"
  },
  theBar: {
    backgroundColor: "#000034",
    borderBottom: "1px solid #fff",
    height: 100
  },
  menuContainer: {
    width: "calc(90% - 80px)"
  },
  menuItems: {
    color: "#fff !important",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    textTransform: "none",
    textAlign: "center",
    margin: 10,
    height: 40
  },
  rightMenuItem: {
    float: "right"
  },
  subMenuItem: {
    color: "#000034 !important",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    textTransform: "none",
    display: "flex",
    flexDirection: "column",
    margin: "2px 10px",
    justifyContent: "left"
  },
  responsiveMenuItems: {
    color: "#000034"
  },
  navIcon: {
    display: "inline-block",
    height: 140,
    marginTop: -10
  },
  accountIcon: {
    fontSize: 28
  },
  upgradeButton: {
    backgroundColor: "#009cad",
    "&:hover": {
      backgroundColor: "#009cad"
    }
  },
  buttonIcon: {
    fontSize: "16px !important",
    marginLeft: 5
  }
}));

const MenuBar = props => {
  const { onLogout } = props;
  const classes = useStyles();
  const [state, setState] = useState({
    vpWidth: window.innerWidth
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const updatePredicate = () => {
    setState({ vpWidth: window.innerWidth });
  };

  const toggleDrawer = isOpen => e => {
    if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
      return;
    }
    setIsDrawerOpen(isOpen);
  };

  const openMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    updatePredicate();
  }, []);

  return (
    <div className={classes.root}>
      <AppBar className={classes.theBar} position="static">
        <Toolbar>
          {state.vpWidth < 900 && (
            <div>
              <IconButton
                edge="start"
                className={classes.menuButton}
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <Menu />
              </IconButton>
              <Drawer
                anchor="left"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
              >
                <List>
                  <Link
                    className={classes.responsiveMenuItems}
                    to="/"
                    onClick={toggleDrawer(false)}
                  >
                    <ListItem button>
                      <ListItemText primary="Home" />
                    </ListItem>
                  </Link>
                  <Link
                    className={classes.responsiveMenuItems}
                    to="/find-out-more"
                    onClick={toggleDrawer(false)}
                  >
                    <ListItem button>
                      <ListItemText primary="About" />
                    </ListItem>
                  </Link>
                  <Link
                    className={classes.responsiveMenuItems}
                    to="/support"
                    onClick={toggleDrawer(false)}
                  >
                    <ListItem button>
                      <ListItemText primary="Support" />
                    </ListItem>
                  </Link>
                  <Link
                    className={classes.responsiveMenuItems}
                    to="/settings"
                    onClick={toggleDrawer(false)}
                  >
                    <ListItem button>
                      <ListItemText primary="Settings" />
                    </ListItem>
                  </Link>
                  <Link
                    className={classes.responsiveMenuItems}
                    to="/logout"
                    onClick={() => {
                      onLogout();
                      toggleDrawer(false);
                    }}
                  >
                    <ListItem button>
                      <ListItemText primary="Logout" />
                    </ListItem>
                  </Link>
                </List>
              </Drawer>
            </div>
          )}
          <Link to="/" className={classes.navIcon}>
            <img src={logo} height="100%" alt="Interrodata" />
          </Link>
          {state.vpWidth > 900 && (
            <div className={classes.menuContainer}>
              <div>
                <Button className={classes.menuItems} component={Link} to="/">
                  Home
                </Button>
                <Button
                  className={classes.menuItems}
                  component={Link}
                  to="/find-out-more"
                >
                  About
                </Button>
                <Button
                  className={classes.menuItems}
                  component={Link}
                  to="/support"
                >
                  Support
                </Button>
                <div className={classes.rightMenuItem}>
                  <Button className={classes.menuItems} disabled>
                    Free trial
                  </Button>
                  <Button
                    className={`${classes.menuItems} ${classes.upgradeButton}`}
                    startIcon={<UpgradeIcon className={classes.buttonIcon} />}
                    component={Link}
                    to="/upgrade"
                  >
                    Upgrade
                  </Button>
                  <Button className={classes.menuItems} onClick={openMenu}>
                    <AccountCircleOutlined className={classes.accountIcon} />
                  </Button>
                </div>
              </div>
              <Popover
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center"
                }}
              >
                <Button
                  className={classes.subMenuItem}
                  component={Link}
                  to="/settings"
                  onClick={closeMenu}
                  startIcon={<SettingsIcon className={classes.buttonIcon} />}
                >
                  Settings
                </Button>
                <Button
                  className={classes.subMenuItem}
                  component={Link}
                  to="/logout"
                  onClick={() => {
                    onLogout();
                    closeMenu();
                  }}
                  startIcon={<LogoutIcon className={classes.buttonIcon} />}
                >
                  Logout
                </Button>
              </Popover>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

MenuBar.propTypes = {
  onLogout: PropTypes.func
};

MenuBar.defaultProps = {
  onLogout: () => {}
};

const mapDispatchToProps = dispatch => ({
  onLogout: () => {
    dispatch(logout());
    // force data refresh on logout
    dispatch(clearStaticDataCache());
    dispatch(clearAllSearchData());
  }
});

export default connect(null, mapDispatchToProps)(MenuBar);
