import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { generate } from "shortid";
import _ from "lodash";
import {
  Button,
  FormHelperText,
  ListItem,
  Avatar,
  Typography
} from "@material-ui/core";
import {
  enterAutocompleteTerm,
  enterSearchTerm,
  deleteLastSearchTerm,
  filterStaticData,
  removeFilterTerm,
  clearSearchHints,
  deleteTerm,
  checkSearchHints,
  getContext
} from "../../actions/search";
import AutocompleteSuggestion from "./AutocompleteSuggestion";
import SearchInput from "./SearchInput";

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSuggestionIndex: -1,
      selectedSuggestion: {}
    };
  }

  onHoverSuggestion(suggestion = null, i) {
    const { suggestions } = this.props;
    let newSuggestion = suggestion;
    let newIndex = i;
    if (!suggestion) {
      newSuggestion = suggestions[i] || {};
    }
    if (i >= suggestions.length) {
      newIndex = suggestions.length - 1;
    }
    if (i < -1) {
      newIndex = -1;
    }
    this.setState({
      selectedSuggestion: newSuggestion,
      selectedSuggestionIndex: newIndex
    });
  }

  onInputKeyUp(e) {
    const { enterTerm, searchTerms, filterTerms, story, client } = this.props;
    const { selectedSuggestionIndex, selectedSuggestion } = this.state;
    // select suggestions with arrow keys
    if (e.key === "ArrowUp") {
      this.onHoverSuggestion(null, selectedSuggestionIndex - 1);
      if (selectedSuggestionIndex !== -1) {
        document.getElementById("AutocompleteSuggestions").scrollBy(0, -28);
      }
    }
    if (e.key === "ArrowDown") {
      this.onHoverSuggestion(null, selectedSuggestionIndex + 1);
      if (selectedSuggestionIndex !== -1) {
        document.getElementById("AutocompleteSuggestions").scrollBy(0, 28);
      }
    }
    // select suggestion if enter button pressed
    // or click to show report if no suggestion selected
    if (e.key === "Enter") {
      if (selectedSuggestionIndex === -1) {
        document.getElementById("toReportButton").click();
      } else {
        enterTerm(selectedSuggestion, searchTerms, filterTerms, story, client);
      }
      this.clearHoverSuggestion();
    }
  }

  // clears the selectedSuggestion state.
  // Triggered when a hover suggestion is clicked on;
  // ensures state is cleaned when user selects autocomplete option.
  clearHoverSuggestion() {
    this.setState({
      selectedSuggestion: {},
      selectedSuggestionIndex: -1
    });
  }

  render() {
    const {
      autoCompleteTerm,
      searchTerms,
      onChange,
      onKeyUp,
      completedSearchTerm,
      suggestions,
      allTerms,
      enterTerm,
      hint,
      searchEngine,
      filteredSearchEngine,
      isFilteredData,
      filterTerms,
      story,
      classes,
      checkForErrors,
      searchHint,
      handleNext,
      getProductContext,
      client,
      placeholder
    } = this.props;
    const { selectedSuggestionIndex } = this.state;
    return (
      <div className="SearchBar">
        <div className={`${classes.subtitle} ${classes.boldSubtitle}`}>
          Describe what you would like to see. Add a product, market, time
          period, and metric.
        </div>
        {isFilteredData ? (
          <SearchInput
            onChange={e => onChange(e, filteredSearchEngine, story)}
            onKeyUp={(e, oldVal) => {
              this.onInputKeyUp(e);
              onKeyUp(e, oldVal, searchTerms, filterTerms, story, client);
            }}
            autoCompleteTerm={autoCompleteTerm.name}
            terms={searchTerms}
            story={story}
            classes={classes}
            client={client}
            placeholder={placeholder}
          />
        ) : (
          <SearchInput
            onChange={e => {
              if (suggestions.length === 0) {
                this.clearHoverSuggestion();
              }
              onChange(e, searchEngine, story);
            }}
            onKeyUp={(e, oldVal) => {
              this.onInputKeyUp(e);
              onKeyUp(e, oldVal, searchTerms, filterTerms, story, client);
            }}
            autoCompleteTerm={autoCompleteTerm.name}
            terms={searchTerms}
            story={story}
            classes={classes}
            filterTerms={filterTerms}
            client={client}
            placeholder={placeholder}
          />
        )}
        <Button
          className={classes.resultsButton}
          variant="outlined"
          disableElevation
          id="toReportButton"
          onClick={() => {
            if (allTerms) {
              const product = searchTerms.filter(s => s.table === "what");
              getProductContext(product, story, client);
              handleNext();
            } else {
              const err = checkForErrors(searchTerms);
              searchHint(err);
            }
          }}
        >
          Review & Run
        </Button>
        <ListItem className={classes.listItem}>
          <Avatar variant="rounded" className={classes.hintIcon}>
            i
          </Avatar>
          <Typography
            variant="subtitle2"
            component="div"
            className={classes.hintText}
          >
            We recommend that you only enter the distributor or brand here and
            add the product scope on the review screen.
          </Typography>
        </ListItem>
        <br />
        <div
          className={classes.autocompleteSuggestions}
          id="AutocompleteSuggestions"
        >
          {suggestions.map((suggestion, i) => (
            <AutocompleteSuggestion
              key={generate()}
              isSelected={i === selectedSuggestionIndex}
              suggestion={suggestion}
              onMouseOver={() => this.onHoverSuggestion(suggestion, i)}
              completedSearchTerm={completedSearchTerm}
              onClick={() => {
                enterTerm(
                  suggestion,
                  searchTerms,
                  filterTerms,
                  story,
                  client,
                  hint
                );
                this.clearHoverSuggestion();
              }}
              classes={classes}
            />
          ))}
        </div>
        <FormHelperText className={classes.errors}>{hint}</FormHelperText>
      </div>
    );
  }
}

SearchBar.propTypes = {
  onKeyUp: PropTypes.func,
  onChange: PropTypes.func,
  enterTerm: PropTypes.func,
  autoCompleteTerm: PropTypes.shape(),
  searchTerms: PropTypes.arrayOf(PropTypes.shape()),
  completedSearchTerm: PropTypes.string,
  suggestions: PropTypes.arrayOf(PropTypes.shape()),
  allTerms: PropTypes.bool,
  hint: PropTypes.string,
  searchEngine: PropTypes.shape(),
  filteredSearchEngine: PropTypes.shape(),
  isFilteredData: PropTypes.bool,
  filterTerms: PropTypes.arrayOf(PropTypes.shape()),
  story: PropTypes.string,
  classes: PropTypes.shape(),
  checkForErrors: PropTypes.func,
  searchHint: PropTypes.func,
  handleNext: PropTypes.func,
  getProductContext: PropTypes.func,
  client: PropTypes.string,
  placeholder: PropTypes.string
};

SearchBar.defaultProps = {
  onKeyUp: () => {},
  onChange: () => {},
  enterTerm: () => {},
  autoCompleteTerm: {},
  searchTerms: [],
  completedSearchTerm: "",
  suggestions: [],
  allTerms: false,
  hint: "",
  searchEngine: {},
  filteredSearchEngine: {},
  isFilteredData: false,
  filterTerms: [],
  story: "",
  classes: {},
  checkForErrors: () => {},
  searchHint: () => {},
  handleNext: () => {},
  getProductContext: () => {},
  client: "",
  placeholder: ""
};

const mapStateToProps = (state, ownProps) => {
  const { story, dataSet } = ownProps;
  const {
    search: {
      searchString = "",
      searchTerms = { story: [] },
      suggestions = [],
      autoCompleteTerm = {},
      filteredSearchEngine = { story: {} },
      isFilteredData = { story: false },
      filterTerms = { story: [] },
      hint = ""
    },
    data: { searchEngine = { dataSet: {} }, placeholder = { dataSet: "" } }
  } = state;

  const autoCompleteSearchTerm = autoCompleteTerm.name
    .replace(/^\s*/, "")
    .replace(/\s*$/, "");
  // completedSearchTerm = the entire search term except for the term that
  // we're currently matching. allows us to join it with any new search term.
  const autoCompleteTermRegex = autoCompleteSearchTerm
    ? new RegExp(`\\s*${autoCompleteSearchTerm}\\s*$`, "i")
    : "";
  const completedSearchTerm = searchString
    .replace(autoCompleteTermRegex, "")
    .replace(/^\s*/, "");

  return {
    searchTerms: searchTerms[story],
    suggestions,
    autoCompleteTerm,
    completedSearchTerm,
    searchEngine: searchEngine[dataSet],
    filteredSearchEngine: filteredSearchEngine[story],
    isFilteredData: isFilteredData[story],
    filterTerms: filterTerms[story],
    hint,
    placeholder: placeholder[dataSet]
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: (e, searchEngine, story) => {
    dispatch(enterSearchTerm(e.target.value, searchEngine, story));
  },
  onKeyUp: (e, oldVal = "", searchTerms, filterTerms, story, client) => {
    if (
      e.key === "Backspace" &&
      oldVal.length === 0 &&
      searchTerms.length > 0
    ) {
      const lastTerm = searchTerms[searchTerms.length - 1];
      dispatch(deleteLastSearchTerm(story));
      if (_.isEqual(lastTerm.table, "what")) {
        dispatch(removeFilterTerm(lastTerm, filterTerms, story, client));
      }
    }
  },
  enterTerm: (
    suggestion,
    searchTerms,
    filterTerms,
    story,
    client,
    hint = ""
  ) => {
    const term = suggestion.name.value
      ? { ...suggestion, name: suggestion.name.value, story }
      : { ...suggestion, story };
    if (suggestion.table === "where") {
      const whereTerm = searchTerms.find(i => i.table === "where");
      if (whereTerm) {
        dispatch(deleteTerm(whereTerm, story));
      }
    }
    if (suggestion.table === "when") {
      const whenTerm = searchTerms.find(i => i.table === "when");
      if (whenTerm) {
        dispatch(deleteTerm(whenTerm, story));
      }
    }
    if (suggestion.table === "measure") {
      const measureTerm = searchTerms.find(i => i.table === "measure");
      if (measureTerm) {
        dispatch(deleteTerm(measureTerm, story));
      }
    }
    dispatch(enterAutocompleteTerm(term, story));
    let table = "";
    switch (suggestion.table) {
      case "what":
        table = "product";
        break;
      case "where":
        table = "retailer";
        break;
      case "when":
        table = "time";
        break;
      case "measure":
        table = "measure";
        break;
      default:
        table = "";
    }
    if (hint.includes(table)) {
      dispatch(clearSearchHints());
    }
    // currently only filtering when the first what term is added
    if (_.isEqual(suggestion.table, "what")) {
      dispatch(filterStaticData(term, filterTerms, story, client));
    }
  },
  searchHint: hint => {
    dispatch(checkSearchHints(hint));
  },
  getProductContext: (terms, story, client) => {
    dispatch(getContext(terms, [], "product", story, client, true));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
