import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Paper, Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import robot from "../../images/error-robot.png";

const PresentationError = props => {
  const { errorMessage, classes } = props;
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.crossOrigin = "anonymous";
    script.src = "https://js.hsforms.net/forms/shell.js";
    document.getElementsByTagName("head")[0].appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "4913136",
          formId: "6d098052-78cd-4f9b-a165-f7f6f0a7e90f",
          target: "#hubspotErrorForm"
        });
      }
    });
  }, []);
  return (
    <div className={classes.errorContainer}>
      <img className={classes.errorImage} alt="robot" src={robot} />
      <Typography variant="h6" className={classes.oopsText}>
        Oops!
      </Typography>
      <Typography
        variant="subtitle2"
        gutterBottom
        className={classes.errorText}
        id="error" // for load-runner
      >
        {errorMessage}
      </Typography>
      <Link to="/reports-reviews/new-ih-performance">
        <Button
          className={classes.outlinedButton}
          variant="outlined"
          color="primary"
          disableElevation
        >
          Create a new report
        </Button>
      </Link>
      <Paper
        elevation={3}
        className={classes.hubspotErrorForm}
        id="hubspotErrorForm"
      />
    </div>
  );
};

PresentationError.propTypes = {
  errorMessage: PropTypes.string,
  classes: PropTypes.shape()
};

PresentationError.defaultProps = {
  errorMessage: "An unknown error occured",
  classes: {}
};

export default PresentationError;
