import React from "react";
import PropTypes from "prop-types";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList,
  Legend,
  ReferenceLine,
  Cell
} from "recharts";

const StackedBar = props => {
  const { data, display, chartData, colors, formatNumber } = props;
  const legend = [];
  if (data.y.length === 1) {
    chartData.map((e, i) => {
      const item = {
        value: e.x,
        type: "square",
        id: i,
        color: colors[i % colors.length]
      };
      return legend.push(item);
    });
  }
  return (
    <ResponsiveContainer>
      <BarChart
        data={chartData}
        layout="vertical"
        margin={{ top: 40, bottom: 40, left: 40, right: 40 }}
        stackOffset="sign"
      >
        <XAxis
          type="number"
          axisLine={display.value_axis.visible}
          hide={!display.value_axis.visible}
        />
        <YAxis
          dataKey="x"
          type="category"
          fontSize={12}
          reversed
          axisLine={false}
          tickLine={false}
          width={Math.max(...chartData.map(i => i.x.length)) > 12 ? 150 : 50}
        />
        <ReferenceLine ifOverflow="extendDomain" x={0} stroke="#333" />
        {display.has_legend ? (
          <Legend
            verticalAlign={
              display.legend.position === "bottom" ? "bottom" : "top"
            }
            wrapperStyle={{
              fontSize: display.legend.font ? display.legend.font.size : "auto"
            }}
            payload={data.y.length > 1 ? null : legend}
          />
        ) : null}
        {data.y.map((i, key) => {
          let colour = colors[key % colors.length];
          if (display.color_map) {
            colour =
              display.color_map[key] === undefined
                ? colors[key % colors.length]
                : `#${display.color_map[key]}`;
          }
          return (
            <Bar
              key={key.toString()}
              dataKey={`${i[0]}`}
              stackId="a"
              fill={display.color_map ? colour : colors[key % colors.length]}
            >
              {display.data_labels ? (
                <LabelList
                  valueAccessor={(e, j) =>
                    display.data_labels.individual_map
                      ? display.data_labels.individual_map[key][j]
                      : i[1][0]
                  }
                  formatter={j =>
                    display.data_labels.individual_map
                      ? j
                      : formatNumber(j, display.data_labels.number_format)
                  }
                  style={{
                    fill: `#${display.data_labels.font.color}`,
                    fontSize: display.data_labels.font.size
                  }}
                />
              ) : null}
              {data.y.length === 1
                ? chartData.map((e, j) => (
                    <Cell key={j.toString()} fill={colors[j % colors.length]} />
                  ))
                : null}
            </Bar>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

StackedBar.propTypes = {
  data: PropTypes.shape(),
  display: PropTypes.shape(),
  chartData: PropTypes.arrayOf(PropTypes.shape()),
  colors: PropTypes.arrayOf(PropTypes.string),
  formatNumber: PropTypes.func
};

StackedBar.defaultProps = {
  data: {},
  display: {},
  chartData: [],
  colors: [],
  formatNumber: () => {}
};

export default StackedBar;
