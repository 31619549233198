import React, { useEffect } from "react";
import {
  Grid,
  Card,
  CardHeader,
  Typography,
  Link,
  makeStyles
} from "@material-ui/core";
import sales from "../images/sales.png";
import helpdesk from "../images/helpdesk.png";

const useStyles = makeStyles({
  container: {
    maxWidth: 1300,
    margin: "0 auto",
    marginBottom: 100
  },
  card: {
    width: "100%",
    padding: 20,
    color: "#3c3838"
  },
  heading: {
    color: "#000074",
    fontWeight: "bold",
    textAlign: "center"
  },
  text: {
    textAlign: "center",
    margin: "0 auto",
    padding: "0 120px"
  },
  formContainer: {
    padding: 50
  },
  image: {
    margin: "0 20px 20px 2px"
  },
  formTitle: {
    color: "#009cad",
    fontWeight: "bold",
    textTransform: "uppercase",
    marginTop: 75
  },
  formIntroText: {
    paddingBottom: 20,
    minHeight: 80
  },
  link: {
    color: "#009cad",
    fontWeight: "bold",
    textDecoration: "underline"
  },
  hubspotForm: {
    padding: 30,
    width: "100%",
    borderRadius: 4,
    boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.16)",
    marginBottom: 20
  },
  fieldsetLegend: {
    backgroundColor: "#fff",
    padding: "0 10px",
    fontWeight: "bold",
    fontSize: 18,
    color: "#272525",
    marginBottom: 20
  },
  footerText: {
    color: "#8f8b8b",
    padding: "0 50px",
    textAlign: "center"
  }
});

const Support = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.crossOrigin = "anonymous";
    script.src = "https://js.hsforms.net/forms/shell.js";
    document.getElementsByTagName("head")[0].appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "4913136",
          formId: "7af17acf-8df0-4ac8-aad2-88bb13a4118d",
          target: "#hubspotUpgradeForm"
        });
        window.hbspt.forms.create({
          portalId: "4913136",
          formId: "02856087-3fe0-4050-97ca-1217476ead25",
          target: "#hubspotSupportForm"
        });
      }
    });
  }, []);
  const classes = useStyles();
  return (
    <div className="Support">
      <Grid container spacing={3} className={classes.container}>
        <Card className={classes.card}>
          <CardHeader
            titleTypographyProps={{
              variant: "h4",
              className: classes.heading
            }}
            title="Support"
          />
          <Typography variant="body2" gutterBottom className={classes.text}>
            We aim to make Interrodata as intuitive to use as possible - but if
            you are experiencing any problems or just want to find out more,
            please don&apos;t hesitate to get in touch with our team!
          </Typography>
          <Grid item md={12} container>
            <Grid item md={6} className={classes.formContainer}>
              <Grid item md={12} container>
                <img
                  alt="sales"
                  src={sales}
                  justify="center"
                  className={classes.image}
                />
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.formTitle}
                >
                  Sales
                </Typography>
              </Grid>
              <Typography
                variant="body2"
                gutterBottom
                className={classes.formIntroText}
              >
                To get in touch with our sales team, please fill in the form
                below or email{" "}
                <Link
                  className={classes.link}
                  href="mailto:frida@interrodata.com"
                >
                  frida@interrodata.com
                </Link>{" "}
                and David or Dan will be in touch as soon as possible.
              </Typography>
              <fieldset className={classes.hubspotForm} id="hubspotUpgradeForm">
                <legend className={classes.fieldsetLegend}>
                  Contact our Sales Team
                </legend>
              </fieldset>
            </Grid>
            <Grid item md={6} className={classes.formContainer}>
              <Grid item md={12} container>
                <img
                  alt="helpdesk"
                  src={helpdesk}
                  justify="center"
                  className={classes.image}
                />
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.formTitle}
                >
                  Helpdesk
                </Typography>
              </Grid>
              <Typography
                variant="body2"
                gutterBottom
                className={classes.formIntroText}
              >
                For all support requests, please fill in the form below or email{" "}
                <Link
                  className={classes.link}
                  href="mailto:helpdesk@interrodata.com"
                >
                  helpdesk@interrodata.com
                </Link>{" "}
                letting us know as much information as possible about what you
                are trying to do and what problem you are experiencing.
              </Typography>
              <fieldset className={classes.hubspotForm} id="hubspotSupportForm">
                <legend className={classes.fieldsetLegend}>
                  Ask the Helpdesk
                </legend>
              </fieldset>
            </Grid>
          </Grid>
          <Typography
            variant="body2"
            gutterBottom
            className={classes.footerText}
          >
            Interrodata needs the contact information you provide to us to
            contact you about our products and services. You may unsubscribe
            from these communications at any time. For information on how to
            unsubscribe, as well as our privacy practices and commitment to
            protecting your privacy, please review our Privacy Policy.
          </Typography>
        </Card>
      </Grid>
    </div>
  );
};

export default Support;
