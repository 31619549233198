import React, { useState } from "react";
import PropTypes from "prop-types";
import { ListItem, Collapse, Button, Box } from "@material-ui/core";
import AdvancedSearch from "../SharedComponents/AdvancedSearch";

const WhatSection = props => {
  const {
    options,
    searchTerms,
    filterTerms,
    hint,
    story,
    classes,
    isFilteringData,
    client
  } = props;
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  return (
    <ListItem>
      <fieldset className={classes.fieldset}>
        <legend className={classes.fieldsetLegend}>What</legend>
        <Collapse in={!isAdvancedSearch}>
          <Box textAlign="center">
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setIsAdvancedSearch(true);
              }}
            >
              Advanced search
            </Button>
          </Box>
        </Collapse>
        <Collapse in={isAdvancedSearch}>
          <Box textAlign="center">
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setIsAdvancedSearch(false);
              }}
            >
              Close advanced search
            </Button>
          </Box>
          <AdvancedSearch
            searchTerms={searchTerms}
            options={options}
            filterTerms={filterTerms}
            story={story}
            hint={hint}
            classes={classes}
            isFilteringData={isFilteringData}
            client={client}
          />
          <Box textAlign="center">
            <Button
              size="small"
              style={{ marginTop: "10px" }}
              variant="outlined"
              onClick={() => {
                setIsAdvancedSearch(false);
              }}
            >
              Close advanced search
            </Button>
          </Box>
        </Collapse>
      </fieldset>
    </ListItem>
  );
};

WhatSection.propTypes = {
  searchTerms: PropTypes.arrayOf(PropTypes.shape()),
  options: PropTypes.arrayOf(PropTypes.shape()),
  filterTerms: PropTypes.arrayOf(PropTypes.shape()),
  hint: PropTypes.string,
  story: PropTypes.string,
  classes: PropTypes.shape(),
  isFilteringData: PropTypes.bool,
  client: PropTypes.string
};

WhatSection.defaultProps = {
  searchTerms: [],
  options: [],
  filterTerms: [],
  hint: "",
  story: "",
  classes: {},
  isFilteringData: false,
  client: ""
};

export default WhatSection;
