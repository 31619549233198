import { STATIC_DATA_URL, DATA_SETS, API_KEY } from "../constants";
import {
  IS_FETCHING_STATIC_DATA,
  DONE_FETCHING_STATIC_DATA,
  FAILED_FETCHING_STATIC_DATA,
  CLEAR_STATIC_DATA_CACHE
} from "../constants/actionConstants";

/**
 * Action for when the autocomplete data is being fetched from the API
 * @return {Object} Object to be used by the dispatch function
 */
const setIsFetchingStaticData = () => ({
  type: IS_FETCHING_STATIC_DATA
});

/**
 * Action for when the static data fetch failed.
 * @param  {String} [error=""] The error message to show
 * @return {Object}            Object to be used by the dispatch function
 */
const fetchStaticDataFailed = (error = "") => ({
  type: FAILED_FETCHING_STATIC_DATA,
  message: error
});

/**
 * Action for when the static data was fetched successfully.
 * @param  {Object} data the data received from the API server
 * @return {Object}      Object to be used by the dispatch function
 */
const fetchStaticDataSucceeded = data => ({
  type: DONE_FETCHING_STATIC_DATA,
  ...data
});

/**
 * Action for fetching static data from the API server
 * @return {Promise} Promise that is used by the thunk middleware
 */
export const fetchStaticData = client => async dispatch => {
  dispatch(setIsFetchingStaticData());
  try {
    const allSubsections = {};
    const placeholders = {};
    let dates = {};
    await Promise.all(
      DATA_SETS.map(async e => {
        const res = await fetch(
          `${STATIC_DATA_URL}?data_set=${e}&client=${client}`,
          {
            headers: { "X-API-KEY": API_KEY }
          }
        );
        if (!res.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await res.json();
        if (String(data).startsWith("ERROR")) {
          throw new Error("ERROR: static data cache corrupted");
        }
        const { subsections, date, placeholder } = data;
        allSubsections[e] = subsections;
        placeholders[e] = placeholder;
        dates = { ...dates, ...date };
      })
    );
    dispatch(
      fetchStaticDataSucceeded({
        subsections: allSubsections,
        date: dates,
        placeholder: placeholders
      })
    );
  } catch (err) {
    dispatch(fetchStaticDataFailed(err.message));
  }
};

/**
 * Action for when the static data cache is cleared so it can be refreshed.
 * @return {Object} Object to be used by the dispatch function
 */
export const clearStaticDataCache = () => ({
  type: CLEAR_STATIC_DATA_CACHE
});
