const getServerURL = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      switch (process.env.REACT_APP_DEV_ENV) {
        case "local":
          return {
            backend: "http://localhost:9090",
            user: "http://localhost:7070",
            staticData: ""
          };
        case "dev":
          return {
            backend:
              "https://9ks97eqm3j.execute-api.eu-west-2.amazonaws.com/dev",
            user: "https://wqn3hrrpsc.execute-api.eu-west-2.amazonaws.com/dev",
            staticData:
              "https://uxhremzpp6.execute-api.eu-west-2.amazonaws.com/dev"
          };
        case "qa":
          return {
            backend:
              "https://afni9qu3ba.execute-api.eu-west-2.amazonaws.com/qa",
            user: "https://jbbwutlv1h.execute-api.eu-west-2.amazonaws.com/qa",
            staticData:
              "https://abjm4cit0a.execute-api.eu-west-2.amazonaws.com/qa"
          };
        case "stg":
          return {
            backend:
              "https://8k9ekgnf8f.execute-api.eu-west-2.amazonaws.com/stg",
            user: "https://xv2jct1qoh.execute-api.eu-west-2.amazonaws.com/stg",
            staticData:
              "https://wkcsnbsbl4.execute-api.eu-west-2.amazonaws.com/stg"
          };
        case "prod":
          return {
            backend:
              "https://hptcaf5pkk.execute-api.eu-west-2.amazonaws.com/prod",
            user: "https://tmy6i3f8z1.execute-api.eu-west-2.amazonaws.com/prod",
            staticData:
              "https://nwyyc6ypml.execute-api.eu-west-2.amazonaws.com/prod"
          };
        default:
          return {
            backend:
              "https://9ks97eqm3j.execute-api.eu-west-2.amazonaws.com/dev",
            user: "https://wqn3hrrpsc.execute-api.eu-west-2.amazonaws.com/dev",
            staticData:
              "https://uxhremzpp6.execute-api.eu-west-2.amazonaws.com/dev"
          };
      }
    case "test":
      return {
        backend: "https://9ks97eqm3j.execute-api.eu-west-2.amazonaws.com/dev",
        user: "https://wqn3hrrpsc.execute-api.eu-west-2.amazonaws.com/dev",
        staticData: "https://uxhremzpp6.execute-api.eu-west-2.amazonaws.com/dev"
      };
    case "production":
      switch (process.env.REACT_APP_DEV_ENV) {
        case "dev":
          return {
            backend:
              "https://9ks97eqm3j.execute-api.eu-west-2.amazonaws.com/dev",
            user: "https://wqn3hrrpsc.execute-api.eu-west-2.amazonaws.com/dev",
            staticData:
              "https://uxhremzpp6.execute-api.eu-west-2.amazonaws.com/dev"
          };
        case "qa":
          return {
            backend:
              "https://afni9qu3ba.execute-api.eu-west-2.amazonaws.com/qa",
            user: "https://jbbwutlv1h.execute-api.eu-west-2.amazonaws.com/qa",
            staticData:
              "https://abjm4cit0a.execute-api.eu-west-2.amazonaws.com/qa"
          };
        case "stg":
          return {
            backend:
              "https://8k9ekgnf8f.execute-api.eu-west-2.amazonaws.com/stg",
            user: "https://xv2jct1qoh.execute-api.eu-west-2.amazonaws.com/stg",
            staticData:
              "https://wkcsnbsbl4.execute-api.eu-west-2.amazonaws.com/stg"
          };
        case "prod":
          return {
            backend:
              "https://hptcaf5pkk.execute-api.eu-west-2.amazonaws.com/prod",
            user: "https://tmy6i3f8z1.execute-api.eu-west-2.amazonaws.com/prod",
            staticData:
              "https://nwyyc6ypml.execute-api.eu-west-2.amazonaws.com/prod"
          };
        default:
          return {
            backend:
              "https://9ks97eqm3j.execute-api.eu-west-2.amazonaws.com/dev",
            user: "https://wqn3hrrpsc.execute-api.eu-west-2.amazonaws.com/dev",
            staticData:
              "https://uxhremzpp6.execute-api.eu-west-2.amazonaws.com/dev"
          };
      }
    default:
      return {
        backend: "https://9ks97eqm3j.execute-api.eu-west-2.amazonaws.com/dev",
        user: "https://wqn3hrrpsc.execute-api.eu-west-2.amazonaws.com/dev",
        staticData: "https://uxhremzpp6.execute-api.eu-west-2.amazonaws.com/dev"
      };
  }
};

export const getDataSet = story => {
  switch (story.toLowerCase()) {
    case "frida":
      return "nielsen";
    default:
      return "nielsen";
  }
};

const config = {
  serverURL: {
    ...getServerURL()
  },
  app: {
    timeout: 28800, // 8 hrs in seconds
    firstPollTimer: 90000,
    generalPollTimer: 30000,
    totalPolls: 26,
    numberSuggestions: 30
  },
  groupings: ["product", "packaging", "package detail"],
  dataSets: ["nielsen"]
};

export default config;
