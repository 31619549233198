import {
  parse,
  format,
  startOfWeek,
  startOfWeekYear,
  endOfWeek,
  subWeeks,
  addWeeks,
  getISOWeeksInYear,
  getWeek,
  setDay,
  differenceInWeeks,
  compareDesc,
  parseISO
} from "date-fns";
import _ from "lodash";
import { enGB } from "date-fns/locale";

/**
 * Date options: allow 53 weeks, week starts Sunday
 */
const options = {
  weekStartsOn: 0,
  firstWeekContainsDate: 4,
  useAdditionalWeekYearTokens: true
};

/**
 * Custom locale for datepicker, allows for 53 weeks, week starts Sunday
 */
export const locale = { ...enGB, options };

/**
 * Format the datepicker week number
 * @param  {Object} date    Date
 */
export const formatWeekNumber = date => getWeek(date, options);

/**
 * Get the date of the start i.e. Sunday of a given week
 * @param  {Object} date    Date in format YYYYww - where ww is the week number (1-53)
 */
export const getWeekStart = date =>
  startOfWeek(parse(date, "YYYYww", new Date(), options), options);

/**
 * Get the date of the end i.e. Saturday of a given week
 * @param  {Object} date    Date in format YYYYww - where ww is the week number (1-53)
 */
export const getWeekEnd = date =>
  endOfWeek(parse(date, "YYYYww", new Date(), options), options);

/**
 * Get the comparison period dates from the initial period dates
 * @param  {Object} periodOption    Option for comparison period - yearAgo or Custom
 * @param  {Object} start           Date for the start of the initial period
 * @param  {Object} end             Date for the end of the initial period
 * @param  {Object} customStart     Date for the start of the custom compare period - optional
 * @param  {Object} customEnd       Date for the end of the custom compare period - optional
 * @return {Array}                  Array containing the comparison start date and comparison end date
 */
export const getCompareDates = (
  periodOption,
  start,
  end,
  customStart,
  customEnd
) => {
  let compareStart;
  let compareEnd;
  if (periodOption === "yearAgo") {
    compareStart = subWeeks(start, 52);
    compareEnd = subWeeks(end, 52);
  } else if (periodOption === "Custom") {
    compareStart = customStart;
    compareEnd = customEnd;
  }
  return [compareStart, compareEnd];
};

/**
 * Convert custom dates to correct format for the backend
 * @param  {Object}   start           Date for the start of the initial period
 * @param  {Object}   end             Date for the end of the initial period
 * @param  {Object}   compareStart    Date for the start of the compare period
 * @param  {Object}   compareEnd      Date for the end of the compare period
 * @return {String}                   Comma separated string of 4 dates chronologically in format YYYYWW
 */
export const getDatePeriod = (start, end, compareStart, compareEnd) =>
  `${format(compareStart, "YYYYww", options)},${format(
    compareEnd,
    "YYYYww",
    options
  )},${format(start, "YYYYww", options)},${format(end, "YYYYww", options)}`;

/**
 * Convert date periods to the exact dates
 * @param  {String} period        Name of period used
 * @param  {Object} dataDate      The last date in the data
 * @return {Array}                Array of the start and end date of initial and compare period
 */
export const getDateFromPeriod = (period, dataDate) => {
  const lastValidDate = dataDate
    ? parse(dataDate, "dd/MM/yy", new Date())
    : new Date();
  let start;
  let end;
  let compareStart;
  let compareEnd;
  switch (period) {
    case "Year to Date":
      start = startOfWeekYear(lastValidDate, options);
      end = lastValidDate;
      compareStart = subWeeks(start, 52);
      compareEnd = subWeeks(end, 52);
      return [compareStart, compareEnd, start, end];
    case "Latest 52 Weeks":
      start = setDay(subWeeks(lastValidDate, 52), 7);
      end = lastValidDate;
      compareStart = subWeeks(start, 52);
      compareEnd = subWeeks(end, 52);
      return [compareStart, compareEnd, start, end];
    case "Latest 24 Weeks":
      start = setDay(subWeeks(lastValidDate, 24), 7);
      end = lastValidDate;
      compareStart = subWeeks(start, 52);
      compareEnd = subWeeks(end, 52);
      return [compareStart, compareEnd, start, end];
    case "Latest 12 Weeks":
      start = setDay(subWeeks(lastValidDate, 12), 7);
      end = lastValidDate;
      compareStart = subWeeks(start, 52);
      compareEnd = subWeeks(end, 52);
      return [compareStart, compareEnd, start, end];
    case "Latest 4 Weeks":
      start = setDay(subWeeks(lastValidDate, 4), 7);
      end = lastValidDate;
      compareStart = subWeeks(start, 52);
      compareEnd = subWeeks(end, 52);
      return [compareStart, compareEnd, start, end];
    default:
      // year
      start = startOfWeekYear(new Date(period, 0, 4), options);
      end = endOfWeek(
        addWeeks(start, getISOWeeksInYear(new Date(period, 0, 4)) - 1),
        options
      );
      compareStart = startOfWeekYear(new Date(period - 1, 0, 4), options);
      compareEnd = subWeeks(end, getISOWeeksInYear(new Date(period, 0, 4)));
      return [compareStart, compareEnd, start, end];
  }
};

/**
 * Convert date periods to readable string for search input bar
 * @param  {String} dateString    Comma separated string of 4 dates chronologically in format YYYYWW
 * @return {String}               Human readable date period
 */
export const getDateLabel = dateString => {
  const dates = dateString
    .split(",")
    .map((i, k) => (k % 2 === 0 ? getWeekStart(i) : getWeekEnd(i)));
  return `Custom period: (${format(dates[2], "dd/MM/yyyy")} - ${format(
    dates[3],
    "dd/MM/yyyy"
  )} Vs ${format(dates[0], "dd/MM/yyyy")} - ${format(dates[1], "dd/MM/yyyy")})`;
};

/**
 * Custom sort function to sort reports by date
 */
export const sortByDate = (a, b) =>
  compareDesc(parseISO(a.date), parseISO(b.date));

/**
 * Create a query string from a list of search terms
 * @param  {Array} searchTerms    Array of search terms, each of which is an object
 * @param  {String} story         Story type - e.g. IDA, PRR
 * @return {String}               URI encoded query string for the report URL
 */
export const buildQueryString = searchTerms =>
  searchTerms
    .filter(
      term =>
        term &&
        term.subsection &&
        term.subsection.length > 0 &&
        term.subsection !== "none"
    ) // remove filler terms (e.g. "in")
    .sort((a, b) => {
      if (a.subsection < b.subsection) return -1;
      if (a.subsection > b.subsection) return 1;
      return 0;
    }) // order so query always the same for a set group of constraints
    .map(
      (term, i) =>
        `${
          (term.table === "context" &&
            `context${i}=${encodeURIComponent(term.subsection)}`) ||
          encodeURIComponent(term.subsection)
        }=${encodeURIComponent(
          term.subsection === "period" && term.name.period
            ? term.name.period
            : term.name
        )}`
    )
    .join("&");

/**
 * Helper function for getTitleFromQueryString to produce a readable period option
 */
const getDateLabeFromPeriod = dateString => {
  const dates = dateString
    .split(",")
    .map((i, k) => (k % 2 === 0 ? getWeekStart(i) : getWeekEnd(i)));
  const diff = differenceInWeeks(dates[3], dates[2]) + 1;
  return `${diff} w/e ${format(dates[3], "dd/MM/yyyy")} vs ${diff} w/e ${format(
    dates[1],
    "dd/MM/yyyy"
  )}`;
};

/**
 * Create a readable title from the query string
 * @param  {String} query   Query string for the report
 * @return {String}         Human readable title for the report
 */
export const getTitleFromQueryString = query => {
  const urlParams = new URLSearchParams(query);
  const params = Object.fromEntries(urlParams);
  const whatParams = _.omit(
    _.omitBy(params, (i, j) => j.startsWith("context")),
    ["metric_name", "retailer", "period", "story"]
  );
  const whatString = Object.values(whatParams).join(" ");
  const contextString = _.values(
    _.pickBy(params, (i, j) => j.startsWith("context"))
  )
    .map(i => i.split("=")[1])
    .join(" ");
  return `Analysis of ${whatString} ${
    params.metric_name
  } performance within ${contextString} in ${params.retailer} in ${
    params.period.split(",").length === 4
      ? getDateLabeFromPeriod(params.period)
      : params.period
  }`;
};

/**
 * Create a short summary from the query string
 * @param  {String} query   Query string for the report
 * @return {String}         Human readable summary for the report
 */
export const getShortTitleFromQueryString = query => {
  const urlParams = new URLSearchParams(query);
  const params = Object.fromEntries(urlParams);
  const whatParams = _.omit(
    _.omitBy(params, (i, j) => i === "all" || j.startsWith("context")),
    ["metric_name", "retailer", "period", "story"]
  );
  const whatString = Object.values(whatParams).join(" ");
  return `${whatString} in ${params.retailer}`;
};

/**
 * Custom search function for react-select dropdown
 * from https://github.com/JedWatson/react-select/issues/3067#issue-363771398
 * @param  {Object} option      Current option object
 * @param  {String} rawInput    User search input
 * @return {Boolean}            Should option be included based on input
 */
export const customFilter = (option, rawInput) => {
  const words = rawInput.split(" ");
  return words.reduce(
    (acc, cur) => acc && option.label.toLowerCase().includes(cur.toLowerCase()),
    true
  );
};
