import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Grid,
  Card,
  CardHeader,
  IconButton,
  FormControl,
  TextField,
  Button,
  FormHelperText
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { Loader } from "../SharedComponents";
import { changePassword } from "../../actions/user";

const ChangePassword = props => {
  const {
    userId,
    error,
    message,
    submitChangePasswordForm,
    isLoading,
    onClose,
    classes
  } = props;
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const reset = () => {
    setPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  };

  return (
    <div className="ChangePassword">
      <Card className={classes.card}>
        <CardHeader
          titleTypographyProps={{
            variant: "h6",
            className: classes.heading
          }}
          avatar={
            <IconButton
              onClick={() => {
                onClose();
                reset();
              }}
            >
              <ArrowBack className={classes.icon} />
            </IconButton>
          }
          title="Change Password"
        />
        <Grid item md={6}>
          <form className={classes.form}>
            <FormControl>
              <TextField
                className={classes.formInput}
                placeholder="Current password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                variant="outlined"
              />
            </FormControl>
            <br />
            <FormControl>
              <TextField
                className={classes.formInput}
                placeholder="New password"
                type="password"
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                variant="outlined"
              />
            </FormControl>
            <br />
            <FormControl>
              <TextField
                className={classes.formInput}
                placeholder="Confirm new password"
                type="password"
                value={confirmNewPassword}
                onChange={e => setConfirmNewPassword(e.target.value)}
                variant="outlined"
              />
            </FormControl>
            <br />
            <div className={classes.buttons}>
              <Button
                className={classes.submitButton}
                variant="contained"
                type="sumbit"
                disableElevation
                onClick={e => {
                  e.preventDefault();
                  submitChangePasswordForm(
                    userId,
                    password,
                    newPassword,
                    confirmNewPassword
                  );
                  // if success
                  if (message.length > 0) {
                    reset();
                  }
                }}
              >
                Change password
              </Button>
              <Button
                className={classes.cancelButton}
                type="cancel"
                disableElevation
                onClick={e => {
                  e.preventDefault();
                  onClose();
                  reset();
                }}
              >
                Close
              </Button>
            </div>
            {error.length > 0 ? (
              <FormHelperText className={classes.errors}>
                {error.startsWith("ERROR:") ? error.substring(12) : error}
              </FormHelperText>
            ) : null}
            {message.length > 0 ? (
              <FormHelperText className={classes.success}>
                {message}
              </FormHelperText>
            ) : null}
          </form>
          {isLoading ? <Loader /> : null}
        </Grid>
      </Card>
    </div>
  );
};

ChangePassword.propTypes = {
  submitChangePasswordForm: PropTypes.func,
  error: PropTypes.string,
  message: PropTypes.string,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  classes: PropTypes.shape(),
  userId: PropTypes.string
};

ChangePassword.defaultProps = {
  submitChangePasswordForm: () => {},
  error: "",
  message: "",
  isLoading: false,
  onClose: () => {},
  classes: {},
  userId: ""
};

const mapDispatchToProps = dispatch => ({
  submitChangePasswordForm: (
    userId,
    password,
    newPassword,
    confirmNewPassword
  ) => {
    dispatch(changePassword(userId, password, newPassword, confirmNewPassword));
  }
});

export default connect(null, mapDispatchToProps)(ChangePassword);
