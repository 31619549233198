import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IconButton } from "@material-ui/core";
import { goToSlide } from "../../actions";
import { NextIcon } from "../Icons";

const PresentationNavRight = props => {
  const { activeSlideIndex, toSlide, report, classes } = props;
  const slideCount = report.length;
  const isLastSlide = activeSlideIndex + 1 >= slideCount;
  return (
    <div className="PresentationNavRight">
      <IconButton
        id="presentation-next"
        className={classes.navButtonIcon}
        onClick={() =>
          !isLastSlide && toSlide(activeSlideIndex + 1, report, slideCount)
        }
        disabled={
          isLastSlide ||
          (activeSlideIndex + 2 === slideCount &&
            report[activeSlideIndex + 1].hidden)
        }
      >
        <NextIcon />
      </IconButton>
      <p>Next Page</p>
    </div>
  );
};

PresentationNavRight.propTypes = {
  activeSlideIndex: PropTypes.number,
  toSlide: PropTypes.func,
  report: PropTypes.arrayOf(PropTypes.shape()),
  classes: PropTypes.shape()
};

PresentationNavRight.defaultProps = {
  activeSlideIndex: 0,
  toSlide: () => {},
  report: [],
  classes: {}
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  toSlide: (slideIndex, report, slideCount) => {
    const { reportId, userId, story, client } = ownProps;
    dispatch(
      goToSlide(
        slideIndex,
        report,
        "next",
        slideCount,
        reportId,
        userId,
        story,
        client
      )
    );
  }
});

export { PresentationNavRight as PresentationNavRightComponent };
export default connect(null, mapDispatchToProps)(PresentationNavRight);
