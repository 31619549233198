import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  Grid,
  FormControl,
  Input,
  InputAdornment,
  IconButton,
  Button,
  FormHelperText,
  makeStyles,
  Collapse
} from "@material-ui/core";
import {
  PersonOutlined,
  LockOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
  PeopleOutlineOutlined
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import ParticleComponent from "./ParticleComponent";
import { signIn, getClients } from "../../actions/user";
import Helpdesk from "./Helpdesk";
import { Loader } from "../SharedComponents";
import logo from "../../images/logo.png";
import "./Login.css";

const useStyles = makeStyles({
  container: {
    textAlign: "center",
    padding: 50,
    position: "absolute",
    zIndex: 2
  },
  image: {
    marginBottom: 30,
    marginTop: 100
  },
  formInput: {
    width: 400,
    padding: 10,
    margin: "0px auto",
    marginBottom: 20,
    color: "#fff",
    fontSize: 14
  },
  button: {
    width: 150,
    color: "#fff",
    backgroundColor: "#009cad",
    margin: 10
  },
  errors: {
    color: "red",
    marginTop: 30,
    fontSize: 14
  },
  whiteIcon: {
    color: "#fff"
  }
});

const Login = props => {
  const {
    isUser,
    error,
    submitSignInForm,
    isLoading,
    location,
    clients,
    getClientOptions
  } = props;
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [client, setClient] = useState("");
  useEffect(() => getClientOptions(), []);
  const { state } = location;
  const { from } = state || { from: { pathname: "/" } };

  if (isUser) {
    return <Redirect to={from} />;
  }
  return (
    <div className="Login">
      <Grid container className={classes.container} justifyContent="center">
        <Grid item md={12}>
          <img className={classes.image} alt="Interrodata" src={logo} />
        </Grid>
        <Grid item md={12}>
          <form
            onSubmit={e => {
              e.preventDefault();
              submitSignInForm(email, password, client);
            }}
          >
            <FormControl>
              <Input
                className={classes.formInput}
                placeholder="Email"
                type="email"
                name="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <PersonOutlined className={classes.whiteIcon} />
                  </InputAdornment>
                }
              />
            </FormControl>
            <br />
            <FormControl>
              <Input
                className={classes.formInput}
                placeholder="Password"
                type={isPasswordVisible ? "text" : "password"}
                name="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <LockOutlined className={classes.whiteIcon} />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      onMouseDown={e => e.preventDefault()}
                    >
                      {isPasswordVisible ? (
                        <VisibilityOutlined className={classes.whiteIcon} />
                      ) : (
                        <VisibilityOffOutlined className={classes.whiteIcon} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <br />
            <Collapse in={email.includes("@interrodata.com")}>
              <FormControl>
                <Autocomplete
                  id="client-select" // for load-runner
                  onChange={(e, newValue) => setClient(newValue)}
                  options={clients}
                  renderInput={params => (
                    <Input
                      ref={params.InputProps.ref}
                      inputProps={params.inputProps}
                      className={classes.formInput}
                      placeholder="Client"
                      name="client"
                      value={client}
                      startAdornment={
                        <InputAdornment position="start">
                          <PeopleOutlineOutlined
                            className={classes.whiteIcon}
                          />
                        </InputAdornment>
                      }
                    />
                  )}
                  disablePortal
                />
              </FormControl>
              <br />
            </Collapse>
            <Button
              className={classes.button}
              variant="contained"
              type="sumbit"
              disableElevation
            >
              Submit
            </Button>
          </form>
        </Grid>
        <Grid container spacing={3} justifyContent="center">
          {error.length > 0 ? (
            <FormHelperText className={classes.errors}>
              {error.startsWith("ERROR:") ? error.substring(12) : error}
            </FormHelperText>
          ) : null}
        </Grid>
        <br />
        {isLoading ? <Loader /> : null}
        <Helpdesk classes={classes.helpdesk} />
      </Grid>
      <ParticleComponent />
    </div>
  );
};

Login.propTypes = {
  submitSignInForm: PropTypes.func,
  isUser: PropTypes.bool,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  location: PropTypes.shape(),
  getClientOptions: PropTypes.func,
  clients: PropTypes.arrayOf(PropTypes.string)
};

Login.defaultProps = {
  submitSignInForm: () => {},
  isUser: false,
  error: "",
  isLoading: false,
  location: {},
  getClientOptions: () => {},
  clients: []
};

const mapStateToProps = state => ({
  isUser: state.user.isUser,
  error: state.user.error,
  isLoading: state.user.isLoading,
  clients: state.user.clients
});

const mapDispatchToProps = dispatch => ({
  submitSignInForm: (email, password, client) => {
    dispatch(signIn(email, password, client));
  },
  getClientOptions: () => dispatch(getClients())
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
