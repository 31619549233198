import React from "react";
import PropTypes from "prop-types";

const ReportTable = props => {
  const { data, display, layout } = props;
  const tableData = JSON.parse(data);
  const colour = Object.assign(
    display.gradient
      ? display.gradient.map(d => ({ x: d[0][0], y: d[0][1], colour: d[1] }))
      : []
  );
  const highlight = Object.assign(
    display.tp_highlight
      ? (display.tp_highlight[0].length > 1 &&
          display.tp_highlight.map(d => ({ x: d[0], y: d[1] }))) || [
          { x: display.tp_highlight[0], y: display.tp_highlight[1] }
        ]
      : []
  );
  return (
    <div className="Table ReportTable">
      <table
        style={{
          textAlign: `${display.master.align}`,
          backgroundColor: `${
            display.master.background_color === "FFFFFF"
              ? "transparent"
              : `#${display.master.background_color}`
          }`,
          color: `#${display.master.font ? display.master.font.color : "000"}`,
          fontFamily: `${
            display.master.font ? display.master.font.name : "inherit"
          }`,
          fontSize: `${
            display.master.font ? Number(display.master.font.size) / 16 : 1
          }em`,
          position: "absolute",
          top: `${layout.top}%`,
          left: `${layout.left}%`,
          height: `${layout.height}%`,
          width: `${layout.width}%`,
          maxWidth: `${layout.width}%`,
          tableLayout: "fixed"
        }}
      >
        <thead
          style={{
            fontWeight: `${
              display.columns.font && display.columns.font.bold
                ? "bold"
                : "normal"
            }`,
            color: `#${
              display.columns.font ? display.columns.font.color : "000"
            }`,
            backgroundColor: `#${display.columns.background_color}`
          }}
        >
          <tr>
            <th aria-label="empty cell" />
            {tableData.columns.map((i, key) => (
              <th
                style={{
                  fontWeight: `${
                    highlight.filter(o => o.x === 0 && o.y === key + 1)[0] !==
                    undefined
                      ? "bold"
                      : null
                  }`,
                  verticalAlign: "middle"
                }}
                key={key.toString()}
              >
                {i}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.data.map((i, x) => (
            <tr key={x.toString()}>
              <th
                style={{
                  backgroundColor: `${
                    display.index
                      ? `#${display.index.background_color}`
                      : "transparent"
                  }`,
                  fontWeight: `${
                    highlight.filter(o => o.x === x + 1 && o.y === 0)[0] !==
                    undefined
                      ? "bold"
                      : null
                  }`,
                  borderTop: "1px solid #fff",
                  verticalAlign: "middle"
                }}
              >
                {tableData.index[x]}
              </th>
              {i.map((j, y) => (
                <td
                  style={{
                    color: `#${
                      colour.filter(o => o.x === x + 1 && o.y === y + 1)[0] ===
                      undefined
                        ? "000"
                        : colour.filter(o => o.x === x + 1 && o.y === y + 1)[0]
                            .colour
                    }`,
                    fontWeight: `${
                      highlight.filter(
                        o => o.x === x + 1 && o.y === y + 1
                      )[0] !== undefined
                        ? "bold"
                        : null
                    }`,
                    fontSize: 14,
                    borderTop: "1px solid #E1CDCC",
                    verticalAlign: "middle"
                  }}
                  key={y.toString()}
                >
                  {j}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

ReportTable.propTypes = {
  data: PropTypes.string,
  display: PropTypes.shape(),
  layout: PropTypes.shape()
};

ReportTable.defaultProps = {
  data: "",
  display: {},
  layout: {}
};

export default ReportTable;
