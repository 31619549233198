import React from "react";
import PropTypes from "prop-types";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList,
  Legend,
  CartesianGrid
} from "recharts";
import { scaleLinear } from "d3-scale";
import _ from "lodash";

const StackedColumn = props => {
  const { data, display, chartData, colors, formatNumber } = props;
  return (
    <ResponsiveContainer>
      <BarChart
        data={chartData}
        margin={{ top: 40, bottom: 40, left: 40, right: 40 }}
      >
        {display.value_axis.has_major_gridlines ? (
          <CartesianGrid vertical={false} />
        ) : null}
        {display.value_axis.maximum_scale ? (
          <YAxis
            type="number"
            axisLine={display.value_axis}
            hide={display.value_axis.visible === false}
            label={{
              value: `${
                display.value_axis.axis_title
                  ? display.value_axis.axis_title.text_frame.text
                  : ""
              }`,
              angle: -90,
              style: {
                fill: "#7a7a7a"
              },
              position: "insideBottomLeft"
            }}
            fontSize={
              display.value_axis.tick_labels
                ? display.value_axis.tick_labels.font.size
                : "auto"
            }
            domain={[
              display.value_axis.minimum_scale,
              display.value_axis.maximum_scale
            ]}
            allowDataOverflow
            ticks={scaleLinear()
              .domain([
                display.value_axis.minimum_scale,
                display.value_axis.maximum_scale
              ])
              .ticks(6)}
            tickFormatter={tick =>
              display.value_axis.tick_labels
                ? formatNumber(
                    tick,
                    display.value_axis.tick_labels.number_format
                  )
                : tick
            }
          />
        ) : (
          <YAxis
            type="number"
            axisLine={display.value_axis}
            hide={display.value_axis.visible === false}
            label={{
              value: `${
                display.value_axis.axis_title
                  ? display.value_axis.axis_title.text_frame.text
                  : ""
              }`,
              angle: -90,
              style: {
                fill: "#7a7a7a"
              },
              position: "insideBottomLeft"
            }}
            fontSize={
              display.value_axis.tick_labels
                ? display.value_axis.tick_labels.font.size
                : "auto"
            }
            allowDataOverflow
            tickFormatter={tick =>
              display.value_axis.tick_labels
                ? formatNumber(
                    tick,
                    display.value_axis.tick_labels.number_format
                  )
                : tick
            }
          />
        )}
        <XAxis
          dataKey="x"
          type="category"
          axisLine={display.category_axis}
          hide={display.category_axis.visible === false}
          fontSize={
            display.category_axis.tick_labels
              ? display.category_axis.tick_labels.font.size
              : "auto"
          }
          interval={chartData.length > 24 ? 2 : 0}
          angle={
            chartData.length > 12 ||
            (chartData[0] && chartData[0].x.length > 10)
              ? -45
              : 0
          }
          textAnchor={
            chartData.length > 12 ||
            (chartData[0] && chartData[0].x.length > 10)
              ? "end"
              : "middle"
          }
          height={
            chartData.length > 12 ||
            (chartData[0] && chartData[0].x.length > 10)
              ? 60
              : 30
          }
          dy={chartData.length > 12 ? 15 : 0}
        />
        {display.has_legend ? (
          <Legend
            layout="vertical"
            verticalAlign="middle"
            align="right"
            wrapperStyle={{
              fontSize: display.legend.font ? display.legend.font.size : "auto"
            }}
            width={100}
          />
        ) : null}
        {data.y.map((i, key) => {
          let colour = colors[key % colors.length];
          if (display.apply) {
            colour =
              display.apply && _.isEqual(data.y[key][0], "white")
                ? "transparent"
                : data.y[key][0];
          } else if (display.color_map) {
            colour =
              display.color_map[key] === undefined
                ? colors[key % colors.length]
                : `#${display.color_map[key]}`;
          }
          return (
            <Bar
              key={key.toString()}
              dataKey={`${i[0]}`}
              stackId="a"
              fill={
                display.apply || display.color_map
                  ? colour
                  : colors[key % colors.length]
              }
            >
              {display.data_labels && display.data_labels.individual_map ? (
                <LabelList
                  style={{
                    fill: `#${display.data_labels.font.color}`,
                    fontSize: display.data_labels.font.size
                  }}
                  valueAccessor={(e, j) =>
                    display.data_labels.individual_map[key][j]
                  }
                />
              ) : null}
              {display.data_labels && !display.data_labels.individual_map ? (
                <LabelList
                  style={{
                    fill: `#${display.data_labels.font.color}`,
                    fontSize: display.data_labels.font.size
                  }}
                  dataKey={`${i[0]}`}
                  formatter={j =>
                    formatNumber(j, display.data_labels.number_format)
                  }
                />
              ) : null}
            </Bar>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

StackedColumn.propTypes = {
  data: PropTypes.shape(),
  display: PropTypes.shape(),
  chartData: PropTypes.arrayOf(PropTypes.shape()),
  colors: PropTypes.arrayOf(PropTypes.string),
  formatNumber: PropTypes.func
};

StackedColumn.defaultProps = {
  data: {},
  display: {},
  chartData: [],
  colors: [],
  formatNumber: () => {}
};

export default StackedColumn;
