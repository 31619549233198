import defaultState from "./defaultState";
import {
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  UNBLOCK_USER_REQUEST,
  UNBLOCK_USER_SUCCESS,
  UNBLOCK_USER_ERROR,
  DISABLE_USER_REQUEST,
  DISABLE_USER_SUCCESS,
  DISABLE_USER_ERROR,
  ENABLE_USER_REQUEST,
  ENABLE_USER_SUCCESS,
  ENABLE_USER_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CLEAR_SETTINGS_MESSAGES,
  LOGOUT,
  // GET_DEPARTMENTS_REQUEST,
  GET_DEPARTMENTS_SUCCESS,
  // GET_DEPARTMENTS_ERROR,
  // ADD_DEPARTMENT_REQUEST,
  ADD_DEPARTMENT_SUCCESS,
  // ADD_DEPARTMENT_ERROR,
  // GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  // GET_CLIENTS_ERROR,
  ADD_CLIENT_REQUEST,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_ERROR
} from "../constants/actionConstants";

const user = (state = defaultState.user, action) => {
  switch (action.type) {
    case CREATE_USER_REQUEST:
      return {
        ...state,
        createUserError: "",
        createUserMessage: "",
        isLoading: true
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        createUserError: "",
        createUserMessage: "User created successfully",
        isLoading: false
      };
    case CREATE_USER_ERROR:
      return {
        ...state,
        createUserError: action.error,
        createUserMessage: "",
        isLoading: false
      };
    case SIGN_IN_REQUEST:
      return {
        ...state,
        user: action.user,
        isUser: false,
        error: "",
        message: "",
        isLoading: true
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        user: action.user,
        isUser: true,
        error: "",
        message: "",
        isLoading: false
      };
    case SIGN_IN_ERROR:
      return {
        ...state,
        error: action.error,
        message: "",
        user: {},
        isUser: false,
        isLoading: false
      };
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePasswordError: "",
        changePasswordMessage: "",
        isLoading: true
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordError: "",
        changePasswordMessage: "Password change successful",
        isLoading: false
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordError: action.error,
        changePasswordMessage: "",
        isLoading: false
      };
    case UNBLOCK_USER_REQUEST:
      return {
        ...state,
        unblockUserError: "",
        unblockUserMessage: "",
        isLoading: true
      };
    case UNBLOCK_USER_SUCCESS:
      return {
        ...state,
        unblockUserError: "",
        unblockUserMessage: "Unblocking user successful",
        isLoading: false
      };
    case UNBLOCK_USER_ERROR:
      return {
        ...state,
        unblockUserError: action.error,
        unblockUserMessage: "",
        isLoading: false
      };
    case DISABLE_USER_REQUEST:
      return {
        ...state,
        disableUserError: "",
        disableUserMessage: "",
        isLoading: true
      };
    case DISABLE_USER_SUCCESS:
      return {
        ...state,
        disableUserError: "",
        disableUserMessage: "User disabled successful",
        isLoading: false
      };
    case DISABLE_USER_ERROR:
      return {
        ...state,
        disableUserError: action.error,
        disableUserMessage: "",
        isLoading: false
      };
    case ENABLE_USER_REQUEST:
      return {
        ...state,
        enableUserError: "",
        enableUserMessage: "",
        isLoading: true
      };
    case ENABLE_USER_SUCCESS:
      return {
        ...state,
        enableUserError: "",
        enableUserMessage: "User enabled successful",
        isLoading: false
      };
    case ENABLE_USER_ERROR:
      return {
        ...state,
        enableUserError: action.error,
        enableUserMessage: "",
        isLoading: false
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPasswordError: "",
        resetPasswordMessage: "",
        isLoading: true
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordError: "",
        resetPasswordMessage: "Password reset successful",
        isLoading: false
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordError: action.error,
        resetPasswordMessage: "",
        isLoading: false
      };
    case CLEAR_SETTINGS_MESSAGES:
      return {
        ...state,
        changePasswordError: "",
        changePasswordMessage: "",
        createUserError: "",
        createUserMessage: "",
        unblockUserError: "",
        unblockUserMessage: "",
        disableUserError: "",
        disableUserMessage: "",
        enableUserError: "",
        enableUserMessage: "",
        resetPasswordError: "",
        resetPasswordMessage: "",
        addClientError: "",
        addClientMessage: ""
      };
    case LOGOUT:
      return {
        ...state,
        user: {},
        isUser: false,
        error: "",
        message: "",
        isLoading: false
      };
    case GET_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departments: action.departments
      };
    case ADD_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departments: [...state.departments, action.department]
      };
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.clients
      };
    case ADD_CLIENT_REQUEST:
      return {
        ...state,
        addClientError: "",
        addClientMessage: "",
        isLoading: true
      };
    case ADD_CLIENT_SUCCESS:
      return {
        ...state,
        addClientError: "",
        addClientMessage: "Client added successfully",
        isLoading: false
      };
    case ADD_CLIENT_ERROR:
      return {
        ...state,
        addClientError: action.error,
        addClientMessage: "",
        isLoading: false
      };
    default:
      return state;
  }
};

export default user;
