import React from "react";
import PropTypes from "prop-types";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

const PieGraph = props => {
  const { display, chartData, colors } = props;
  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          data={chartData}
          dataKey="value"
          labelLine={false}
          label={e => (
            <CustomizedLabel
              x={e.tooltipPosition.x}
              y={e.tooltipPosition.y}
              index={e.index}
              values={display.data_labels.individual_map[0]}
              font={display.data_labels.font}
            />
          )}
        >
          {chartData.map((e, i) => (
            <Cell key={i.toString()} fill={colors[i % colors.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

PieGraph.propTypes = {
  display: PropTypes.shape(),
  chartData: PropTypes.arrayOf(PropTypes.shape()),
  colors: PropTypes.arrayOf(PropTypes.string)
};

PieGraph.defaultProps = {
  display: {},
  chartData: [],
  colors: []
};

const CustomizedLabel = props => {
  const { x, y, index, values, font } = props;
  if (values[index]) {
    return (
      <text
        x={x}
        y={y}
        fill={`#${font.color}`}
        textAnchor="middle"
        fontSize={font.size}
      >
        {values[index]}
      </text>
    );
  }
  return null;
};

CustomizedLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  index: PropTypes.number,
  values: PropTypes.shape(),
  font: PropTypes.shape()
};

CustomizedLabel.defaultProps = {
  x: 0,
  y: 0,
  index: 0,
  values: {},
  font: {}
};

export default PieGraph;
