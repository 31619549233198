import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { format, parseISO, differenceInWeeks } from "date-fns";
import {
  Card,
  Grid,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  IconButton,
  Tooltip
} from "@material-ui/core";
import {
  StarBorderRounded,
  StarRounded,
  DateRangeOutlined
} from "@material-ui/icons";
import { DownloadPPTIcon } from "../Icons";
import { addToFavourites, removeFromFavourites } from "../../actions/report";
import { getWeekStart, getWeekEnd, getDateFromPeriod } from "../../utils";

const ReportCard = props => {
  const {
    report,
    classes,
    isFavourite,
    addFavourite,
    removeFavourite,
    userId,
    dataDate
  } = props;
  const isCustomDate = report.period.split(",").length === 4;
  let mainStart;
  let mainEnd;
  let compareStart;
  let compareEnd;
  let periodLength;
  if (isCustomDate) {
    [compareStart, compareEnd, mainStart, mainEnd] = report.period
      .split(",")
      .map((i, k) => (k % 2 === 0 ? getWeekStart(i) : getWeekEnd(i)));
    periodLength = differenceInWeeks(mainEnd, mainStart) + 1;
  } else {
    [compareStart, compareEnd, mainStart, mainEnd] = getDateFromPeriod(
      report.period,
      dataDate
    );
  }

  return (
    <Grid item md={4} key={`${report.reportId}`}>
      <a href={`/reports?${report.query}`} className="report-link">
        <Card className={classes.reportCard}>
          <CardHeader
            action={<div className={classes.storyType}>In Home</div>}
            subheader={
              <Fragment>
                <div className={classes.cardDate}>
                  {report.date
                    ? format(parseISO(report.date), "dd/MM/yy")
                    : format(new Date(), "dd/MM/yy")}
                  <IconButton
                    style={{ marginLeft: 3, marginTop: -3 }}
                    onClick={e => {
                      e.preventDefault();
                      if (isFavourite) {
                        removeFavourite(userId, report);
                      } else {
                        addFavourite(userId, report);
                      }
                    }}
                  >
                    {isFavourite ? (
                      <StarRounded className={classes.favourite} />
                    ) : (
                      <StarBorderRounded />
                    )}
                  </IconButton>
                </div>
                <div className={classes.cardProduct}>
                  {_.values(
                    _.omit(
                      _.omitBy(
                        report,
                        (i, j) => i === "all" || j.startsWith("context")
                      ),
                      [
                        "metric_name",
                        "retailer",
                        "period",
                        "story",
                        "query",
                        "date",
                        "reportId"
                      ]
                    )
                  ).join(" / ")}
                  <div>
                    <div className={classes.cardSubtitle}>Within</div>
                    &nbsp;
                    {_.values(
                      _.pickBy(report, (i, j) => j.startsWith("context"))
                    )
                      .map(i => i.split("=")[1])
                      .join(" / ")}
                  </div>
                </div>
              </Fragment>
            }
          />
          <CardContent className={classes.cardContent}>
            <div className={classes.cardSubsection}>
              <div className={classes.cardSubtitle}>Where</div>
              <br />
              <div className={classes.cardValue}>{report.retailer}</div>
            </div>
            <div className={`${classes.cardSubsection} ${classes.cardPeriod}`}>
              <div className={classes.periodHeader}>
                <DateRangeOutlined fontSize="small" /> &nbsp;&nbsp;
                {isCustomDate ? `${periodLength} weeks` : report.period}
              </div>
              <div className={classes.periodSubtitle}>Period:</div>
              &nbsp;&nbsp;
              <div className={`${classes.cardValue} ${classes.periodValue}`}>
                {format(mainStart, "dd/MM/yy")}&nbsp;-&nbsp;
                {format(mainEnd, "dd/MM/yy")}
              </div>
              <br />
              <div className={classes.periodSubtitle}>Comp:&nbsp;</div>
              &nbsp;&nbsp;
              <div className={`${classes.cardValue} ${classes.periodValue}`}>
                {format(compareStart, "dd/MM/yy")}&nbsp;-&nbsp;
                {format(compareEnd, "dd/MM/yy")}
              </div>
            </div>
            <Divider />
            <div className={classes.cardSubsection}>
              <div className={classes.cardSubtitle}>Metric</div>
              <br />
              <div className={classes.cardValue}>{report.metric_name}</div>
            </div>
          </CardContent>
          <Tooltip
            title="You can download reports to a fully editable PowerPoint document with our Professional and Professional+ packages"
            arrow
          >
            <CardActions
              onClick={e => {
                e.preventDefault();
              }}
              disableSpacing
              className={classes.cardDownload}
            >
              <DownloadPPTIcon /> &nbsp;&nbsp;Download Report
            </CardActions>
          </Tooltip>
        </Card>
      </a>
    </Grid>
  );
};

ReportCard.propTypes = {
  report: PropTypes.shape(),
  classes: PropTypes.shape(),
  isFavourite: PropTypes.bool,
  addFavourite: PropTypes.func,
  removeFavourite: PropTypes.func,
  userId: PropTypes.string,
  dataDate: PropTypes.string
};

ReportCard.defaultProps = {
  report: {},
  classes: {},
  isFavourite: false,
  addFavourite: () => {},
  removeFavourite: () => {},
  userId: "",
  dataDate: ""
};

const mapDispatchToProps = dispatch => ({
  addFavourite: (userId, report) => dispatch(addToFavourites(userId, report)),
  removeFavourite: (userId, report) =>
    dispatch(removeFromFavourites(userId, report))
});

export default connect(null, mapDispatchToProps)(ReportCard);
