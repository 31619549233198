import React from "react";
import PropTypes from "prop-types";
import {
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  LabelList,
  Cell,
  ReferenceLine
} from "recharts";
import { getNiceTickValues } from "recharts-scale";
import { scaleLinear } from "d3-scale";

const getDomain = (data, axis) => {
  const minValue = Math.min(
    ...data.map(i => (i[axis] < 0 ? i[axis] - i.z / 20 : i[axis])),
    0
  );
  const maxValue = Math.max(...data.map(i => i[axis] + i.z / 20), 0);
  const domain = [minValue, maxValue];
  const tickValues = getNiceTickValues(domain);
  return [Math.min(...tickValues), Math.max(...tickValues)];
};

const BubbleChart = props => {
  const { display, chartData } = props;
  return (
    <ResponsiveContainer>
      <ScatterChart margin={{ top: 40, bottom: 40, left: 40, right: 40 }}>
        <XAxis
          dataKey="x"
          type="number"
          domain={getDomain(chartData, "x")}
          ticks={scaleLinear().domain(getDomain(chartData, "x")).ticks()}
          label={{
            value: display.category_axis.axis_title.text_frame.text,
            position:
              Math.min(...chartData.map(i => i.y)) < 0 &&
              Math.max(...chartData.map(i => i.y)) < 0
                ? "top"
                : "bottom"
          }}
          orientation={
            Math.min(...chartData.map(i => i.y)) < 0 &&
            Math.max(...chartData.map(i => i.y)) < 0
              ? "top"
              : "bottom"
          }
        />
        <YAxis
          dataKey="y"
          type="number"
          domain={getDomain(chartData, "y")}
          ticks={scaleLinear().domain(getDomain(chartData, "y")).ticks()}
          label={{
            value: display.value_axis.axis_title.text_frame.text,
            angle:
              Math.min(...chartData.map(i => i.x)) < 0 &&
              Math.max(...chartData.map(i => i.x)) < 0
                ? 90
                : -90,
            position:
              Math.min(...chartData.map(i => i.x)) < 0 &&
              Math.max(...chartData.map(i => i.x)) < 0
                ? "right"
                : "left"
          }}
          orientation={
            Math.min(...chartData.map(i => i.x)) < 0 &&
            Math.max(...chartData.map(i => i.x)) < 0
              ? "right"
              : "left"
          }
        />
        <ReferenceLine ifOverflow="extendDomain" x={0} stroke="#333" />
        <ReferenceLine ifOverflow="extendDomain" y={0} stroke="#333" />
        <ZAxis
          dataKey="z"
          type="number"
          range={
            Math.max(...chartData.map(i => i.z)) > 100
              ? [
                  Math.min(...chartData.map(i => i.z)) * 50,
                  Math.max(...chartData.map(i => i.z)) * 50
                ]
              : [
                  Math.min(...chartData.map(i => i.z)) * 1000,
                  Math.max(...chartData.map(i => i.z)) * 1000
                ]
          }
        />
        <Scatter data={chartData}>
          <LabelList
            valueAccessor={(e, j) => display.data_labels.individual_map[0][j]}
            style={{
              fill: `#${display.data_labels.font.color}`,
              fontSize: display.data_labels.font.size
            }}
          />
          {chartData.map((e, i) => (
            <Cell
              key={i.toString}
              fill={`#${display.color_map[0][i]}${display.color_map.transparency}`}
            />
          ))}
        </Scatter>
      </ScatterChart>
    </ResponsiveContainer>
  );
};

BubbleChart.propTypes = {
  display: PropTypes.shape(),
  chartData: PropTypes.arrayOf(PropTypes.shape())
};

BubbleChart.defaultProps = {
  display: {},
  chartData: []
};

export default BubbleChart;
