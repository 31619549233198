import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { generate } from "shortid";
import _ from "lodash";
import {
  ListItem,
  Collapse,
  Button,
  Box,
  FormHelperText,
  Chip
} from "@material-ui/core";
import AdvancedSearch from "../SharedComponents/AdvancedSearch";
import SearchBar from "../SharedComponents/SearchBar";
import {
  enterSelectedTerm,
  deleteTerm,
  getContext
} from "../../actions/search";

const ProductContext = props => {
  const {
    options,
    contextTerms,
    whatTerms,
    story,
    classes,
    isLoadingContext,
    onChange,
    searchEngine,
    clearErrors,
    contextError,
    suggestedContext,
    enterSuggestedContext,
    client
  } = props;
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  return (
    <ListItem>
      <fieldset className={classes.fieldset}>
        <legend className={classes.fieldsetLegend}>Select Product Scope</legend>
        <div className={`${classes.subtitle} ${classes.searchSubtitle}`}>
          Enter the context that you would like to see for the product
        </div>
        <SearchBar
          searchOptions={searchEngine._docs || []}
          value={contextTerms}
          onChange={(e, val, reason) => {
            if (_.isEqual(reason, "select-option")) {
              const toAdd = val[val.length - 1];
              onChange(toAdd, reason, story, contextTerms, whatTerms, client);
            } else if (_.isEqual(reason, "remove-option")) {
              const toDelete = _.difference(contextTerms, val)[0];
              onChange(
                toDelete,
                reason,
                story,
                contextTerms,
                whatTerms,
                client
              );
            } else if (_.isEqual(reason, "clear")) {
              onChange(
                contextTerms,
                reason,
                story,
                contextTerms,
                whatTerms,
                client
              );
            }
            clearErrors();
          }}
          searchEngine={searchEngine}
          disabled={isLoadingContext}
          classes={classes}
          multiple
        />
        <FormHelperText className={classes.errors}>
          {contextError}
        </FormHelperText>
        <div className={classes.subtitle}>Suggested Context</div>
        <div className={classes.suggestedContext}>
          {suggestedContext.map(i => (
            <Chip
              key={generate()}
              className={classes.searchInputChip}
              label={i.name.value ? i.name.value : i.name}
              onClick={() =>
                enterSuggestedContext(i, story, contextTerms, whatTerms, client)
              }
            />
          ))}
        </div>
        <Collapse in={!isAdvancedSearch}>
          <div className={classes.subtitle}>
            Can&apos;t find what you&apos;re looking for?
            <br />
            <Button
              className={classes.advancedSearchButton}
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => setIsAdvancedSearch(true)}
            >
              Advanced Search
            </Button>
          </div>
        </Collapse>
        <Collapse in={isAdvancedSearch}>
          <AdvancedSearch
            searchTerms={contextTerms}
            options={options}
            filterTerms={contextTerms}
            story={story}
            classes={classes}
            isFilteringData={isLoadingContext}
            isContext
            whatTerms={whatTerms} // required for updating context
            clearErrors={clearErrors}
          />
          <Box textAlign="center">
            <Button
              size="small"
              style={{ marginTop: "10px" }}
              variant="outlined"
              onClick={() => {
                setIsAdvancedSearch(false);
              }}
            >
              Close advanced search
            </Button>
          </Box>
        </Collapse>
      </fieldset>
    </ListItem>
  );
};

const mapDispatchToProps = dispatch => ({
  onChange: (selected, e, story, contextTerms, whatTerms, client) => {
    if (_.isEqual(e, "select-option")) {
      const term = selected.name.value
        ? { ...selected, name: selected.name.value, story }
        : { ...selected, story };
      dispatch(enterSelectedTerm(term, story));
      const context = [...contextTerms, term];
      dispatch(getContext(whatTerms, context, "product", story, client));
    } else if (_.isEqual(e, "remove-option")) {
      dispatch(deleteTerm(selected, story));
      const context = contextTerms.filter(t => !_.isEqual(t, selected));
      dispatch(getContext(whatTerms, context, "product", story, client));
    } else if (_.isEqual(e, "clear")) {
      selected.forEach(i => {
        dispatch(deleteTerm(i, story));
      });
      dispatch(getContext(whatTerms, [], "product", story, client));
    }
  },
  enterSuggestedContext: (selected, story, contextTerms, whatTerms, client) => {
    const term = {
      name: selected.name.value ? selected.name.value : selected.name,
      subsection: selected.subsection,
      table: "context",
      story
    };
    dispatch(enterSelectedTerm(term, story));
    const context = [...contextTerms, term];
    dispatch(getContext(whatTerms, context, "product", story, client));
  }
});

ProductContext.propTypes = {
  contextTerms: PropTypes.arrayOf(PropTypes.shape()),
  options: PropTypes.arrayOf(PropTypes.shape()),
  whatTerms: PropTypes.arrayOf(PropTypes.shape()),
  story: PropTypes.string,
  classes: PropTypes.shape(),
  isLoadingContext: PropTypes.bool,
  onChange: PropTypes.func,
  searchEngine: PropTypes.shape(),
  clearErrors: PropTypes.func,
  contextError: PropTypes.string,
  suggestedContext: PropTypes.arrayOf(PropTypes.shape()),
  enterSuggestedContext: PropTypes.func,
  client: PropTypes.string
};

ProductContext.defaultProps = {
  contextTerms: [],
  options: [],
  whatTerms: [],
  story: "",
  classes: {},
  isLoadingContext: false,
  onChange: () => {},
  searchEngine: {},
  clearErrors: () => {},
  contextError: "",
  suggestedContext: [],
  enterSuggestedContext: () => {},
  client: ""
};

export default connect(null, mapDispatchToProps)(ProductContext);
