import React from "react";
import PropTypes from "prop-types";
import { Grid, Tooltip } from "@material-ui/core";

const DataUpdate = props => {
  const { date, classes } = props;
  const tooltip =
    "More data sources can be added within the full product package (e.g. brand tracking, shopper panel data, weather data)";
  return (
    <div className="DataUpdate">
      <Grid container spacing={3}>
        <Grid item md={12} className={classes.dataUpdateHeader}>
          Data available to w/e
        </Grid>
        <Grid container className={classes.dataUpdateBody}>
          <Grid item md={4}>
            <div>
              <p>{date.nielsen}</p>
              <p className={classes.dataSource}>Nielsen Scantrack</p>
            </div>
          </Grid>
          <Grid item md={4}>
            <Tooltip title={tooltip} arrow>
              <div>
                <p>-</p>
                <p className={classes.dataSource}>Source 2</p>
              </div>
            </Tooltip>
          </Grid>
          <Grid item md={4}>
            <Tooltip title={tooltip} arrow>
              <div>
                <p>-</p>
                <p className={classes.dataSource}>Source 3</p>
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

DataUpdate.propTypes = {
  date: PropTypes.shape(),
  classes: PropTypes.shape()
};

DataUpdate.defaultProps = {
  date: {},
  classes: {}
};

export default DataUpdate;
