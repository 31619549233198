import React from "react";
import PropTypes from "prop-types";

const ReportText = props => {
  const { data, display, layout } = props;
  return (
    <div className="Text ReportText">
      <div
        style={{
          textAlign: `${display.align}`,
          backgroundColor: `${
            display.background_color === "FFFFFF"
              ? "transparent"
              : `#${display.background_color}`
          }`,
          color: `#${display.font ? display.font.color : "000"}`,
          fontFamily: `${display.font ? display.font.name : "inherit"}`,
          fontSize: `${display.font ? Number(display.font.size) / 16 : 1}em`,
          whiteSpace: "pre-wrap",
          position: "absolute",
          top: `${layout.top}%`,
          left: `${layout.left}%`,
          height: `${layout.height}%`,
          width: `${layout.width}%`,
          padding: `${display.vertical_anchor ? 0 : "5px"}`,
          fontWeight: `${
            display.font && display.font.bold ? "bold" : "normal"
          }`,
          border: `${
            display.line ? `1px solid #${display.line.color}` : "none"
          }`,
          display: `${display.vertical_anchor ? "flex" : null}`,
          zIndex: 10
        }}
      >
        <p
          id={display.rotation > 0 ? `rotate-${display.rotation}` : null}
          style={{
            margin: `${
              display.vertical_anchor || display.rotation ? "auto" : 0
            }`,
            padding: "2px"
          }}
        >
          {display.link_address ? (
            <a href={display.link_address} target="_blank" rel="noreferrer">
              {data}
            </a>
          ) : (
            data
          )}
        </p>
      </div>
    </div>
  );
};

ReportText.propTypes = {
  data: PropTypes.string,
  display: PropTypes.shape(),
  layout: PropTypes.shape()
};

ReportText.defaultProps = {
  data: "",
  display: {},
  layout: {}
};

export default ReportText;
