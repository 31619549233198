const styles = {
  container: {
    width: "100%",
    margin: "0 auto",
    marginTop: -10,
    marginBottom: 20,
    textAlign: "center"
  },
  loadingImage: {
    marginTop: 50,
    width: "50%"
  },
  loadingText: {
    width: "80%",
    margin: "0 auto",
    padding: 20,
    "& a": {
      color: "#009cad"
    },
    color: "#3c3838"
  },
  presentation: {
    width: "100%",
    height: 0,
    paddingBottom: "56.25%", // 9:16 ratio
    marginTop: 20,
    marginBottom: 20,
    overflowX: "scroll",
    overflowY: "scroll",
    position: "relative"
  },
  navButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 150,
    fontSize: 10
  },
  navButtonIcon: {
    "&:hover": {
      color: "#009cad"
    }
  },
  headerRow: {
    height: 48
  },
  header: {
    height: 56
  },
  reportTitle: {
    padding: 10,
    color: "#000034",
    whiteSpace: "nowrap",
    overflowX: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left"
  },
  titleIcon: {
    margin: "5px 8px -5px 0"
  },
  titleTooltip: {
    maxWidth: 500
  },
  favouriteButton: {
    paddingLeft: 0,
    paddingRight: 0,
    width: "100%",
    color: "#ada8a8",
    "&:hover": {
      color: "#faa61a"
    }
  },
  favouriteIcon: {
    fontSize: 32
  },
  slideCount: {
    color: "#009cad",
    padding: "20px 0"
  },
  download: {
    color: "#ef4129",
    padding: 16,
    textTransform: "capitalize",
    fontSize: 14
  },
  errorContainer: {
    width: "80%",
    marginTop: 50,
    margin: "0 auto"
  },
  errorText: {
    padding: 10,
    color: "#3c3838"
  },
  oopsText: {
    color: "#ef4129",
    fontWeight: "bold"
  },
  errorImage: {
    margin: 5
  },
  outlinedButton: {
    color: "#009cad",
    margin: 20,
    border: "1px solid #009cad",
    "&:hover": {
      border: "1px solid #009cad"
    }
  },
  hubspotErrorForm: {
    marginTop: 50,
    padding: 50,
    marginBottom: 10
  }
};

export default styles;
