import AWS from "aws-sdk/global";
import DynamoDB from "aws-sdk/clients/dynamodb";
import {
  CREATE_USER_URL,
  SIGN_IN_URL,
  CHANGE_PASSWORD_URL,
  UNBLOCK_USER_URL,
  DISABLE_USER_URL,
  ENABLE_USER_URL,
  RESET_PASSWORD_URL,
  API_KEY,
  AWS_CONFIG,
  DEPARTMENTS_TABLE,
  CLIENTS_TABLE
} from "../constants";

import {
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  UNBLOCK_USER_REQUEST,
  UNBLOCK_USER_SUCCESS,
  UNBLOCK_USER_ERROR,
  DISABLE_USER_REQUEST,
  DISABLE_USER_SUCCESS,
  DISABLE_USER_ERROR,
  ENABLE_USER_REQUEST,
  ENABLE_USER_SUCCESS,
  ENABLE_USER_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CLEAR_SETTINGS_MESSAGES,
  LOGOUT,
  GET_DEPARTMENTS_REQUEST,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_ERROR,
  ADD_DEPARTMENT_REQUEST,
  ADD_DEPARTMENT_SUCCESS,
  ADD_DEPARTMENT_ERROR,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_ERROR,
  ADD_CLIENT_REQUEST,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_ERROR
} from "../constants/actionConstants";
import logger from "../logger";

/**
 * Ensure all user input emails are lowercase
 */
const lowerCaseEmail = email => email.toLowerCase();

function createUserRequest() {
  return {
    type: CREATE_USER_REQUEST
  };
}

function createUserSuccess() {
  return {
    type: CREATE_USER_SUCCESS
  };
}

function createUserError(error) {
  return {
    type: CREATE_USER_ERROR,
    error
  };
}

export const createUser =
  (
    name,
    inputEmail,
    password,
    confirmPassword,
    department,
    client,
    startDate
  ) =>
  dispatch => {
    const email = lowerCaseEmail(inputEmail);
    dispatch(createUserRequest());
    if (name.length === 0) {
      return dispatch(createUserError("Name is not valid"));
    }
    if (email.length === 0) {
      return dispatch(createUserError("Email is not valid"));
    }
    if (!email.match(/\S+@\S+\.\S+/)) {
      return dispatch(createUserError("Email is not valid"));
    }
    if (password.length < 8) {
      return dispatch(
        createUserError("Password must be at least 8 characters")
      );
    }
    if (!password.match(/(\S*\d\S*)/)) {
      return dispatch(createUserError("Password must contain a number"));
    }
    if (!password.match(/(\S*[A-Z]\S*)/)) {
      return dispatch(
        createUserError("Password must contain a capital letter")
      );
    }
    if (password !== confirmPassword) {
      return dispatch(createUserError("Passwords do not match"));
    }
    if (!email.includes("@interrodata.com")) {
      if (department.length === 0) {
        return dispatch(createUserError("Department not valid"));
      }
      if (startDate.length === 0) {
        return dispatch(createUserError("Start date not provided"));
      }
    }
    const body = email.includes("@interrodata.com")
      ? { name, email, password }
      : {
          name,
          email,
          password,
          department,
          client,
          product: "frida",
          start_date: startDate
        };
    return fetch(CREATE_USER_URL, {
      method: "post",
      body: JSON.stringify(body),
      headers: { "X-API-KEY": API_KEY }
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(
        res => {
          if (res === "SUCCESS") {
            dispatch(createUserSuccess());
          } else {
            dispatch(createUserError(res));
          }
        },
        err => {
          dispatch(createUserError(err.message));
        }
      );
  };

function signInRequest(user) {
  return {
    type: SIGN_IN_REQUEST,
    user
  };
}

function signInSuccess(user) {
  return {
    type: SIGN_IN_SUCCESS,
    user
  };
}

function signInError(error) {
  return {
    type: SIGN_IN_ERROR,
    error
  };
}

export const signIn = (inputEmail, password, pseudoClient) => dispatch => {
  const email = lowerCaseEmail(inputEmail);
  const user = { email };
  dispatch(signInRequest(user));
  if (email.length === 0 || password.length === 0) {
    return dispatch(signInError("Please enter a valid email and password"));
  }
  if (email.includes("@interrodata.com") && pseudoClient.length === 0) {
    return dispatch(signInError("Please select a valid client"));
  }
  return fetch(SIGN_IN_URL, {
    method: "post",
    body: JSON.stringify({
      email,
      password
    }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        if (res.status === "ACCEPTED") {
          const { admin, id, client, product, endDate } = res;
          if (admin) {
            // i.e. interrodata user
            dispatch(
              signInSuccess({ admin, id, client: pseudoClient, endDate })
            );
            logger.info({
              date: new Date().toISOString(),
              action: "SIGN_IN",
              user_id: id,
              client: "interrodata", // this is also what comes from user api
              product: "frida"
            });
          } else if (product !== "frida") {
            // not admin
            dispatch(signInError("Permission denied"));
          } else {
            // not admin
            dispatch(signInSuccess({ admin, id, client, endDate }));
            logger.info({
              date: new Date().toISOString(),
              action: "SIGN_IN",
              user_id: id,
              client,
              product: "frida"
            });
          }
        } else if (res.status === "DENIED") {
          dispatch(signInError("Access denied"));
        } else {
          dispatch(signInError(res));
        }
      },
      err => {
        if (err.message === "Failed to fetch") {
          dispatch(
            signInError(
              "Failed to fetch. Between 12AM and 5AM (GMT) and over the weekend the database has some down time."
            )
          );
        } else {
          dispatch(signInError(err.message));
        }
      }
    );
};

function changePasswordRequest() {
  return {
    type: CHANGE_PASSWORD_REQUEST
  };
}

function changePasswordSuccess() {
  return {
    type: CHANGE_PASSWORD_SUCCESS
  };
}

function changePasswordError(error) {
  return {
    type: CHANGE_PASSWORD_ERROR,
    error
  };
}

export const changePassword =
  (userId, password, newPassword, confirmNewPassword) => dispatch => {
    dispatch(changePasswordRequest());
    if (newPassword !== confirmNewPassword) {
      return dispatch(changePasswordError("Passwords do not match"));
    }
    if (newPassword.length < 8) {
      return dispatch(
        changePasswordError("Password must be at least 8 characters")
      );
    }
    return fetch(CHANGE_PASSWORD_URL, {
      method: "post",
      body: JSON.stringify({
        user_id: userId,
        password,
        new_password: newPassword
      }),
      headers: { "X-API-KEY": API_KEY }
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(
        res => {
          if (res === "SUCCESS") {
            dispatch(changePasswordSuccess());
          } else {
            dispatch(changePasswordError(res));
          }
        },
        err => {
          dispatch(changePasswordError(err.message));
        }
      );
  };

function unblockUserRequest() {
  return {
    type: UNBLOCK_USER_REQUEST
  };
}

function unblockUserSuccess() {
  return {
    type: UNBLOCK_USER_SUCCESS
  };
}

function unblockUserError(error) {
  return {
    type: UNBLOCK_USER_ERROR,
    error
  };
}

export const unblockUser = inputEmail => dispatch => {
  const email = lowerCaseEmail(inputEmail);
  dispatch(unblockUserRequest());
  if (email.length === 0) {
    return dispatch(unblockUserError("Email is not valid"));
  }
  return fetch(UNBLOCK_USER_URL, {
    method: "post",
    body: JSON.stringify({
      email
    }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          dispatch(unblockUserSuccess());
        } else {
          dispatch(unblockUserError(res));
        }
      },
      err => {
        dispatch(unblockUserError(err.message));
      }
    );
};

function disableUserRequest() {
  return {
    type: DISABLE_USER_REQUEST
  };
}

function disableUserSuccess() {
  return {
    type: DISABLE_USER_SUCCESS
  };
}

function disableUserError(error) {
  return {
    type: DISABLE_USER_ERROR,
    error
  };
}

export const disableUser = inputEmail => dispatch => {
  const email = lowerCaseEmail(inputEmail);
  dispatch(disableUserRequest());
  if (email.length === 0) {
    return dispatch(disableUserError("Email is not valid"));
  }
  return fetch(DISABLE_USER_URL, {
    method: "post",
    body: JSON.stringify({
      email
    }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          dispatch(disableUserSuccess());
        } else {
          dispatch(disableUserError(res));
        }
      },
      err => {
        dispatch(disableUserError(err.message));
      }
    );
};

function enableUserRequest() {
  return {
    type: ENABLE_USER_REQUEST
  };
}

function enableUserSuccess() {
  return {
    type: ENABLE_USER_SUCCESS
  };
}

function enableUserError(error) {
  return {
    type: ENABLE_USER_ERROR,
    error
  };
}

export const enableUser = inputEmail => dispatch => {
  const email = lowerCaseEmail(inputEmail);
  dispatch(enableUserRequest());
  if (email.length === 0) {
    return dispatch(enableUserError("Email is not valid"));
  }
  return fetch(ENABLE_USER_URL, {
    method: "post",
    body: JSON.stringify({
      email
    }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          dispatch(enableUserSuccess());
        } else {
          dispatch(enableUserError(res));
        }
      },
      err => {
        dispatch(enableUserError(err.message));
      }
    );
};

function resetPasswordRequest() {
  return {
    type: RESET_PASSWORD_REQUEST
  };
}

function resetPasswordSuccess() {
  return {
    type: RESET_PASSWORD_SUCCESS
  };
}

function resetPasswordError(error) {
  return {
    type: RESET_PASSWORD_ERROR,
    error
  };
}

export const resetPassword =
  (inputEmail, password, confirmPassword) => dispatch => {
    const email = lowerCaseEmail(inputEmail);
    dispatch(resetPasswordRequest());
    if (email.length === 0 || password.length === 0) {
      return dispatch(
        resetPasswordError("Please enter a valid email and password")
      );
    }
    if (password !== confirmPassword) {
      return dispatch(resetPasswordError("Passwords do not match"));
    }
    if (password.length < 8) {
      return dispatch(
        resetPasswordError("Password must be at least 8 characters")
      );
    }
    return fetch(RESET_PASSWORD_URL, {
      method: "post",
      body: JSON.stringify({
        email,
        password
      }),
      headers: { "X-API-KEY": API_KEY }
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(
        res => {
          if (res === "SUCCESS") {
            dispatch(resetPasswordSuccess());
          } else {
            dispatch(resetPasswordError(res));
          }
        },
        err => {
          dispatch(resetPasswordError(err.message));
        }
      );
  };

export function clearSettingsMessages() {
  return {
    type: CLEAR_SETTINGS_MESSAGES
  };
}

export function logout() {
  return {
    type: LOGOUT
  };
}

function getDepartmentsRequest() {
  return {
    type: GET_DEPARTMENTS_REQUEST
  };
}

function getDepartmentsSuccess(departments) {
  return {
    type: GET_DEPARTMENTS_SUCCESS,
    departments
  };
}

function getDepartmentsError(error) {
  return {
    type: GET_DEPARTMENTS_ERROR,
    error
  };
}

export const getDepartments = client => dispatch => {
  dispatch(getDepartmentsRequest());
  AWS.config.update(AWS_CONFIG);
  const dynamodb = new DynamoDB({ apiVersion: "2012-08-10" });

  const params = {
    TableName: `${DEPARTMENTS_TABLE}`,
    KeyConditionExpression: "client = :client", // this is the partition key
    ExpressionAttributeValues: {
      ":client": { S: client }
    }
  };
  dynamodb.query(params, (err, data) => {
    if (!err) {
      const { Items } = data;
      const departments = Items.map(d => d.department.S).sort();
      dispatch(getDepartmentsSuccess(departments));
    } else {
      dispatch(getDepartmentsError(err));
    }
  });
};

function addDepartmentRequest() {
  return {
    type: ADD_DEPARTMENT_REQUEST
  };
}

function addDepartmentSuccess(department) {
  return {
    type: ADD_DEPARTMENT_SUCCESS,
    department
  };
}

function addDepartmentError(error) {
  return {
    type: ADD_DEPARTMENT_ERROR,
    error
  };
}

export const addDepartment = (dept, client) => dispatch => {
  dispatch(addDepartmentRequest());
  AWS.config.update(AWS_CONFIG);
  const dynamodb = new DynamoDB({ apiVersion: "2012-08-10" });

  const params = {
    TableName: `${DEPARTMENTS_TABLE}`,
    Item: {
      client: {
        S: client
      },
      department: {
        S: dept
      }
    }
  };
  dynamodb.putItem(params, err => {
    if (!err) {
      dispatch(addDepartmentSuccess(dept));
    } else {
      dispatch(addDepartmentError(err));
    }
  });
};

function getClientsRequest() {
  return {
    type: GET_CLIENTS_REQUEST
  };
}

function getClientsSuccess(clients) {
  return {
    type: GET_CLIENTS_SUCCESS,
    clients
  };
}

function getClientsError(error) {
  return {
    type: GET_CLIENTS_ERROR,
    error
  };
}

export const getClients = () => dispatch => {
  dispatch(getClientsRequest());
  AWS.config.update(AWS_CONFIG);
  const dynamodb = new DynamoDB({ apiVersion: "2012-08-10" });

  const params = {
    TableName: `${CLIENTS_TABLE}`,
    KeyConditionExpression: "product = :product", // this is the partition key
    ExpressionAttributeValues: {
      ":product": { S: "frida" }
    }
  };
  dynamodb.query(params, (err, data) => {
    if (!err) {
      const { Items } = data;
      const clients = Items.map(i => i.client.S).sort();
      dispatch(getClientsSuccess(clients));
    } else {
      dispatch(getClientsError(err));
    }
  });
};

function addClientRequest() {
  return {
    type: ADD_CLIENT_REQUEST
  };
}

function addClientSuccess(client) {
  return {
    type: ADD_CLIENT_SUCCESS,
    client
  };
}

function addClientError(error) {
  return {
    type: ADD_CLIENT_ERROR,
    error
  };
}

export const addClient = client => dispatch => {
  dispatch(addClientRequest());
  if (client.length === 0) {
    return dispatch(addClientError("Please enter a valid client"));
  }
  AWS.config.update(AWS_CONFIG);
  const dynamodb = new DynamoDB({ apiVersion: "2012-08-10" });

  const params = {
    TableName: `${CLIENTS_TABLE}`,
    Item: {
      product: {
        S: "frida"
      },
      client: {
        S: client
      }
    }
  };
  return dynamodb.putItem(params, err => {
    if (!err) {
      dispatch(addClientSuccess(client));
    } else {
      dispatch(addClientError(err));
    }
  });
};
