import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Card,
  CardHeader,
  Typography,
  makeStyles
} from "@material-ui/core";
import { connect } from "react-redux";
import { getHours } from "date-fns";
import { PerformanceIcon, BrandBattlesIcon, RangeReviewIcon } from "../Icons";
import { getMyRecentReports } from "../../actions/report";
import DataUpdate from "./DataUpdate";
import ReportType from "./ReportType";

const useStyles = makeStyles({
  container: {
    maxWidth: 1300,
    margin: "0 auto",
    marginBottom: 100
  },
  card: {
    width: "100%",
    padding: 20
  },
  greeting: {
    float: "left"
  },
  heading: {
    color: "#000074",
    fontWeight: "bold"
  },
  introText: {
    paddingLeft: 16,
    color: "#009cad",
    fontWeight: "bold"
  },
  introSubText: {
    paddingLeft: 16
  },
  dataUpdateContainer: {
    float: "right",
    width: "100%",
    textAlign: "center",
    padding: "32px"
  },
  dataUpdateHeader: {
    backgroundColor: "#f7f3f2",
    color: "#272525",
    fontSize: 14,
    fontWeight: "bold",
    padding: 10,
    borderBottom: "1px solid white",
    borderRadius: "5px 5px 0 0"
  },
  dataUpdateBody: {
    fontSize: 12,
    padding: 10,
    backgroundColor: "#f7f3f2",
    color: "#736f6f",
    borderRadius: "0 0 5px 5px"
  },
  dataSource: {
    color: "#272525",
    fontWeight: 500
  },
  reportTypesContainer: {
    padding: 16
  },
  reportType: {
    padding: 20,
    textAlign: "center",
    height: 475
  },
  performance: {
    "&:hover": {
      backgroundColor: "#fff2dc",
      boxShadow: "0 0 10px 0 #faa61a"
    }
  },
  disabledBackground: {
    "&:hover": {
      backgroundColor: "#f7f3f2",
      boxShadow: "0 0 10px 0 #e5e0df"
    }
  },
  avatar: {
    height: 32,
    width: 32,
    padding: 24,
    backgroundColor: "#fff",
    margin: "0px auto",
    marginBottom: -24,
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.3)"
  },
  icon: {
    height: 24,
    width: 24
  },
  performanceIcon: {
    color: "#faa61a"
  },
  performanceButton: {
    backgroundColor: "#faa61a",
    "&:hover": {
      backgroundColor: "#faa61a"
    }
  },
  brandbattlesIcon: {
    color: "#ef4129"
  },
  rangereviewIcon: {
    color: "#009dbc"
  },
  reportTypeHeader: {
    marginTop: 10,
    color: "#000074 !important",
    fontSize: 18,
    fontWeight: "bold"
  },
  reportTypeIntro: {
    fontSize: 12,
    margin: "0px 15px 10px"
  },
  moreInfoLink: {
    color: "#009cad !important",
    textDecoration: "underline"
  },
  buttonContainer: {
    height: 125
  },
  newReportButton: {
    color: "#fff",
    margin: 5,
    fontSize: 12,
    maxWidth: 275
  },
  recentReportsContainer: {
    fontSize: 12,
    textAlign: "left"
  },
  recentReports: {
    margin: 10,
    color: "#272525"
  },
  viewRecent: {
    float: "right",
    textAlign: "right",
    color: "#272525 !important",
    textDecoration: "underline"
  },
  reportList: {
    height: 150,
    overflow: "auto !important"
  },
  reportTitle: {
    backgroundColor: "#f7f3f2",
    color: "#565151",
    padding: 5,
    margin: 2,
    borderRadius: 3
  },
  upgradeIcon: {
    fontSize: "16px !important",
    color: "#fff"
  }
});

const Home = props => {
  const { date, userId, getRecentReports, myRecentReports, endDate } = props;
  const classes = useStyles();
  useEffect(() => {
    if (myRecentReports.length < 1) {
      getRecentReports(userId);
    }
  }, []);
  const getGreeting = () => {
    const hour = getHours(new Date());
    if (hour >= 2 && hour < 12) {
      return "Good Morning";
    }
    if (hour >= 12 && hour < 18) {
      return "Good Afternoon";
    }
    return "Good Evening";
  };
  const fridaReports = myRecentReports.filter(r =>
    r.query.startsWith("story=FRIDA")
  );
  return (
    <div className="Home">
      <Grid container spacing={3} className={classes.container}>
        <Card className={classes.card}>
          <Grid item md={7} className={classes.greeting}>
            <CardHeader
              titleTypographyProps={{
                variant: "h5",
                className: classes.heading
              }}
              title={`${getGreeting()}! Welcome to your Interrodata free trial.`}
            />
            <Typography variant="body2" className={classes.introText}>
              We are here to simplify, and improve, the understanding of
              business performance.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              className={classes.introSubText}
            >
              Interrodata intelligently analyses standard industry datasets and
              generates meaningful stories about what happened, and why. Run a
              report to find out what has been happening for brands, categories,
              and more!
              <br />
              Your free trial will last for 30 days, ending on {endDate}.
            </Typography>
          </Grid>
          <Grid item md={4} className={classes.dataUpdateContainer}>
            <DataUpdate date={date} classes={classes} />
          </Grid>
          <Grid container spacing={3} className={classes.reportTypesContainer}>
            <Grid item md={4}>
              <ReportType
                type="performance"
                reports={fridaReports}
                icon={
                  <PerformanceIcon
                    className={`${classes.icon} ${classes.performanceIcon}`}
                  />
                }
                title="Performance Reports"
                intro="Understand how a category or brand is performing over time, in a specific market, channel or retailer."
                buttons={[
                  {
                    text: "New In-Home Performance Report (Including Impulse)",
                    link: "/reports-reviews/new-ih-performance",
                    disabled: false
                  },
                  {
                    text: "New Out-of-Home Performance Report (Excluding Impulse)",
                    link: "/",
                    disabled: true,
                    tooltip:
                      "This feature is included within the Professional & Professional+ packages"
                  }
                ]}
                buttonClass={`${classes.performanceButton}`}
                background={`${classes.performance}`}
                classes={classes}
              />
            </Grid>
            <Grid item md={4}>
              <ReportType
                type="brandbattles"
                icon={
                  <BrandBattlesIcon
                    className={`${classes.icon} ${classes.brandbattlesIcon}`}
                  />
                }
                title="Brand Battles Report"
                intro="Compare the performance of two products within a specific market or retailer."
                buttons={[
                  {
                    text: "New Brand Battles Report",
                    link: "/",
                    disabled: true,
                    tooltip:
                      "This feature is included within the Professional & Professional+ packages"
                  }
                ]}
                background={`${classes.disabledBackground}`}
                classes={classes}
                disabled
              />
            </Grid>
            <Grid item md={4}>
              <ReportType
                type="rangereview"
                icon={
                  <RangeReviewIcon
                    className={`${classes.icon} ${classes.rangereviewIcon}`}
                  />
                }
                title="Range Review Impact Assessment"
                intro="Explore the context and the impact of the range review, as well as the key drivers of performance change."
                buttons={[
                  {
                    text: "New Post Range Review",
                    link: "/",
                    disabled: true,
                    tooltip:
                      "This feature is included within the Professional+ package"
                  }
                ]}
                background={`${classes.disabledBackground}`}
                classes={classes}
                disabled
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </div>
  );
};

Home.propTypes = {
  date: PropTypes.shape(),
  userId: PropTypes.string,
  getRecentReports: PropTypes.func,
  myRecentReports: PropTypes.arrayOf(PropTypes.shape()),
  endDate: PropTypes.string
};

Home.defaultProps = {
  date: {},
  userId: "",
  getRecentReports: () => {},
  myRecentReports: [],
  endDate: ""
};

const mapStateToProps = state => ({
  date: state.data.date,
  userId: state.user.user.id,
  myRecentReports: state.report.myRecentReports,
  endDate: state.user.user.endDate
});

const mapDispatchToProps = dispatch => ({
  getRecentReports: userId => {
    dispatch(getMyRecentReports(userId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
