import _ from "lodash";
import { GOTO_SLIDE } from "../constants/actionConstants";
import logger from "../logger";

// hidden slide logic
function findSlide(slideIndex, report, direction, slideCount) {
  const isHidden = report[slideIndex].hidden;
  let slide = slideIndex;
  if (isHidden) {
    if (_.isEqual(direction, "prev")) {
      if (slideIndex - 1 > 0) {
        slide = findSlide(slideIndex - 1, report, "prev");
      }
    } else if (slideIndex + 1 < slideCount) {
      slide = findSlide(slideIndex + 1, report, "next", slideCount);
    } else {
      // if last slide is hidden, go back
      slide = findSlide(slideIndex, report, "prev");
    }
  }
  return slide;
}

export const goToSlide = (
  slideIndex,
  report,
  direction,
  slideCount,
  reportId,
  userId,
  story,
  client
) => {
  // to go to first slide
  if (slideIndex === 0) {
    return {
      type: GOTO_SLIDE,
      slideIndex
    };
  }
  const index = findSlide(slideIndex, report, direction, slideCount);
  const slideKey = report[index].slidekey;
  logger.info({
    date: new Date().toISOString(),
    action: "NAVIGATE_REPORT",
    report_id: reportId,
    slide_index: index + 1,
    slide_key: slideKey,
    total_slide_count: slideCount,
    user_id: userId,
    story,
    client,
    product: "frida"
  });
  return {
    type: GOTO_SLIDE,
    slideIndex: index
  };
};

export default null;
